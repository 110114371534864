import "../widgetLg/widgetLg.css";
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {getObjectKeys} from "../utils/Utils"

export default function AnalyticsWidgetSm({transactions: transactions, dataByOperators: dataByOperators}) {
    const Button = ({type}) => {
        return <button className={"widgetLgButton " + type}>{type}</button>;
    };
    return (
        <div className="widgetLg">
            <div>
                <h3 className="widgetLgTitle"> Operators currency stack <span
                    className="widgetLgTitle span">by Operator</span>
                </h3></div>

            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        {/*<TableRow>
                            <TableCell/>
                            <TableCell>Operator</TableCell>
                            <TableCell align="right">Trade&nbsp;(s)</TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    direction={'desc'}
                                    onClick={(e) => {
                                    }}
                                >
                                    {"Profit(s)"}

                                    <Box component="span">
                                    </Box>

                                </TableSortLabel></TableCell>
                            <TableCell align="right">Win&nbsp;(s)</TableCell>
                            <TableCell align="right">Loss&nbsp;(es)</TableCell>
                        </TableRow>*/}
                    </TableHead>
                    <TableBody>
                        {rows(dataByOperators).map((row) => (
                            <Row dataByOperators={dataByOperators} key={row.name} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function Row(props) {
    const {row, dataByOperators} = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Breakdown
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Symbol(s)</TableCell>
                                        <TableCell>Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getObjectKeys(dataByOperators[row.name].mostTradedSymbol).map((value, key) => (
                                        <TableRow key={key}>
                                            <TableCell component="th" scope="row">
                                                {value + ":"}
                                            </TableCell>
                                            <TableCell>{dataByOperators[row.name].mostTradedSymbol[value]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};


function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price
    };
}


const rows = (dataByOperators) => {
    let retVal = [];
    let getOperatorKeys = dataByOperators && getObjectKeys(dataByOperators);
    getOperatorKeys.forEach((operator => {
        retVal.push(createData(operator))
    }))
    return retVal.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
}
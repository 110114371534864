import "./chart.css";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis,} from "recharts";
import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, db} from "../../firebase";
import {collection, getDocs, query, where} from "firebase/firestore";
import {useDispatch, useSelector} from "react-redux";
import {tradesMonthlyRevenueInfo} from "../../reducers/RevenuePermonthPerUser-reducer";

export default function Chart({title, data, dataKey, grid}) {
    const [user] = useAuthState(auth);
    //set authenticated user from fire auth
    const dispatch = useDispatch();
    const [revenueData, setRevenueData] = useState([]);
    const _tradesMonthlyRevenueInfo = useSelector((state) => state.tradesMonthlyRevenueInfo.value);


    useEffect(() => {
        const runQuery = async () => {
            if (_tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0]) {
                setRevenueData([{name: "Jan", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["jan"])},
                    {name: "Feb", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["feb"])},
                    {name: "Mar", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["mar"])},
                    {name: "Apr", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["apr"])},
                    {name: "May", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["may"])},
                    {name: "Jun", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["jun"])},
                    {name: "Jul", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["jul"])},
                    {name: "Aug", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["aug"])},
                    {name: "Sep", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["sep"])},
                    {name: "Oct", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["oct"])},
                    {name: "Nov", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["nov"])},
                    {name: "Dec", Revenue: parseFloat(_tradesMonthlyRevenueInfo._tempData[0].equity_per_month["dec"])}])
            } else {
                const monthlyEquityPerUserCollectionRef = collection(db, "Monthly_equity_per_user");
                const q = query(monthlyEquityPerUserCollectionRef, where("email", "==", user.email));
                const data = await getDocs(q);
                const _tempData = data.docs.map((doc) => ({...doc.data(), id: doc.id}));
                setRevenueData(_tempData);
                _tempData && _tempData[0] && setRevenueData([
                    {name: "Jan", Revenue: parseFloat(_tempData[0].equity_per_month["jan"])},
                    {name: "Feb", Revenue: parseFloat(_tempData[0].equity_per_month["feb"])},
                    {name: "Mar", Revenue: parseFloat(_tempData[0].equity_per_month["mar"])},
                    {name: "Apr", Revenue: parseFloat(_tempData[0].equity_per_month["apr"])},
                    {name: "May", Revenue: parseFloat(_tempData[0].equity_per_month["may"])},
                    {name: "Jun", Revenue: parseFloat(_tempData[0].equity_per_month["jun"])},
                    {name: "Jul", Revenue: parseFloat(_tempData[0].equity_per_month["jul"])},
                    {name: "Aug", Revenue: parseFloat(_tempData[0].equity_per_month["aug"])},
                    {name: "Sep", Revenue: parseFloat(_tempData[0].equity_per_month["sep"])},
                    {name: "Oct", Revenue: parseFloat(_tempData[0].equity_per_month["oct"])},
                    {name: "Nov", Revenue: parseFloat(_tempData[0].equity_per_month["nov"])},
                    {name: "Dec", Revenue: parseFloat(_tempData[0].equity_per_month["dec"])}
                ])
                dispatch(tradesMonthlyRevenueInfo({_tempData}));
            }


        }
        runQuery();
    }, [data, user])

    return (
        <div className="chart">
            <h3 className="chartTitle">{title}</h3>
            <ResponsiveContainer width="100%" aspect={4 / 1}>
                <LineChart data={revenueData}>
                    <XAxis dataKey="name" stroke="#5550bd"/>
                    <Line type="monotone" dataKey={dataKey} stroke="#5550bd"/>
                    <Tooltip/>
                    {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5"/>}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
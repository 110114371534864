import React, {useState} from "react"
import {Alert, Button, Card} from "react-bootstrap"
import {useAuth} from "../../contexts/AuthContext"
import {Link, useHistory} from "react-router-dom"
import "./home.css"
import {useDispatch} from "react-redux";
import {userInfo} from "../../reducers/UserInformation-reducer";
import {authUser} from "../../reducers/Users-reducer";
import {transactionInfo} from "../../reducers/TransactionLis-reducer";
import {userDash} from "../../reducers/UserDash-Reducer";
import {operatorsInfo} from "../../reducers/Operators-Reducers";
import Toolbar from "@mui/material/Toolbar";
import {userTransactionInfo} from "../../reducers/Users-TransList-Reducer";
import {transactionInfoMild} from "../../reducers/Transactions-Mild";
import {tradesMonthlyRevenueInfo} from "../../reducers/RevenuePermonthPerUser-reducer";

export default function Dashboard() {
    const [error, setError] = useState("")
    const {currentUser, logout} = useAuth()
    const history = useHistory()
    const dispatch = useDispatch();

    async function handleLogout() {
        setError("")
        try {
            await logout()
            history.push("/")
            dispatch(userInfo({}));
            dispatch(authUser({}));
            dispatch(transactionInfo({}));
            dispatch(userTransactionInfo({}))
            dispatch(userDash({}));
            dispatch(operatorsInfo({}));
            dispatch(transactionInfo({}));
            dispatch(transactionInfoMild({}))
            dispatch(tradesMonthlyRevenueInfo({}))
        } catch {
            setError("Failed to log out")
        }
    }

    return (
        <>
            <div className="profile-dashboard-home">
                <div className="main-profile">
                    <Card>
                        <Card.Body>
                            <Toolbar className="login">
                                <h2 className="text-center mb-4 login">Profile</h2></Toolbar>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <strong>Email:</strong> {currentUser.email}
                            <Link to="/update-profile" className="btn btn-primary w-100 mt-3 logButt">
                                Update Profile
                            </Link>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2 ">
                        <Button variant="link" onClick={handleLogout}>
                            Log Out
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
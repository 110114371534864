import React, {useEffect, useRef, useState} from "react";
import publicLog from "../header/dnainvestingLogo1.png"
import devices from './img/device_merged_Img.png'
import macbookPro from './img/iphone6-journal-merged.png'
import sketchLogo from './img/sketch-logo.png'
import webPortal from './img/WebPortal_toUse.png'
import blogImage3 from './img/GlobalOperatorsPerformance.png'
import avatar1 from './img/avatar.jpg'
import main1 from './img/mani.jpg'
import main2 from './img/130.jpg'
import telegramSample from "./img/Telegram_Sample-min.png"
import "./css/normalize.min.css";
import "./css/jquery.fancybox.css";
import "./css/flexslider.css";
import "./css/styles.css";
import "./css/queries.css"
import "./css/etline-font.css"
import "./bower_components/animate.css/animate.min.css"
import "./css/font-awesome.min.css"
import Carousel from 'react-bootstrap/Carousel'
import Paper from "@material-ui/core/Paper";
import CountUp from "react-countup";
import Card from "@material-ui/core/Card";
import SendIcon from '@mui/icons-material/Send';
import "./publicPage.css"

import {
    faAlignLeft,
    faBalanceScaleLeft,
    faBeer,
    faCalendarCheck,
    faChartLine,
    faCheckSquare as fasCheckSquare, faCoffee, faCopy,
    faFileExcel,
    faHandHoldingUsd,
    faHistory,
    faImages,
    faListAlt,
    faMoneyBillAlt,
    faStopCircle
} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {faCheckSquare as farCheckSquare, faClone} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import CookieConsent from "react-cookie-consent";
import {Link, useHistory} from "react-router-dom"
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import {AccountCircle} from "@mui/icons-material";
import Button from "@mui/material/Button";
import axios from "axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

library.add(fab, fasCheckSquare, farCheckSquare,
    faBeer, faMoneyBillAlt, faHandHoldingUsd,
    faChartLine, faListAlt, faAlignLeft, faStopCircle, faBalanceScaleLeft, faFileExcel, faCalendarCheck, faHistory, faImages, faCopy)
const FontAwesome = require('react-fontawesome');

export default function Body() {
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState();
    const history = useHistory();
    const emailRef = useRef();
    const messageRef = useRef();
    const nameRef = useRef();
    const theme = useTheme();
    const smallBreakPoint = useMediaQuery(theme.breakpoints.down("sm"))
    const largeBreakPoint = useMediaQuery(theme.breakpoints.up("md"))

    const onSubmitForm = (e) => {
        try {
            e.preventDefault();


            if (!nameRef || !nameRef.current || !nameRef.current.value) {
                setShowError("nameRef");
                return;
            }
            if (!emailRef || !emailRef.current || !emailRef.current.value || !emailRef.current.value.match('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')) {
                setShowError("emailRef");
                return;
            }

            if (!messageRef || !messageRef.current || !messageRef.current.value) {
                setShowError("messageRef");
                return;
            }


            var data = {name: nameRef.current.value, message: messageRef.current.value, email: emailRef.current.value};
                  axios({
            method: "POST",
            url: "https://formspree.io/f/meqnerow",
            data: data
        })
            .then(response => {
        setShowError("sent")
            })
            .catch(error => {
            }).finally(()=>{
                      nameRef.current = "";
                      messageRef.current="";
                      emailRef.current="";

                      setTimeout(()=>setShowError(""), 10000)
                      document.getElementById("feedbackForm").reset();

                  });
        } catch (e) {
            console.error(e)
        }
    }
    const handleClose = () => setShow(false);
    const handleShow = () => {
        history.push("/termsCondition")
        setShow(!show)
    };
    const [hambuggerMenu, setHambuggerMenu] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);
    // Function to change an element's CSS when it is scrolled in.
    const scrollEventTrigger = (_event) => {
        var element = document.querySelector(".navigation");
        if (window.scrollY >= 100) {
            element.className = "navigation fixed"
        } else {
            if(element)
                element.className = "navigation"
        }
    };
    const scrollCounter = (_event) => {
        if (window.scrollY >= 900) {
            setScrollPosition(window.scrollY);
        } else {
            setScrollPosition(0);
        }
    };

    const onclickHamB = (_event) => {
        setHambuggerMenu(!hambuggerMenu)
        var element = document.querySelector(".nav-toggle");
        var headerNav = document.querySelector(".header-nav");

        if (hambuggerMenu) {
            element.className = "nav-toggle active";
            headerNav.className = "header-nav open";
        } else {
            element.className = "nav-toggle";
            headerNav.className = "header-nav";
        }

    }


// Call the function when the 'window' scrolls.
    useEffect(() => {
        window.addEventListener('scroll', scrollEventTrigger, {passive: true})
        window.addEventListener('scroll', scrollCounter, {passive: true})

        const query = new URLSearchParams(window.location.search);


        return () => {
            window.removeEventListener('scroll', scrollEventTrigger);
            window.removeEventListener('scroll', scrollCounter);
        }
    }, []);


    return (
        <div >
            <body id="top">
            <section className="hero">
                <section className="navigation">
                    <header>
                        <div className="header-content">
                            <div className="logo"><a href="#"><img className={"public_logo_img"} src={publicLog} alt="DnaInvesting logo"/></a></div>
                            <div className="header-nav">
                                <nav>
                                    <ul className="primary-nav">
                                        <li><a href="#subscription_module" onClick={onclickHamB}>Subscription</a></li>
                                        <li><a href="#feedback_module" onClick={onclickHamB}>Feedback</a></li>
                                        <li><a href="#features" onClick={onclickHamB}>Features</a></li>
                                        <li><a href="#download" onClick={onclickHamB}>Signup</a></li>
                                        <li><Link to={"/termsCondition"} onClick={onclickHamB}>Terms</Link></li>
                                        <li><a href="#feedbackForm" onClick={onclickHamB}>Contact Us</a></li>
                                    </ul>
                                    <ul className="member-actions">
                                        <li><Link to="/login" onClick={onclickHamB} className="btn-white btn-small">log
                                            in</Link></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="navicon">
                                <a className="nav-toggle" onClick={onclickHamB}><span></span></a>
                            </div>
                        </div>
                    </header>
                </section>
                <div className="container">
                    <div className="row audit">
                        <div className="col-md-10 col-md-offset-1">
                            <div className="hero-content  text-center">
                                <h1>Audit, Copy Trade, Win!</h1>
                                <p className="intro">Get Audited results of forex telegram signal providers &
                                    copy their signals automatically!.</p>
                                <sector className="homeSector">
                                    <Paper elevation={3} className={"intro-paper"}>
                                        <p>
                                            Tired of getting scammed by signal providers claiming to be legit and
                                            claiming to have 100% win rate
                                            but are far from making you money? or you simply want to know which signal
                                            providers are legit and which are not?
                                            Welcome to DNA Investing. We Audit signal providers; we take their trades
                                            and generate statistical
                                            data. With this; you can see how the operators are truly doing. NO BS NO
                                            marketing, all results are based on actual PnL results,
                                            and not through their marketing claims.<br/>
                                            Also, Don't miss any trade from your Telegram signal provider once you find
                                            the right one.
                                            If you are tired of missing good trades because you don't look at your phone
                                            24/7, our bots help you place trades
                                            automatically from the Telegram group you have authorized our software to
                                            trade on your behalf or simply control your trades from the bot commands interface we have
                                            on
                                            telegram. That way you don't have to be glued to your phone all day long and
                                            still
                                            place trades during the market open period </p>
                                    </Paper>
                                </sector>

                                <div class={"baseButtonHome"}>
                                    <a href="#subscription_module"
                                       className="btn btn-fill btn-large btn-margin-right">Pricing</a>
                                    <a href="#mini-features"
                                       className="btn btn-accent btn-large">Learn
                                        more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="down-arrow floating-arrow"><a href="#footer"><i className="fa fa-angle-down"></i></a>
                </div>
            </section>
            <section className="intro section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 intro-feature">
                            <div className="intro-icon">
                                <span data-icon="&#xe033;" className="icon"></span>
                            </div>
                            <div id={"mini-features"} className="intro-content">
                                <h5>Analytics Web Portal</h5>
                                <p>Our web portal displays statistics of closed trades and results from
                                    signal operators, you can also view your trade journal!</p>
                            </div>
                        </div>
                        <div className="col-md-4 intro-feature">
                            <div className="intro-icon">
                                <span data-icon="&#xe030;" className="icon"></span>
                            </div>
                            <div className="intro-content">
                                <h5>Automate copy trade</h5>
                                <p>Automatically copy trades of any signal provider you are subscribed to or any channel
                                    you are a member of.</p>
                            </div>
                        </div>
                        <div className="col-md-4 intro-feature">
                            <div className="intro-icon">
                                <span data-icon="&#xe046;" className="icon"></span>
                            </div>
                            <div className="intro-content last">
                                <h5>Control MT4 Telegram Bot</h5>
                                <p>Control your MT4 trades from your mobile phone, view actives, close trade, adjust money
                                    management settings.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features section-padding" id="features">
                <div className="container">
                    <div className={smallBreakPoint ? "row " : "row features"}>
                        <div className="col-md-5 col-md-offset-7">
                            <div className="feature-list">
                                <h3>Features & Highlight </h3>
                                <p>Some of the features we offer on our applications....</p>
                                <ul className="features-stack">
                                    <li className="feature-item">
                                        <div className="feature-icon">
                                            <span data-icon="&#xe03e;" className="icon"></span>
                                        </div>
                                        <div className="feature-content">
                                            <h5>Copy Trades Automatically</h5>
                                            <p>Let our bot copy trades of your favorite signal providers while you seat back and relax.</p>
                                        </div>
                                    </li>
                                    <li className="feature-item">
                                        <div className="feature-icon">
                                            <span data-icon="&#xe040;" className="icon"></span>
                                        </div>
                                        <div className="feature-content">
                                            <h5>Risk Management</h5>
                                            <p>Update and control your trade risk directly from the telegram bot through your mobile phone.</p>
                                        </div>
                                    </li>
                                    <li className="feature-item">
                                        <div className="feature-icon">
                                            <span data-icon="&#xe03c;" className="icon"></span>
                                        </div>
                                        <div className="feature-content">
                                            <h5>Web Analytics Portal</h5>
                                            <p>View your trading journal and the trade analysis of various signal
                                                providers that users in the community are subscribed to. Also you can configure the authorization of your bots from the
                                                controls panel in the portal.</p>
                                        </div>
                                    </li>
                                    <li className="feature-item">

                                        <div className={"baseButtonHome"}>
                                            <a href="#product-features"
                                               className="btn btn-accent btn-large">Learn
                                                more</a>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="device-showcase">
                    <div className="devices">
                        <div className="ipad-wrap wp1"></div>
                        <div className="iphone-wrap wp2"></div>
                    </div>
                </div>
                <div className="responsive-feature-img"><img src={devices} alt="responsive devices"/></div>
            </section>
            <section className="features-extra section-padding" id="assets">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="feature-list">
                                <h3>Auditing Signal Operators!</h3>
                                <p className={"textParAuditingText"}>
                                    Our web portal is the perfect tool to see signal operators results (both current month and
                                    historic results),
                                    see how well a signal operator is faring at the moment or historically before risking your
                                    money to
                                    investing in their signals.
                                    <p className={"textParAuditingText"}>
                                        <u>
                                            Some Features...
                                        </u>
                                    </p>

                                </p>

                                <ul>
                                    <li className={"auditSignalFeatures"}> Total trades by highest grossing operator for
                                        user/community
                                    </li>
                                    <li className={"auditSignalFeatures"}> Most traded Symbol by operator for
                                        user/community
                                    </li>
                                    <li className={"auditSignalFeatures"}> Operators revenue/equity curve (multiple line
                                        graph) for the year
                                    </li>
                                    <li className={"auditSignalFeatures"}> Calendar heatmap of winning and losing closed
                                        trades for the month by day
                                    </li>
                                    <li className={"auditSignalFeatures"}> Top grossing Details for individual operator
                                        with the following information [Total Wins, Total Losses, Total Buy Wins.. et
                                        al..]
                                    </li>
                                    <li className={"auditSignalFeatures"}> Operators Currency Stack for the month with
                                        break down information as [Symbols, count...]
                                    </li>
                                    <li className={"auditSignalFeatures"}> Largest operator revenue pie chart by month
                                    </li>
                                </ul>

                                <a href="#" className="btn btn-ghost btn-accent btn-small">See more?</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="macbook-wrap wp3"></div>
                <div className="responsive-feature-img"><img src={macbookPro} alt="responsive devices"/></div>
            </section>
            <section className="hero-strip section-padding">
                <div className="container">
                    <div className="col-md-12 text-center">
                        <div className={"is-intro"}>
                            <ul>
                                <li className={"imageSector"}>
                                    <div >
                                        <span data-icon="&#xe04d;"></span>
                                    </div>
                                    <div className={"is-name counter"}>{scrollPosition > 900 ?
                                        <CountUp end={203} duration={8}/> : 203}</div>
                                    <div className={"is-text"}>Downloads</div>
                                </li>
                            </ul>
                            <ul>
                                <li className={"imageSector"}>
                                    <div >
                                        <span data-icon="&#xe040;"></span>
                                    </div>
                                    <div className={"is-name counter"}>{scrollPosition > 900 ?
                                        <CountUp end={203} duration={15}/> : 203}</div>
                                    <div className={"is-text"}>Customers</div>
                                </li>
                            </ul>
                            <ul>
                                <li className={"imageSector"}>
                                    <div >
                                        <span data-icon="&#xe03b;"></span>
                                    </div>
                                    <div className={"is-name counter"}>{scrollPosition > 900 ?
                                        <CountUp end={300} duration={20}/> : 300}</div>
                                    <div className={"is-text"}>Operators</div>
                                </li>
                            </ul>
                        </div>
                        <h2>
                            Want to be part of the happy <span className="sketch">Family <i
                            className="version"> 😊</i></span>
                        </h2>
                        <p>hurry up and join below, let the operators work for you without lifting a finger...</p>
                        <a href="#sign-up" className="btn btn-ghost btn-accent btn-large">Join Now</a>
                        <div className="logo-placeholder floating-logo"><img src={sketchLogo}
                                                                             alt="Sketch Logo"/></div>
                    </div>
                </div>
            </section>
            <section className="blog-intro section-padding" id="blog">
                <div id={"product-features"} className="container">
                    <div className="row">
                        <div className="col-md-12 prod_features_header">
                            <h3>Product Features</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 leftcol">
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesome className="super-crazy-colors newOne"
                                         name="check-square"
                                                 featuredSectorTextSection
                                                 size="2x"
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Tradingview Integration</h2>
                                            <p className={"innerTextfeatured2"}>Trade indicator/strategy alerts from tradingview on mt4</p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesome className="super-crazy-colors one"
                                                 name="rocket" featuredSectorTextSection
                                                 size="2x"
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Super Fast Execution</h2>
                                            <p className={"innerTextfeatured2"}>Execute signal from signal operators in record
                                                time.</p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon className="super-crazy-colors two" icon={"hand-holding-usd"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Risk Management</h2>
                                            <p className={"innerTextfeatured2"}>Eliminate any psychological and
                                                emotional trading issue,
                                                the application places the trades with your configured risk level. </p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors three" icon={"chart-line"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Web Analytics</h2>
                                            <p className={"innerTextfeatured2"}>Draw up different sentiment analysis from trade data we have collated</p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors four" icon={"list-alt"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Advanced Trade Journal</h2>
                                            <p className={"innerTextfeatured2"}>Iterate through your closed
                                                transactions list, or make use of the advanced filter to narrow down information and generate reports </p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors five" icon={"history"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Historic operator data</h2>
                                            <p className={"innerTextfeatured2"}>See prior results of signal operators, filter
                                                by year,month, operator name and more</p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors six" icon={"history"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Calendar Heatmap</h2>
                                            <p className={"innerTextfeatured2"}> Draw different sentiments from calender
                                                heatmap of closed trades, see winning days and losing days</p>
                                        </p>
                                    </div>
                                </div>
                            </Card>

                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 rightcol">
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors ten" icon={"copy"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Trade Replication</h2>
                                            <p className={"innerTextfeatured2"}>Trade execution multiplication, e.g execute a single trade x number of times</p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors seven" icon={"align-left"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Multiple TPs & custom SL</h2>
                                            <p className={"innerTextfeatured2"}>Configure multiple take profit levels (up to 5), stop loss, move to break even after take profit one is hit or just take profit after a certain number of pips is reached. </p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors eight" icon={"stop-circle"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Trailing Stops</h2>
                                            <p className={"innerTextfeatured2"}>Trail your stop loss, move your stoploss price after a certain number of pips. </p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors nine"
                                                     icon={"balance-scale-left"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Scalp mode 😎</h2>
                                            <p className={"innerTextfeatured2"}> Mode dedicated to scalping trades</p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors ten" icon={"file-excel"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Generate Report</h2>
                                            <p className={"innerTextfeatured2"}>Generate pdf, csv reports of your journal
                                                 on demand</p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors elev"
                                                     icon={"calendar-check"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Top Signal Provider Pick (Monthly)</h2>
                                            <p className={"innerTextfeatured2"}>See top grossing signal provider in the
                                                community (determined by our algorithm) and how they fare against each
                                                other. </p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className={"featuresSector"}>
                                    <FontAwesomeIcon color={"red"} className="super-crazy-colors twel"
                                                     icon={"images"}
                                    />
                                    <div className={""}>
                                        <p className={""}>
                                            <h2 className={"innerTextfeatured1"}>Trade Photo/Text signals</h2>
                                            <p className={"innerTextfeatured2"}>We parse image or text signals</p>
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog text-center">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <article className="blog-post">
                                <figure>
                                    <a href={"/login"} className="single_image">
                                        <div className="blog-img-wrap">
                                            <div className="overlay">
                                                <i className="fa fa-search"></i>
                                            </div>
                                            <img src={telegramSample} alt="telegram bot"/>
                                        </div>
                                    </a>
                                    <figcaption onClick={() => {
                                    }}>
                                        <h2><a className="blog-category" data-toggle="tooltip" onClick={() => {
                                        }}
                                               data-placement="top" data-original-title="See more posts">Telegram
                                            Bot</a>
                                        </h2>
                                        <p><Link to={"/login"} className="blog-post-title">Getting started with
                                            DnaInvesting
                                            Bot <i
                                                className="fa fa-angle-right"></i></Link></p>
                                    </figcaption>
                                </figure>
                            </article>
                        </div>
                        <div className="col-md-4">
                            <article className="blog-post">
                                <figure>
                                    <Link to="/login" className="single_image">
                                        <div className="blog-img-wrap">
                                            <div className="overlay">
                                                <i className="fa fa-search"></i>
                                            </div>
                                            <img className={"portal_img"} src={webPortal}
                                                 alt="DnaInvesting Web Portal"/>
                                        </div>
                                    </Link>
                                    <figcaption>
                                        <h2><Link to="/login" className="blog-category" data-toggle="tooltip"
                                                  data-placement="top" data-original-title="DnaInvesting Web portal">Web
                                            Portal</Link>
                                        </h2>
                                        <p><Link to="/login" className="blog-post-title">Supported on all browsers<i
                                            className="fa fa-angle-right"></i></Link></p>
                                    </figcaption>
                                </figure>
                            </article>
                        </div>
                        <div className="col-md-4">
                            <article className="blog-post">
                                <figure>
                                    <a href="img/blog-img-03.jpg" className="single_image">
                                        <div className="blog-img-wrap">
                                            <div className="overlay">
                                                <i className="fa fa-search"></i>
                                            </div>
                                            <img src={blogImage3} className="single_image"
                                                 alt="Sedna blog image"/>
                                        </div>
                                    </a>
                                    <figcaption>
                                        <h2><a href="#" className="blog-category" data-toggle="tooltip"
                                               data-placement="top" data-original-title="See more posts">Operator
                                            Performance</a>
                                        </h2>
                                        <p><a href="#" className="blog-post-title">Global Operators Performance Analysis<i
                                            className="fa fa-angle-right"></i></a></p>
                                    </figcaption>
                                </figure>
                            </article>
                        </div>
                        <a href="#product-features" className="btn btn-ghost btn-accent btn-small newsButton">More
                            features</a>
                    </div>
                </div>
            </section>
            {/*<section>
                <ForexHeatMap colorTheme={"dark"} autosize></ForexHeatMap>
            </section>
            <section>
                <AdvancedRealTimeChart symbol={"XAUUSD"} theme="dark" autosize></AdvancedRealTimeChart>
            </section>*/}
            <section id={"subscription_module"}>
                <h2 className={"sub_header"}>Subscription Plan</h2>
                <div className={"sub_header_mini"}>Please select your preferred plan</div>
                <div className={"parent_sub_plans"}>
                    <div className={"container"}>
                        <div className="lifetime">
                            <div className="Lifetime Pro_title subscription_title">Monthly Sub</div>
                            <div className="Lifetime Pro_price subscription_price">$20.00</div>
                            <div className="Lifetime Pro_desc subscription_desc"><p className="font_7"><span
                                className="color_11"><span>One MT4 trading accounts</span></span></p>
                                <p className="font_7"><span
                                    className="color_11"><span>Full Web analytics</span></span></p>
                                <p className="font_7"><span
                                    className="color_11"><span>Unlimited Signal Operators</span></span>
                                </p>
                                <p className="font_7"><span className="color_11"><span>Unlimited Telegram Signals</span></span>
                                </p>
                                <p className="font_7"><span
                                    className="color_11"><span>All access to bot control</span></span>
                                </p>
                                <p className="font_7"><span className="color_11">Lifetime Support</span></p></div>
                            <Link className="view_more Lifetime Pro_button" to="/signup">Buy Now!</Link>
                        </div>
                    </div>
                    <div className={"container"}>
                        <div className="lifetime">
                            <div className="Lifetime Pro_title subscription_title">Yearly Sub</div>
                            <div className="Lifetime Pro_price subscription_price">$209.99</div>
                            <div className="Lifetime Pro_desc subscription_desc">
                                <p className="font_7"><span
                                    className="color_11"><span>One MT4 trading accounts</span></span></p>
                                <p className="font_7"><span
                                    className="color_11"><span>Full Web analytics</span></span></p>
                                <p className="font_7"><span
                                    className="color_11"><span>Unlimited Signal Operators</span></span>
                                </p>
                                <p className="font_7"><span className="color_11"><span>Unlimited Telegram Signals</span></span>
                                </p>
                                <p className="font_7"><span
                                    className="color_11"><span>All access to bot control</span></span>
                                </p>
                                <p className="font_7"><span className="color_11">Lifetime Support</span></p>

                            </div>
                            <Link className="view_more Lifetime Pro_button" to="/signup">Buy Now!</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"feedback_module"} className="testimonial-slider section-padding text-center">
                <div className="container">
                    <div className={"feedBackSection"}>Customers feedback's</div>
                    <div className="row">
                        <div className="col-md-12">
                            <Carousel indicators={false} controls={false} variant="dark">
                                <Carousel.Item>
                                    <div className="avatar"><img src={avatar1}
                                                                 alt="DnaInvesting Testimonial Avatar"/></div>
                                    <h2>"Very interactive and simple UI, instant trade execution"</h2>
                                    <p className="author">Peter F, Sweden. <span>⭐⭐⭐⭐⭐️</span></p>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <div className="avatar"><img src={main1} alt="Sedna Testimonial Avatar"/>
                                    </div>
                                    <h2>"Modern and simple to use. By far the best trade analytics"</h2>
                                    <p className="author">Manoela Il, Germany. <span>⭐⭐⭐⭐⭐️</span></p>
                                </Carousel.Item>


                                <Carousel.Item>
                                    <div className="avatar"><img src={main2} alt="Sedna Testimonial Avatar"/>
                                    </div>
                                    <h2>"This made me lazy, plug and play style of trading. the operators work for
                                        me😛"</h2>
                                    <p className="author">Blaz R, Australia <span>⭐⭐⭐⭐⭐️</span></p>
                                </Carousel.Item>
                            </Carousel>

                        </div>
                    </div>
                </div>
            </section>
            <section className="sign-up section-padding text-center" id="download">
                <div id={"sign-up"} className="container">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <h3>Get started with DnaInvesting.</h3>
                            <p>Start seeing value immediately for your money</p>
                            <form className="signup-form" action="#" method="POST" role="form">
                                <div className="form-input-group">
                                    <i className="fa fa-envelope"></i><input type="text" className=""
                                                                             placeholder="Enter your email" required/>
                                </div>
                                {/*  <div className="form-input-group">
                                    <i className="fa fa-lock"></i><input type="text" className=""
                                                                         placeholder="Enter your password" required/>
                                </div>*/}
                                <button onClick={() => {
                                    history.push("/signup")
                                }} type="submit" className="btn-fill sign-up-btn">Sign up with us
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <section class={"container feedbacksection"}>
                    <form id={"feedbackForm"} onSubmit={() => onSubmitForm}>

                        <div className={"feedback_main_parent_title"}>
                            <h2 className={"feedback_title"}> Contact Us</h2>
                            <p className={"feedback_sub_header"}>We are bunch of developer traders, we are constantly
                                adding features we cherish your
                                feedbacks or
                                ideas of new features.. get in touch!</p>
                        </div>
                        <div className={"name_email_feedback"}>
                            <Box sx={{'& > :not(style)': {m: 9}}}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="input-with-icon-adornment">
                                        *Full Name
                                    </InputLabel>
                                    <Input
                                        className={"full_name_feedback"}
                                        sx={{minWidth: 150, fontSize: 15, fontWeight: "light"}} required
                                        error={showError === "nameRef"}
                                        inputRef={nameRef}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AccountCircle/>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <TextField
                                    className={"email_feedback"}

                                    sx={{minWidth: 200, fontSize: 15, fontWeight: "light", p: 1}}
                                    inputRef={emailRef}
                                    id="input-with-icon-textfield"
                                    label="*Email"
                                    required
                                    error={showError === "emailRef"}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                />

                            </Box>

                        </div>
                        <div>
                            <TextField multiline type={"text"} rows={5} size={"large"}
                                       sx={{minWidth: 500, fontSize: 35, fontWeight: "light", height: 120}}
                                       className={"message_feedback"} required error={showError === "messageRef"}
                                       inputRef={messageRef} label="*Message"/>
                        </div>


                        <div className={"submit_feedback"}>

                            <Button onClick={(e) => onSubmitForm(e)} variant="outlined" size="large"
                                    endIcon={<SendIcon/>}>
                                Submit
                            </Button>

                        </div>
                        <div className={"error_success"}>
                            {showError && showError === "sent" ? <i style={{color: "green"}}>Message sent
                                successfully..✅ <br/> <i>We will get back to you shortly</i></i> : showError != "sent" && showError ?
                                <i style={{color: "red"}}>error you need to fill in all the
                                    boxes</i> : () => setShowError("")}
                        </div>

                    </form>

                </section>
            </section>
            <section className="to-top">
                <div className="container">
                    <div className="row">
                        <div className="to-top-wrap">
                            <a href="#top" className="top"><i className="fa fa-angle-up"></i></a>
                        </div>
                    </div>
                </div>
            </section>
            <footer id={"footer"}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="footer-links">
                                <ul className="footer-group">
                                    <li><a href="#">feedbacks</a></li>
                                    <li><a href="#subscription_module">Pricing</a></li>
                                    <li><a href="#download">Sign up</a></li>
                                    <li><a href="#product-features">features</a></li>
                                    <li><Link to="/termsCondition">Condition</Link></li>
                                    <li><a href="#feedbackForm">Contact Us</a></li>
                                </ul>
                                <p>Copyright © 2022 <a href="#">DnaInvesting</a>
                                    <br/>
                                    <a>Licenced</a> | And developed
                                    <span
                                        className="fa fa-heart pulse2"></span> for &nbsp;

                                    <a
                                        href="https://dnainvesting.net/">DnaInvesting</a>.</p>
                            </div>

                        </div>
                        <div className={"disclaimer_footer"}><b style={{fontSize: "15px"}}>Disclamer:</b> Trading
                            involves the possibility of financial
                            loss.
                            Only trade with money that you are prepared to lose.
                            DNAInvesting is no way offering you any financial advice, nor selling any financially
                            related products, everything on DNAInvesting is for educational purposes ONLY.
                            By signing up as a member you acknowledge that we are not providing financial advice and
                            that you are making the decision to trade on your account at your own risk.
                            You must make your own financial decisions, we take no responsibility for money made or lost
                            as a result from the use of signal providers or advice on forex related products on this
                            website. <br/><a className={"termsandcondition_a"} onClick={() => handleShow()}>Terms And
                                Conditions</a> © Copyright DnaInvesting 2022.
                            All Rights Reserved.
                        </div>
                        <CookieConsent
                            location="top"
                            style={{background: "#2B373B"}}
                            buttonStyle={{color: "#4e503b", fontSize: "11px"}}
                            expires={150}

                            enableDeclineButton
                            onDecline={() => {
                                alert("You have declined the consent request, please exit this site right away!");
                            }}
                        >
                            <div>
                                DNAInvesting is a site created for educational purposes and a platform for auditing forex signal providers. All
                                information contained on this web site are generated by our algorithm or in other cases
                                our belief. None of these data is a recommendation or financial advice in any sense.
                                <Link to={"/termsCondition"}>Terms And Conditions</Link>
                            </div>
                        </CookieConsent>
                        <CookieConsent
                            location="top"
                            style={{background: "#2B373B"}}
                            buttonStyle={{color: "#4e503b", fontSize: "11px"}}
                            expires={150}

                            enableDeclineButton
                            onDecline={() => {
                                alert("You have declined the consent request, please exit this site right away!");
                            }}
                        >
                            <div>
                               This site uses cookies to offer you a better browsing experience. Find out more on
                                <Link to={"/termsCondition"}>Terms And Conditions</Link>
                            </div>
                        </CookieConsent>
                        <div className="social-share">
                            <p>Checkout our social media pages and share DnaInvesting with your friends</p>
                            <a href="https://www.instagram.com/dnainvesting_/" target="_blank"
                               className="twitter-share"><i
                                className="fa fa-instagram"></i></a>
                            <a href="https://twitter.com/Dnainvesting1" target="_blank" className="twitter-share"><i
                                className="fa fa-twitter"></i></a> <a href="https://www.facebook.com/DnaInvesting/" target="_blank"
                                                                      className="facebook-share"><i
                            className="fa fa-facebook"></i></a>

                        </div>
                    </div>

                </div>
            </footer>
            <script src="http://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"></script>
            </body>

        </div>
    );
}

import React, { useEffect, useState } from "react";
import "./topbar.css"
import { Language, NotificationsNone, Settings } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory, Link } from "react-router-dom"
import { useSelector } from "react-redux";
import pageLogo from "./dnainvestingLogo2.png"


export default function Header() {

    const history = useHistory();
    const [operatorCount, setOperatorCount] = useState([]);
    const _user = useSelector((state) => state.user.value);
    const _userDash = useSelector((state) => state.userDash.value);
    const userTransactions = useSelector((state) => state.transactionInfoMild.value)
    const _operators = useSelector((state) => state.operatorsInfo.value);
    let userEmail = _user ? _user.email : _user
    if (userEmail === "" | userEmail === undefined)
        userEmail = _user && _user.user ? _user.user.email : _user;


    //*_operators && _operators._operatorsList && operatorCount.length == 0 && doTrans()*/
    ///*_operators && _operators._operatorsList && operatorCount.length == 0 && doTrans()*/

    const doTrans = () => {
        let retVal = [];
        let retvalComment = []
        let retvalOpsName = [];
        let operatorNames = _operators && _operators._operatorsList && _operators._operatorsList.map((operator) => {
            retvalOpsName.push(operator["name"]);
            return retvalOpsName;
        })
        let distinctMonthlyOperators = userTransactions && userTransactions._transList && userTransactions._transList.filter((_transact) => _transact.orderCloseTime.toDate().getFullYear() === new Date().getFullYear() && _transact.orderCloseTime.toDate().getMonth() === new Date().getMonth()).map((transaction) => {
            //console.log("transactions", transaction) rough work because of the format of the data
            //removeTPs SL
            let runX = transaction["orderComment"].replace(/\[sl]|\[tp]/, "").trim();
            if (retvalComment.indexOf(runX) < 0) {
                if (operatorNames && operatorNames[0].indexOf(runX) >= 0)
                    retvalComment.push(runX);
                setOperatorCount(retvalComment)
            }


            return retvalComment;
        })
    }

    useEffect(() => {
        doTrans()
    }, [_operators])

    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <Link to="/" className="logo_img" >
                        <img src={pageLogo} className="logo_img" alt="Home" />
                    </Link>
                </div>

                <div className="topRight">
                    <Tooltip title={"Total Signal Provider for the month"}>
                        <div className="topbarIconsContainer">
                            <NotificationsNone />

                            <span
                                className="topbarBadge">{operatorCount.length}</span>
                        </div>
                    </Tooltip>
                    <Tooltip title={"Total user trades for current month"}>
                        <div className="topbarIconsContainer">
                            <Language />

                            <span
                                className="topbarBadge">{userTransactions && userTransactions._transList && userTransactions._transList.filter((_transact) => _transact.orderCloseTime.toDate().getFullYear() === new Date().getFullYear() && _transact.orderCloseTime.toDate().getMonth() === new Date().getMonth()).length}</span>
                        </div>
                    </Tooltip>
                    <Tooltip title={"Update Settings/Profile"}>
                        <div className="topbarIconsContainer">
                            <Settings onClick={() => history.push("update-profile")} />
                        </div>
                    </Tooltip>

                    <Tooltip title={userEmail}
                        placement="top">
                        <img

                            src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                            alt="" className="topAvatar" onClick={() => history.push("/profile")}>

                        </img>

                    </Tooltip>
                </div>

            </div>
        </div>
    )

}
import Container from "@material-ui/core/Container";
import React from "react";
import GaugeChart from 'react-gauge-chart'
import Stack from "@mui/material/Stack";
import Grid from "@material-ui/core/Grid";
import "./operatormonthlyprofiabilitychart.css";
import {decimalToWholeNumber, isNegativeNumber} from "../../components/utils/Utils";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@mui/material/IconButton';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Divider from "@material-ui/core/Divider";
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function FormRow(item1, item2, item3) {
    return (
        <React.Fragment>
            <Grid item xs={4}>
                <Item>Total Profit:</Item>
            </Grid>
            <Grid item xs={4}>
                <Item>Total Loss(es)</Item>
            </Grid>
            <Grid item xs={4}>
                <Item>Total lot</Item>
            </Grid>
        </React.Fragment>
    );
}

const calculateOverallPoint = (winRate, profitFactor) => {
    let retVal = 0;
    //scenerio one high winrate and low profitfactor
    if (winRate > 85 && profitFactor < 0.99) {

        return Number((50 * (profitFactor + winRate)) / 85.99).toFixed(2)/100;
    }
    // scenerio two low winrate and high profitfactor
    if (winRate < 85 && profitFactor >= 1) {
        return Number((((winRate / 100) + (profitFactor * 6)) + 70) / 100).toFixed(2)
    }

    // scenrio 3
    if (winRate >= 85 && profitFactor >= 1) {
        return Number((((winRate / 100) + (profitFactor * 4)) + 80) / 100).toFixed(2)
    }

    // scenerio four low winrate and low profit
    if (winRate < 85 && profitFactor < 0.99) {
        const _retval = Number((30 / 85.99) * (profitFactor + winRate)).toFixed(2) / 100;
        return _retval;
    }


    return retVal;
}

export default function ProfitabilityPane({transactions: transactions}) {
    const chartStyle = {
        height: 250,
        marginTop: 50,
        margin: 0,
        overflow: "hidden"
    }
    const chartStyle2 ={

    }
    let winRate = 0;
    let lossRate = 0;
    let profitFactor = 0;
    let lotNumber = 0;
    let totalWins = 0;
    let totalLoss = 0;
    let grossProfit = 0;
    let grossLoss = 0;
    let averageProfitWin = 0;
    let averageProfitLoss = 0;
    let totalLot = 0;
    let averageLot = 0;
    let overallPoint = 0
    transactions && transactions.map((transaction) => {
        winRate = !isNegativeNumber(Number(String(transaction.orderProfit).trim())) ? winRate + 1 : winRate;
        totalLot = Number(String(transaction.orderSize).trim()) + totalLot;
        lossRate = isNegativeNumber(Number(String(transaction.orderProfit).trim())) ? lossRate + 1 : lossRate;
        grossProfit = !isNegativeNumber(Number(String(transaction.orderProfit).trim())) ? Number(String(transaction.orderProfit).trim()) + grossProfit : grossProfit;
        grossLoss = isNegativeNumber(Number(String(transaction.orderProfit).trim())) ? Number(String(transaction.orderProfit).trim()) + grossLoss : grossLoss;
        totalWins = !isNegativeNumber(Number(String(transaction.orderProfit).trim())) ? totalWins + 1 : totalWins
        totalLoss = isNegativeNumber(Number(String(transaction.orderProfit).trim())) ? totalLoss + 1 : totalLoss
    })
    winRate = !isNaN(winRate) && winRate > 0 ? Math.round((100 * winRate) / transactions.length) / 100 : 0.0
    lossRate = !isNaN(lossRate) && winRate > 0 ? Math.round((100 * lossRate) / transactions.length) / 100 : 0.0
    averageProfitWin = transactions && grossProfit / transactions.length
    averageProfitLoss = transactions && grossLoss / transactions.length
    averageLot = transactions && Math.round(totalLot) / transactions.length
    profitFactor = (totalLoss == 0 && totalWins == 0) ? 0.0 : totalLoss > 0 ? Math.abs(averageProfitWin / averageProfitLoss).toFixed(2) : 4;
    profitFactor = profitFactor > 4 ? 4 : profitFactor;
    overallPoint = !isNaN(winRate) && winRate > 0 && !isNaN(profitFactor) ? calculateOverallPoint(Number(winRate) * 100, Number(profitFactor)) : 0.0;
    
    return (<div className={"operator_monthly_profibility pane_container_settings"} style={{width: "80%"}}>

        <Container>
            <Stack style={chartStyle} direction="row">
                <Grid container
                      justifyContent="center"
                      alignItems="center"
                      item xs={4}
                >
                    <GaugeChart
                        colors={["#f91616", "#c5f213", "#69ef02"]}
                        formatTextValue={() => "profitability " + decimalToWholeNumber(winRate * 100) + "%"}
                        needleBaseColor={"#464A4F"}
                        percent={winRate}
                        animateDuration={6000}
                        textColor={"#464A4F"} id="gauge-chart1" class={"justStyle"}/>
                    <div style={{textAlign: "center", fontSize: "small"}}>win rate</div>
                    <Tooltip
                        title="Percentage number of wins on the total number of trades; this alone is not by any means an indication of profitability, compare with the profit factor"
                        arrow>
                        <IconButton>
                            <QuestionMark sx={{fontSize: 10, color: "grey"}}/>
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid

                    container
                    justifyContent="center"
                    alignItems="center"
                    item xs={4}
                >
                    <GaugeChart id="gauge-chart3"
                                nrOfLevels={30}
                                formatTextValue={() => "profit factor: " + profitFactor}
                                colors={["#FF5F6D", "#69ef02"]}
                                textColor={"#464A4F"}
                                arcWidth={0.3}
                                percent={((100 * profitFactor) / 4) / 100}
                    />
                    <div style={{textAlign: "center", fontSize: "small"}}>profit factor</div>
                    <Tooltip
                        title="gross profit divided by gross loss, if the profit higher than 1 it indicates the operator is profitable, with a max of 4"
                        arrow>
                        <IconButton>
                            <QuestionMark sx={{fontSize: 10, color: "grey !important"}}/>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    item xs={4}
                >
                    <GaugeChart id="gauge-chart4"
                                nrOfLevels={10}
                                arcPadding={0.1}
                                textColor={"#464A4F"}
                                formatTextValue={() => "overall point: " + decimalToWholeNumber(overallPoint * 100)}
                                colors={["#FF5F6D", "#69ef02"]}
                                cornerRadius={3}
                                percent={overallPoint}
                    />
                    <div style={{textAlign: "center", fontSize: "small"}}>signal overall profitability</div>
                    <Tooltip
                        title="Overall profitability is calculated with our algorithm based on the profit factor and winrate. Very good
                         profitable system starts at 70 till 100.
                         " arrow>
                        <IconButton>
                            <QuestionMark sx={{fontSize: 10, color: "grey !important"}}/>
                        </IconButton>
                    </Tooltip>
                </Grid>

            </Stack>
            <Divider/>
            <Stack>
                <Box sx={{flexGrow: 1}}>
                    <Grid container spacing={1}>
                        <Grid container item spacing={3}>
                            <Grid item xs={4}>
                                <Item>Gross Profit: {Number(grossProfit).toFixed(2)}</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>Total number of trades: {transactions ? transactions.length : 0} </Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>Average
                                    Profit: {!isNaN(averageProfitWin) ? Number(averageProfitWin).toFixed(2) : 0}</Item>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={4}>
                                <Item>Gross Loss: {!isNaN(grossLoss) ? Number(grossLoss).toFixed(2) : 0}</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>Total Win trades: {totalWins}</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>Average
                                    loss: {!isNaN(averageProfitLoss) ? Number(averageProfitLoss).toFixed(2) : 0}</Item>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={4}>
                                <Item>Win Rate(%): {winRate}</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>Total loss trades: {totalLoss}</Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>Average lot size: {!isNaN(averageLot) ? Number(averageLot).toFixed(2) : 0}</Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Container>
    </div>)
}
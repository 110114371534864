import React, {useEffect, useState} from "react";
import {addEventListeners, removeEventListeners} from './eventListenerUtil'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import "./style.css"
import {useHistory} from "react-router-dom"
import {useDispatch} from "react-redux";
import {useAuth} from "../../contexts/AuthContext";
import {userInfo} from "../../reducers/UserInformation-reducer";
import {authUser} from "../../reducers/Users-reducer";
import {transactionInfo} from "../../reducers/TransactionLis-reducer";
import {userDash} from "../../reducers/UserDash-Reducer";
import {operatorsInfo} from "../../reducers/Operators-Reducers";
import {transactionInfoMild} from "../../reducers/Transactions-Mild";
import {tradesMonthlyRevenueInfo} from "../../reducers/RevenuePermonthPerUser-reducer";


export default function TimeoutLogic() {
    const [isWarningModalOpen, setWarningModalOpen] = useState(false);
    const history = useHistory()
    const dispatch = useDispatch();
    const {currentUser, logout} = useAuth()

    const [signoutTime, setSignoutTime] = useState(180000);
    const [warningTime, setWarningTime] = useState(120000);
    const [remainingTime, setRemainingTime] = useState(0);

    async function handleLogout() {

        try {
            await logout()
            history.push("/")
            dispatch(userInfo({}));
            dispatch(authUser({}));
            dispatch(operatorsInfo({}));
            dispatch(transactionInfo({}));
            dispatch(userDash({}));
            dispatch(operatorsInfo({}));
            dispatch(transactionInfoMild({}))
            dispatch(tradesMonthlyRevenueInfo({}))
            //dispatch(operatorsMemInfo({}));
        } catch {
            console.error("Failed to log out")
        }
    }


    useEffect(() => {
        const createTimeout1 = () => setTimeout(() => {
            setWarningModalOpen(true);
        }, 60000) //controls the warning....

        const createTimeout2 = () => setTimeout(() => {
            // Implement a sign out function here
            handleLogout()
        }, 120000) //controls signing out time

        const listener = () => {
            if (!isWarningModalOpen) {
                clearTimeout(timeout)
                timeout = createTimeout1();
            }
        }

        // Initialization
        let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1()
        addEventListeners(listener);

        // Cleanup
        return () => {
            removeEventListeners(listener);
            clearTimeout(timeout);
        }
    }, [isWarningModalOpen])
    return (
        /* <div>
             {isWarningModalOpen && (
                 <TimeoutWarningModal
                     isOpen={isWarningModalOpen}
                     onRequestClose={() => setWarningModalOpen(false)}
                 />
             )
             }
         </div>*/
        <div>
            <div style={{
                display: currentUser ? 'block' : 'none', width: 700, padding: 30
            }}>
                {isWarningModalOpen && (
                    <Modal isOpen={isWarningModalOpen} toggle={() => setWarningModalOpen(false)}
                           style={{marginTop: "20%"}}>

                        <ModalHeader style={{color: "crimson"}}
                                     toggle={() => setWarningModalOpen(false)}>Shutdown Warning... </ModalHeader>
                        <ModalBody>
                            <CountdownCircleTimer
                                isPlaying
                                duration={120000 / 1000}
                                colors={[
                                    ['#004777', 0.33],
                                    ['#F7B801', 0.33],
                                    ['#A30000', 0.33],
                                ]}
                            >

                                {({remainingTime}) => {
                                    setRemainingTime(Number(remainingTime))
                                    return Number(remainingTime).toFixed(0)
                                }
                                }
                            </CountdownCircleTimer>
                            <span>Signing out in {Number(remainingTime / 60).toFixed(2)} minutes</span>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => setWarningModalOpen(false)}>Exit</Button>
                        </ModalFooter>
                    </Modal>)
                }
            </div>
        </div>


    )
}
import AnalyticsFeaturedInfo from "../../components/featuredInfo/AnalyticsFeaturedInfo";
import Chart from "../../components/charts/AnalysisChart";
import {userData} from "../../DummyData";
import AnalyticsWidgetLg from "../../components/widgetLg/AnalyticsWidgetLg";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getObjectKeys, getObjectValues, isEmpty, isNegativeNumber} from "../../components/utils/Utils";
import AnalyticsWidgetSm from "../../components/widgetSm/AnalyticsWidgetSm";
import ViewsDatePicker from "../../components/utils/DatePickerLocal";
import BeatLoader from "react-spinners/ClipLoader";
import RingLoader from "react-spinners/RingLoader";
import "./home.css"
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../../firebase";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


let lineChatData = {};
export default function Analytics() {
    const [loading, setLoading] = useState(true);
    let color = "#00ff0e";
    const _operators = useSelector((state) => state.operatorsInfo.value);
    const [yearlyTransactions, setYearlyTransactions] = useState({});
    const _transactions = useSelector((state) => state.transactionInfoMild.value);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const _user = useSelector((state) => state.userInfo.value);
    const [_dataByOperators, setDataByOperators] = useState([]);
    const [highestGrossingMethodValue, setHighestGrossingMethodValue] = useState([]);
    const [prepData, setPrepData] = useState([]);
    const [lineChartRetVal, setLineChartRetVal] = useState([]);

    const [open, setOpen] = React.useState(false);
    //const handleClose = () => setOpen(false);

    const setOpenCallback = (value)=>setOpen(value);


//by current month and current year
    const dataByOperators = (transactions, operators, selectedDate) => {
        setOpenCallback(true)
        let retval = {};
        transactions && transactions.filter((value) => value.orderCloseTime.toDate().getFullYear() === selectedDate.getFullYear()
            && value.orderCloseTime.toDate().getMonth() === selectedDate.getMonth()).map((value, key) => {
            //Reason for the math.round is to cut 20% of the comment value from behind, because of comments not exactly matching operator id values
            let _tempName = operators && operators.find((_value) => {
                    // let strippedValue = _value.name.toUpperCase();
                    // return value.orderComment.trim().toUpperCase().includes(strippedValue.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')
                    //     .slice(0, (/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu.test(strippedValue) ? strippedValue.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')
                    //         .length - Math.round(strippedValue.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').length * 0.45) : strippedValue.length)))

                    return compareOperatorName(_value.name.toUpperCase().trim() , value.orderComment.trim()) ? _value.name.toUpperCase() : undefined

                }
            )
            //value.orderComment.includes(selectedOperatorName.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').slice(0, selectedOperatorName.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').length - (0.2 * selectedOperatorName.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').length))))

            const operatorAcutalName = value.orderComment && _tempName ? _tempName.name : undefined;
            if (operatorAcutalName) {
                if (retval[operatorAcutalName]) {
                    retval[operatorAcutalName] =
                        {
                            ...retval[operatorAcutalName],
                            count: retval[operatorAcutalName]["count"] + 1,
                            profits: retval[operatorAcutalName]["profits"] + Number(value.orderProfit),
                            mostTradedSymbol: {
                                ...retval[operatorAcutalName]["mostTradedSymbol"],
                                [value.orderSymbol.trim()]: retval[operatorAcutalName]["mostTradedSymbol"][value.orderSymbol.trim()] ? Number(retval[operatorAcutalName]["mostTradedSymbol"][value.orderSymbol.trim()]) + 1 : 1,
                            },
                            Buys: value.orderType === "BUY" ? retval[operatorAcutalName]["totalBuys"] + 1 : retval[operatorAcutalName]["totalBuys"],
                            totalSells: value.orderType === "SELL" ? retval[operatorAcutalName]["totalSells"] + 1 : retval[operatorAcutalName]["totalSells"],
                            totalSellWins: value.orderType.trim() === "SELL" && !isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalSellWins"] + 1 : retval[operatorAcutalName]["totalSellWins"],
                            totalSellLoss: value.orderType.trim() === "SELL" && isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalSellLoss"] + 1 : retval[operatorAcutalName]["totalSellLoss"],
                            totalBuyWins: value.orderType.trim() === "BUY" && !isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalBuyWins"] + 1 : retval[operatorAcutalName]["totalBuyWins"],
                            totalBuyLoss: value.orderType.trim() === "BUY" && isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalBuyLoss"] + 1 : retval[operatorAcutalName]["totalBuyLoss"],
                            totalWins: !isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalWins"] + 1 : retval[operatorAcutalName]["totalWins"],
                            totalLoss: isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalLoss"] + 1 : retval[operatorAcutalName]["totalLoss"],
                        }

                } else {
                    retval =
                        {
                            ...retval,
                            [operatorAcutalName]: {
                                count: 1,
                                profits: Number(value.orderProfit),
                                mostTradedSymbol: {
                                    [value.orderSymbol.trim()]: 1
                                },
                                totalBuys: value.orderType.trim() === "BUY" ? 1 : 0,
                                totalSells: value.orderType.trim() === "SELL" ? 1 : 0,
                                totalSellWins: value.orderType.trim() === "SELL" && !isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalSellLoss: value.orderType.trim() === "SELL" && isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalBuyWins: value.orderType.trim() === "BUY" && !isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalBuyLoss: value.orderType.trim() === "BUY" && isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalWins: !isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalLoss: isNegativeNumber(value.orderProfit) ? 1 : 0,
                            }
                        }
                }

            }


        })


        return retval;

    }

    //Compare Actual operator name vs the transaction operator name (which is sometime fucked up!)
    const compareOperatorName = (originalName, comparisonValue) => {
        // Function to clean and split the string
        const cleanAndSplit = (str) => {
            return str.replace(/\[TP\]|\bTP\b|\[SL\]|\bSL\b/gi, '')  // Remove special words
                      .split(' ')                                    // Split by spaces
                      .map(value => value.trim().toUpperCase())      // Trim and convert to uppercase
                      .filter(value => value);                       // Remove empty strings
        };
    
        // Function to remove special characters and Unicode
        const removeSpecialChars = (str) => {
            return str.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
        };
    
        const splitOriginal = cleanAndSplit(originalName);
        let splitComparison = cleanAndSplit(comparisonValue);
    
        // Remove special characters from splitComparison
        splitComparison = splitComparison.map(removeSpecialChars);
    
        // Check if lengths of the split arrays are the same
        if (splitOriginal.length !== splitComparison.length) {
            return false;
        }

        let retVal = splitComparison.every(value => splitOriginal.some(origValue => origValue.includes(value)));
    
        // Check if all split values from comparisonValue are present in the originalName
        return retVal;
    };


    useEffect(async () => {
        setLoading(true);
            if(isEmpty(yearlyTransactions) && isEmpty(prepData) )
            {
                let _transList = [];
                const tradeHistoryCollectionRef = collection(db, "Trade_history_report");
                //const q = query(tradeHistoryCollectionRef, where("userId", "==", _user && _user._userInfo[0] ? " " + _user._userInfo[0].User_Id.toString() : 0), where("orderCloseTime", ">=", new Date(new Date().getFullYear(), 0, 1)), orderBy("orderCloseTime", "desc"));
                const q = query(
                    tradeHistoryCollectionRef,
                    where("userId", "==", _user && _user._userInfo[0] ? _user._userInfo[0].User_Id.toString() : 0),
                    where("orderCloseTime", ">=", new Date(new Date().getFullYear(), 0, 1)),
                    orderBy("orderCloseTime", "desc")
                );
                const data = await getDocs(q);
                data.docs.map((doc) => {
                    _transList.push(({...doc.data(), id: doc.id}))
                })
                setYearlyTransactions({_transList : _transList})
                let _prepData = prepareDate(_operators && _operators._operatorsList, _transList , selectedDate);
                setPrepData(_prepData);
                let _lineChartRetVal = prepareLineChartDataByOperatorAndCurrentMonth(_prepData, selectedDate)
                setLineChartRetVal(_lineChartRetVal);
                setDataByOperators (dataByOperators(_transactions && _transactions._transList, _operators && _operators._operatorsList, selectedDate));
                setHighestGrossingMethodValue(higestGrossing(_lineChartRetVal))
            }else
            {

                let _prepData = prepareDate(_operators && _operators._operatorsList, yearlyTransactions && yearlyTransactions._transList, selectedDate)
                setPrepData(_prepData)
                let _lineChartRetVal = prepareLineChartDataByOperatorAndCurrentMonth(_prepData, selectedDate);
                setLineChartRetVal(_lineChartRetVal);
                setDataByOperators (dataByOperators(yearlyTransactions && yearlyTransactions._transList, _operators && _operators._operatorsList, selectedDate));
                setHighestGrossingMethodValue(higestGrossing(_lineChartRetVal))
            }

            setLoading(false);
    }, [selectedDate])

    const prepareDate = (_operators, _transactions, selectedDate) => {
        setOpenCallback(true)
        let chartOnecData = [
            {
                id: 1,
                name: "Jan"
            },
            {
                id: 2,
                name: "Feb"
            },
            {
                id: 3,
                name: "Mar"

            }
            ,
            {
                id: 4,
                name: "Apr"

            }
            ,
            {
                id: 5,
                name: "May"

            }
            ,
            {
                id: 6,
                name: "Jun"

            }
            ,
            {
                id: 7,
                name: "Jul"

            }
            ,
            {
                id: 8,
                name: "Aug"

            }
            ,
            {
                id: 9,
                name: "Sep"

            }
            ,
            {
                id: 10,
                name: "Oct"

            },
            {
                id: 11,
                name: "Nov"

            }
            ,
            {
                id: 12,
                name: "Dec"

            }
        ]


        let _transList =[];
        if(_transactions == null)
        {
            const runner = async()=>{
                //get new dat and load yearly data.
                const tradeHistoryCollectionRef = collection(db, "Trade_history_report");
                const q = query(tradeHistoryCollectionRef, where("userId", "==", _user && _user._userInfo[0] ? " " + _user._userInfo[0].User_Id.toString() : 0), where("orderCloseTime", ">=", new Date(new Date().getFullYear(), 0, 1)), orderBy("orderCloseTime", "desc"));
                const data = await getDocs(q);
                data.docs.map((doc) => {
                    _transList.push(({...doc.data(), id: doc.id}))
                })
                setYearlyTransactions({_transList : _transList})
                _transList && _transList.filter((value) => value.orderCloseTime.toDate().getFullYear() === selectedDate.getFullYear()).map((value, key) => {
                    const transactionMonth = value.orderCloseTime.toDate().getMonth();
                    const operator = value.orderComment.trim();
                    const operatorAcutalName = _operators && _operators.find((value) => operator.toUpperCase().includes(value.name.toUpperCase()));
                    //get keys of the object
                    //if object key has the operator add the value... else add the operator and add the value
                    if (operatorAcutalName) {
                        if (getObjectKeys(chartOnecData[transactionMonth]).find((x) => operatorAcutalName.name.toUpperCase() === x.toUpperCase())) {
                            //chartOnecData[transactionMonth].[operator]
                            chartOnecData[transactionMonth] = {
                                ...chartOnecData[transactionMonth],
                                [operatorAcutalName.name]: Math.round(Number(chartOnecData[transactionMonth][operatorAcutalName.name] + Number(value.orderProfit)) + Number.EPSILON) * 100 / 100,
                            }

                        } else
                            chartOnecData[transactionMonth] =
                                {
                                    ...chartOnecData[transactionMonth],
                                    [operatorAcutalName.name]: Number(value.orderProfit),
                                }

                    }
                })
            }
            runner();

        }else{
            _transactions && _transactions.filter((value) => value.orderCloseTime.toDate().getFullYear() === selectedDate.getFullYear()).map((value, key) => {
                const transactionMonth = value.orderCloseTime.toDate().getMonth();
                const operator = value.orderComment.trim();
                const operatorAcutalName = _operators && _operators.find((value) => operator.toUpperCase().includes(value.name.toUpperCase()));
                //get keys of the object
                //if object key has the operator add the value... else add the operator and add the value
                if (operatorAcutalName) {
                    if (getObjectKeys(chartOnecData[transactionMonth]).find((x) => operatorAcutalName.name.toUpperCase() === x.toUpperCase())) {
                        //chartOnecData[transactionMonth].[operator]
                        chartOnecData[transactionMonth] = {
                            ...chartOnecData[transactionMonth],
                            [operatorAcutalName.name]: Math.round(Number(chartOnecData[transactionMonth][operatorAcutalName.name] + Number(value.orderProfit)) + Number.EPSILON) * 100 / 100,
                        }

                    } else
                        chartOnecData[transactionMonth] =
                            {
                                ...chartOnecData[transactionMonth],
                                [operatorAcutalName.name]: Number(value.orderProfit),
                            }

                }
            })

        }
        setOpenCallback(false)

        return chartOnecData;

    }




    const handleCallback = async (childData) => {
        setSelectedDate(childData)

    }
    const higestGrossing = (lineChartRetVal) => {
        let retVal = lineChartRetVal ? lineChartRetVal[0] : [];
        lineChartRetVal && lineChartRetVal.map((_value) => {
            retVal = Number(_value["value"]) >= Number(retVal["value"]) ? _value : retVal
            return retVal
        })

        return retVal
    }


    return (
        <div className="home">
            {
                loading ?
                    <div className="sweet-loading analytics">
                        <BeatLoader
                            color={color}
                            loading={loading}
                            size={60}/>
                    </div>
                    :
                    <div>

                        <div style={{display: "none"}}>
                            <Modal
                                open={open}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box >
                                    <Typography style={{textAlign: "center", fontStyle: "bold", fontFamily: "sans-serif"}}
                                                id="modal-modal-title" variant="h6"
                                                component="h2">
                                        <div className="sweet-loading component-loader">
                                            <RingLoader
                                                color={color}
                                                loading={true}
                                                size={60}/>
                                        </div>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                        <ViewsDatePicker text={"Your Trade Analysis"} function={handleCallback}/>
                        <AnalyticsFeaturedInfo lineChartRetVal={lineChartRetVal} transactions={_transactions}
                                               prepData={prepData}
                                               dataByOperators={{_dataByOperators}}
                                               higestGrossing={highestGrossingMethodValue} setOpenCallback={setOpenCallback}/>
                        <Chart data={userData} _operators={_operators}
                               _transactions={ !isEmpty(yearlyTransactions)  ? yearlyTransactions && yearlyTransactions._transList.filter((trans) => trans.orderCloseTime.toDate().getMonth() === selectedDate.getMonth())  : _transactions && _transactions._transList.filter((trans) => trans.orderCloseTime.toDate().getMonth() === selectedDate.getMonth() && trans.orderCloseTime.toDate().getFullYear() === selectedDate.getFullYear())}
                               lineChartRetVal={lineChartRetVal} title="Operator Stats "
                               prepData={prepData}
                               selectedDate={selectedDate}
                               yearlyTransactions={yearlyTransactions}
                               dataKey="Active User"
                               setOpenCallback={setOpenCallback}
                        />
                        <div className="homeWidgets">
                            <AnalyticsWidgetLg dataByOperators={_dataByOperators}/>
                            <AnalyticsWidgetSm dataByOperators={_dataByOperators}/>
                        </div>
                    </div>
            }
        </div>
    );
}

const prepareLineChartDataByOperatorAndCurrentMonth = (chartOneData, selectedDate) => {
    const currentMonth = selectedDate.getMonth();
    let retval = [];
    const getValues = getObjectValues(chartOneData[currentMonth]);
    chartOneData && getObjectKeys(chartOneData[currentMonth]).map((value, key) => {
        if (value === "name" || value === "id")
            return
        else
            retval.push(lineChatData = {
                ...lineChatData,
                name: value,
                value: getValues[key]
            })
    })

    return retval;
}


import {createSlice} from "@reduxjs/toolkit"

const initialState = {};
export const operatorsMemSlice = createSlice({
    name: "operatorsMemInformation",
    initialState: initialState,
    reducers: {
        operatorsMemInfo: (state, action) => {
            state.value = action.payload;
            ;
        },
    }
});


export const {operatorsMemInfo} = operatorsMemSlice.actions;
export default operatorsMemSlice.reducer;
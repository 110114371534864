import "./sidebar.css";
import {
    BarChart,
    ChatBubbleOutline,
    DynamicFeed,
    LineStyle,
    MailOutline,
    NetworkLockedOutlined,
    PermIdentity,
    Report,
    Storefront,
    TrendingUp,
    WorkOutline,
} from "@material-ui/icons";
import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useState,} from "react";
import {authUser} from "../../reducers/Users-reducer";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {auth, db} from "../../firebase";
import {userInfo} from "../../reducers/UserInformation-reducer";
import {transactionInfo} from "../../reducers/TransactionLis-reducer";
import {userTransactionInfo} from "../../reducers/Users-TransList-Reducer"
import {operatorsInfo} from "../../reducers/Operators-Reducers";
import {isEmpty} from "../utils/Utils";
import {useAuthState} from "react-firebase-hooks/auth";
import BeatLoader from "react-spinners/ClipLoader";
import {tradesMonthlyRevenueInfo} from "../../reducers/RevenuePermonthPerUser-reducer";
import {useDispatch, useSelector} from "react-redux";
import {transactionInfoMild} from "../../reducers/Transactions-Mild";


export default function Sidebar() {

    const [clicked, setClicked] = useState("");

    const setActiveClassName = (id) => {
        setClicked(id)
    }

    const [currentUser, setCurrentUser] = useState([]);
    const [user] = useAuthState(auth);
    let _userInfo = [];
    const [loading, setLoading] = useState(true);
    let color = "#00ff0e";

    const history = useHistory();

//set authenticated user from fire auth
    const dispatch = useDispatch();
    const transactions = useSelector((state) => state.transactionInfoMild.value);

    //loads at startup *once
    useEffect(() => {
        dispatch(authUser({user})); //set the authenticated User in to redux state
        // fetch user infomation from DB
        const usersInfoCollectionRef = collection(db, "User_Information");
        //fetching user records from the database by email and saving user info to central store
        const getUserInfo = async () => {
            const data = await getDocs(usersInfoCollectionRef);
            _userInfo = data.docs.map((doc) => ({...doc.data(), id: doc.id}));
            _userInfo = _userInfo.filter(value => userEmail.toUpperCase() === value.email.toUpperCase());
            dispatch(userInfo({_userInfo}));
        }


        // Pull transactions from the DB to the central store
        const transactionCollectionRef = collection(db, "Trade_history_report")
        let _transList = null;
        const setTransactionsList = async () =>
        {
            const botId = _userInfo.length > 0  ? _userInfo[0].Signal_Owner_Id : "";
            const _data = await getDocs(transactionCollectionRef).then((data) => {
                let _transList = data.docs.map((doc, key) => ({
                    ...doc.data(),
                    id: key + 1,
                }));
                dispatch(transactionInfo({_transList}));//save it to the state for future use before mutation
                //retrieve only the signed persons transactions
                _transList = _transList.filter(value => botId.toUpperCase() === value.userId.trim().toUpperCase() /*&& value.orderCloseTime.toDate().getFullYear() === new Date().getFullYear()*/); //default data to the current month
                // setTransactions());
                //dispatch(userTransactionInfo({_transList: _transList.sort((a, b) => b.orderCloseTime.toDate() - a.orderCloseTime.toDate())}));//save logged In user transaction list state for future use before mutation
                return _transList
            })

            return _transList;
        }

        //userMonthlyrevenue
        const runQuery = async () => {
            const monthlyEquityPerUserCollectionRef = collection(db, "Monthly_equity_per_user");
            const q = query(monthlyEquityPerUserCollectionRef, where("email", "==", user.email));
            const data = await getDocs(q);
            const _tempData = data.docs.map((doc) => ({...doc.data(), id: doc.id}));
            /*_tempData && _tempData[0] && setRevenueData([
                {name: "Jan", Revenue: parseFloat(_tempData[0].equity_per_month["jan"]) },
                {name: "Feb", Revenue: parseFloat(_tempData[0].equity_per_month["feb"]) },
                {name: "Mar", Revenue: parseFloat(_tempData[0].equity_per_month["mar"]) },
                {name: "Apr", Revenue: parseFloat(_tempData[0].equity_per_month["apr"]) },
                {name: "May", Revenue: parseFloat(_tempData[0].equity_per_month["may"]) },
                {name: "Jun", Revenue: parseFloat(_tempData[0].equity_per_month["jun"]) },
                {name: "Jul", Revenue: parseFloat(_tempData[0].equity_per_month["jul"]) },
                {name: "Aug", Revenue: parseFloat(_tempData[0].equity_per_month["aug"]) },
                {name: "Sep", Revenue: parseFloat(_tempData[0].equity_per_month["sep"]) },
                {name: "Oct", Revenue: parseFloat(_tempData[0].equity_per_month["oct"]) },
                {name: "Nov", Revenue: parseFloat(_tempData[0].equity_per_month["nov"]) },
                {name: "Dec", Revenue: parseFloat(_tempData[0].equity_per_month["dec"]) }
            ])*/
            dispatch(tradesMonthlyRevenueInfo({_tempData}));

        }

        // Pull operators information from the DB to the central store
        const operatorsInformationCollectionRef = collection(db, "Operators_Information");
        const setOperatorsList = async () => {
            let operatorsList = [];
            const _data = await getDocs(operatorsInformationCollectionRef).then((data) => {
                let _operatorsList = data.docs.map((doc, key) => ({
                    ...doc.data(),
                    id: key + 1,
                }));
                dispatch(operatorsInfo({_operatorsList}));//save it to the state for future use before mutation
                //retrieve only the signed persons transactions
                operatorsList = _operatorsList;
            })

            return operatorsList;
        }


        const runner = async () => {

            await getUserInfo().then(() => {
                setCurrentUser(_userInfo)
                //check if user has bot id else force Update profile
                //runQuery();
                if (isEmpty(_userInfo) || _userInfo[0].Signal_Owner_Id === "")
                    history.push("/update-profile")
            }).then(() => {
                 setLoading(transactions ? false : true);
                    //dispatch(userTransactionInfo({_transList: transactions && transactions._data}));//save logged In user transaction list state for future use before mutation
                setOperatorsList();
                //setOperatorsMemList()
            });

        }


        runner();

    }, [transactions])


    const userEmail = user ? user.email : user
    return (
        <div className="sidebar">
            {

                loading ?
                    <div className="sweet-loading-sidebar">
                        <BeatLoader
                            color={color}
                            loading={loading}
                            size={60}/>
                    </div>
                    :

                    <div className="sidebar">
                        <div className="sidebarWrapper">

                            <div className="sidebarMenu">
                                <h3 className="sidebarTitle">Dashboard</h3>
                                <ul className="sidebarList">
                                    <Link to="/" className="link">
                                        <li id={"home"}
                                            className={clicked === "home" ? "sidebarListItem active" : "sidebarListItem"}
                                            onClick={(e) => setActiveClassName(e.target.id)}>
                                            <LineStyle className="sidebarIcon"/>
                                            Home
                                        </li>
                                    </Link>

                                    <Link to="/analytics">
                                        <li id={"analytics"}
                                            className={clicked === "analytics" ? "sidebarListItem active" : "sidebarListItem"}
                                            onClick={(e) => setActiveClassName(e.target.id)}>
                                            <BarChart className="sidebarIcon"/>
                                            Analytics
                                        </li>
                                    </Link>
                                    <Link to="/transactions" className="link">
                                        <li id={"transactions"}
                                            className={clicked === "transactions" ? "sidebarListItem active" : "sidebarListItem"}
                                            onClick={(e) => setActiveClassName(e.target.id)}>
                                            <DynamicFeed className="sidebarIcon"/>
                                            Journal
                                        </li>
                                    </Link>
                                    <Link to="/operator-analytics" className="link">
                                        <li id={"operatoranalytics"}
                                            className={clicked === "operatoranalytics" ? "sidebarListItem active" : "sidebarListItem"}
                                            onClick={(e) => setActiveClassName(e.target.id)}>
                                            <TrendingUp className="sidebarIcon"/>
                                            Operators
                                        </li>
                                    </Link>
                                    <Link to="/globalanalysis" className={"link"}>
                                        <li id={"globalanalysis"}
                                            className={clicked === "globalanalysis" ? "sidebarListItem active" : "sidebarListItem"}
                                            onClick={(e) => setActiveClassName(e.target.id)}>
                                            <NetworkLockedOutlined className="sidebarIcon"/>
                                            Global
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                            <div className="sidebarMenu">
                                <h3 className="sidebarTitle">Quick Menu</h3>
                                <ul className="sidebarList">
                                    <Link to="/users" className="link"
                                          hidden={currentUser && currentUser[0] && currentUser[0].role != "super_admin" || !currentUser[0]}>
                                        <li className="sidebarListItem">
                                            <PermIdentity className="sidebarIcon"/>
                                            Users
                                        </li>
                                    </Link>
                                    <Link to="/profile" className="link">
                                        <li className="sidebarListItem">
                                            <Storefront className="sidebarIcon"/>
                                            Profile
                                        </li>
                                    </Link>
                                    <Link to="/update-profile" className="link">
                                        <li className="sidebarListItem">
                                            <WorkOutline className="sidebarIcon"/>
                                            Settings
                                        </li>
                                    </Link>
                                    <Link to="/transactions" className="link">
                                        <li className={"sidebarListItem" + "active"}>
                                            <LineStyle className="sidebarIcon"/>
                                            Journal
                                        </li>
                                    </Link>
                                    <Link to="/report" className="link" hidden={currentUser && currentUser[0] && currentUser[0].role != "super_admin" || !currentUser[0]}>
                                        <li className="sidebarListItem">
                                            <BarChart className="sidebarIcon"/>
                                            Reports
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                            <div className="sidebarMenu"
                                 hidden={currentUser && currentUser[0] && currentUser[0].role != "super_admin" || !currentUser[0]}>
                                <h3 className="sidebarTitle">Notifications</h3>
                                <ul className="sidebarList">
                                    <li className="sidebarListItem">
                                        <MailOutline className="sidebarIcon"/>
                                        Mail
                                    </li>
                                    <li className="sidebarListItem">
                                        <DynamicFeed className="sidebarIcon"/>
                                        Feedback
                                    </li>
                                    <li className="sidebarListItem">
                                        <ChatBubbleOutline className="sidebarIcon"/>
                                        Messages
                                    </li>
                                </ul>
                            </div>
                            <div className="sidebarMenu"
                                 hidden={currentUser && currentUser[0] && currentUser[0].role != "super_admin" || !currentUser[0]}>
                                <h3 className="sidebarTitle">Staff</h3>
                                <ul className="sidebarList">
                                    <li className="sidebarListItem">
                                        <WorkOutline className="sidebarIcon"/>
                                        Manage
                                    </li>
                                    <li className="sidebarListItem">
                                        <BarChart className="sidebarIcon"/>
                                        Analytics
                                    </li>
                                    <li className="sidebarListItem"
                                        hidden={currentUser && currentUser[0] && currentUser[0].role != "super_admin" || !currentUser[0]}>
                                        <Report className="sidebarIcon"/>
                                        Reports
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}
import React, {useEffect} from "react"
import {AuthProvider} from "../contexts/AuthContext"
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import "./app.css"
import Login from "../public/Login"
import Signup from "../public/Signup";
import ForgotPassword from "../public/ForgotPassword";
import ResetPassword from "../public/ResetPassword";
import TermsCondition from "../components/public/TermsCondition"
import Home from "../user-area/home/home";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import PrivateRoute from "./auth/PrivateRoute";
import UserList from "../user-area/userList/UserList";
import TransList from "../user-area/userList/Transactions";
import UpdateProfile from "../user-area/home/UpdateProfile";
import Dashboard from "../user-area/home/Dashboard";
import Analytics from "../user-area/home/Analytics";
import AnalyticsGlobal from "../user-area/home/AnalyticsGlobal"
import OperatorAnalytics from "../user-area/home/OperatorAnalytics"
import TimeoutLogic from "./utils/TimeoutModelLogic"
import Body from "./public/Body"
import ProductCheckOut from "./public/ProductCheckout"
import ReactGa from "react-ga"
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";


function App() {
    const [user] = useAuthState(auth);


    useEffect(() => {

        ReactGa.initialize("UA-117514705-2");
        //reporting the home page
        ReactGa.pageview(window.location.pathname + window.location.search)
    }, [])

    return (
        <Router>
            <div>

                <Router>

                    <Route path="/welcome" component={Body}/>
                    <Route path={"/termsCondition"} component={TermsCondition}/>
                    <Route path={"/productCheckout"} component={ProductCheckOut}/>
                    <AuthProvider>
                        <Switch>
                            <div>
                                <PrivateRoute component={Header}/>
                                <div className="main-container" hidden={!user}>
                                    <PrivateRoute component={Sidebar} />
                                    <PrivateRoute exact path="/" component={Home}/>
                                    <PrivateRoute path="/users" component={UserList}/>
                                    <PrivateRoute path="/transactions" component={TransList}/>
                                    <PrivateRoute path="/analytics" component={Analytics}/>
                                    <PrivateRoute exact path="/profile" component={Dashboard}/>
                                    <PrivateRoute path="/update-profile" component={UpdateProfile}/>
                                    <PrivateRoute path="/operator-analytics" component={OperatorAnalytics}/>
                                    <PrivateRoute path="/globalanalysis" component={AnalyticsGlobal}/>
                                </div>
                                <div className="sec-container" hidden={user}>
                                    <PrivateRoute component={TimeoutLogic}/>
                                    {/*Unauthenticated path*/}
                                    <div className="w-100" style={{maxWidth: "400px"}}>
                                        <Route path="/signup" component={Signup}/>
                                        <Route path="/login" component={Login}/>
                                        <Route path="/forgot-password" component={ForgotPassword}/>
                                        <Route path="/reset-password" component={ResetPassword}/>
                                    </div>
                                </div>
                            </div>
                        </Switch>
                    </AuthProvider>
                </Router>
            </div>
        </Router>
    )
}

export default App

export const isEmpty =(object)=>{
    return Object.keys(object).length === 0
}


export const higestOccurance=(arr)=>{
    return arr && arr.sort((a, b) =>
        arr.filter(v => v === a).length
        - arr.filter(v => v === b).length
    ).pop();
}

export function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
}

export const countHighestOccurance = (arr, symbol) => {
    let counts = 0;
    arr && arr.map((value, key) => {

        if (value.trim() === symbol.trim())
            counts = counts + 1;
    })
    return counts;
}


export const findHighestValueOfObjectElement = (obj) => {
    let higestCount = 0;
    let objectValue = "";

    obj &&  getObjectKeys(obj).forEach(((value, index) => {
        if (obj[value] > higestCount) {
            objectValue = value;
            higestCount = obj[value];
        }
    }))
    return objectValue
}


export const getObjectKeys = (object) => {
    return Object.keys(object);
}

export const getObjectValues = (object) => {
    return Object.values(object)
}

export const generateRandomColor = () => {
    let maxVal = 0xFFFFFF; // 16777215
    let randomNumber = Math.random() * maxVal;
    randomNumber = Math.floor(randomNumber);
    randomNumber = randomNumber.toString(16);
    let randColor = randomNumber.padStart(6, 0);
    return `#${randColor.toUpperCase()}`
}


export const isNegativeNumber = (value) => {
    return Math.sign(value) === -1;
}

export const getMonthString = (monthNumber) => {
    let retval = '';
    const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    if (monthNumber => 0 && monthNumber <= 12)
        retval = monthString[monthNumber];
    else
        retval = "Invalid Month number"

    return retval
}

export const decimalToWholeNumber = (_number) => {
    return Math.round(_number + Number.EPSILON) * 100 / 100
}


export function roundUp(num, precision) {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
}

export function listEmpty(list) {
    return list && list.length > 0 ? false : true;
}

export function binarySearchNumberOrderedList(searchObject, list) {
    let index = -1;
    if (!listEmpty(list)) {
        let first = 0;
        let last = list.length - 1;
        while (first <= last && index == -1) {
            const mid = Math.floor((first + last) / 2);
            if (list[mid] == searchObject) {
                index = mid;
            } else if (searchObject < list[mid]) {
                last = mid - 1;
            } else {
                first = mid + 1
            }
        }

    }
    return index;
}

export function specialBinarySearchNumberOrderedListOperatorsList(searchObject, list) {
    let index = -1;
    if (!listEmpty(list)) {
        let first = 0;
        let last = list.length - 1;
        while (first <= last && index == -1) {
            const mid = Math.floor((first + last) / 2);
            if (Math.abs(Number(list[mid].split("|")[0].trim())) == Math.abs(searchObject)) {
                index = mid;
            } else if (Math.abs(searchObject) < Math.abs(Number(list[mid].split("|")[0].trim()))) {
                last = mid - 1;
            } else {
                first = mid + 1
            }
        }

    }
    return index;
}

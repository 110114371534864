export const userData = [
    {
        name: "Jan",
        "Active User": 4000,
    },
    {
        name: "Feb",
        "Active User": 3000,
    },
    {
        name: "Mar",
        "Active User": 5000,
    },
    {
        name: "Apr",
        "Active User": 4000,
    },
    {
        name: "May",
        "Active User": 3000,
    },
    {
        name: "Jun",
        "Active User": 2000,
    },
    {
        name: "Jul",
        "Active User": 4000,
    },
    {
        name: "Agu",
        "Active User": 3000,
    },
    {
        name: "Sep",
        "Active User": 4000,
    },
    {
        name: "Oct",
        "Active User": 1000,
    },
    {
        name: "Nov",
        "Active User": 4000,
    },
    {
        name: "Dec",
        "Active User": 3000,
    },
];


export const revenuePerMonth = [
    {
        name: "Jan",
        Revenue: 12,
    },
    {
        name: "Feb",
        Revenue: 20,
    },
    {
        name: "Mar",
        Revenue: 30,
    },
    {
        name: "Apr",
        Revenue: 10,
    },
    {
        name: "May",
        Revenue: 15,
    },
    {
        name: "Jun",
        Revenue: 0,
    },
    {
        name: "Jul",
        Revenue: 0,
    },
    {
        name: "Aug",
        Revenue: 0,
    },
    {
        name: "Sep",
        Revenue: 0,
    },
    {
        name: "Oct",
        Revenue: 0,
    },
    {
        name: "Nov",
        Revenue: 0,
    },
    {
        name: "Dec",
        Revenue: 0,
    },
];

export const productData = [
    {
        name: "Jan",
        "Sales": 4000,
    },
    {
        name: "Feb",
        "Sales": 3000,
    },
    {
        name: "Mar",
        "Sales": 5000,
    },
];

export const userRows = [
    {
        id: 1,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
    {
        id: 2,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
    {
        id: 3,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
    {
        id: 4,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
    {
        id: 5,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
    {
        id: 6,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
    {
        id: 7,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
    {
        id: 8,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
    {
        id: 9,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
    {
        id: 10,
        username: "Jon Snow",
        avatar:
            "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        email: "jon@gmail.com",
        status: "active",
        transaction: "$120.00",
    },
];

export const productRows = [
    {
        id: 1,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
    {
        id: 2,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
    {
        id: 3,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
    {
        id: 4,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
    {
        id: 5,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
    {
        id: 6,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
    {
        id: 7,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
    {
        id: 8,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
    {
        id: 9,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
    {
        id: 10,
        name: "Apple Airpods",
        img:
            "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
        stock: 123,
        status: "active",
        price: "$120.00",
    },
];


export const chartOnecData = [
    {
        id: 1,
        name: "Jan"
    },
    {
        id: 2,
        name: "Feb"
    },
    {
        id: 3,
        name: "Mar"

    }
    ,
    {
        id: 4,
        name: "Apr"

    }
    ,
    {
        id: 5,
        name: "May"

    }
    ,
    {
        id: 6,
        name: "Jun"

    }
    ,
    {
        id: 7,
        name: "Jul"

    }
    ,
    {
        id: 8,
        name: "Aug"

    }
    ,
    {
        id: 9,
        name: "Sep"

    }
    ,
    {
        id: 10,
        name: "Oct"

    },
    {
        id: 11,
        name: "Nov"

    }
    ,
    {
        id: 12,
        name: "Dec"

    }
]


export const dataAnalytics = [
    {
        "name": "Jan",
        "forex VIP": 4000,
        "TJ Fx": 2400,
        "Fx Direct": 3400,
        "M15 signals": 4600,
        "VIP Signals": -2400,
        "1000pips builder": 8465,
        "amt": 2400
    },
    {
        "name": "Feb",
        "forex VIP": 500,
        "TJ Fx": 5677,
        "Fx Direct": 2355,
        "M15 signals": 9876,
        "VIP Signals": 3522,
        "1000pips builder": 8367,
        "amt": 6578
    },
    {
        "name": "Mar",
        "forex VIP": 5764,
        "TJ Fx": 7463,
        "Fx Direct": 2456,
        "M15 signals": 3673,
        "VIP Signals": 4678,
        "1000pips builder": 4367,
        "amt": 9723
    },
    {
        "name": "Apr",
        "forex VIP": 6342,
        "TJ Fx": 2456,
        "Fx Direct": 4567,
        "M15 signals": 4565,
        "VIP Signals": 2345,
        "1000pips builder": 2353,
        "amt": 6578
    },
    {
        "name": "May",
        "forex VIP": 4567,
        "TJ Fx": 345,
        "Fx Direct": 5678,
        "M15 signals": 8993,
        "VIP Signals": 6735,
        "1000pips builder": 3427,
        "amt": 6578
    },
    {
        "name": "Dec",
        "forex VIP": 4567,
        "TJ Fx": 345,
        "Fx Direct": 5678,
        "M15 signals": 8993,
        "VIP Signals": 6735,
        "1000pips builder": 3427,
        "amt": 6578
    },

    {
        "name": "Page F",
        "uv": 2390,
        "pv": 3800,
        "amt": 2500
    },
    {
        "name": "Page G",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
    }
]

export const pieChart = [
    {
        "name": "Group A",
        "value": 400
    },
    {
        "name": "Group B",
        "value": 300
    },
    {
        "name": "Group C",
        "value": 300
    },
    {
        "name": "Group D",
        "value": 200
    },
    {
        "name": "Group E",
        "value": 278
    },
    {
        "name": "Group F",
        "value": 189
    }
];

export const treeData = [
    {
        "name": "axis",
        "size": 24593,
    },
    {
        "name": "controls",
        "size": 84358
    },
    {
        "name": "data",
        "size": 20544
    },
    {
        "name": "events",
        "size": 7313
    },
    {
        "name": "legend",
        "size": 20859
    },
    {
        "name": "operator",
        "size": 60314

    },
    {
        "name": "encoder",
        "size": 4138
    },
    {
        "name": "filter",
        "size": 5219
    },
    {
        "name": "IOperator",
        "size": 1286
    },
    {
        "name": "label",
        "size": 9956
    },
    {
        "name": "layout",
        "size": 12348
    },
    {
        "name": "OperatorList",
        "size": 5248
    },
    {
        "name": "OperatorSequence",
        "size": 4190
    },
    {
        "name": "OperatorSwitch",
        "size": 2581
    },
    {
        "name": "Operator",
        "size": 2490
    },
    {
        "name": "SortOperator",
        "size": 2023
    }
]

export const piezData = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Votes',
            data: [-102, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 0,
        },
    ],
};

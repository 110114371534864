import React, {useRef, useState} from "react"
import {Alert, Button, Card, Form} from "react-bootstrap"
import {useAuth} from "../contexts/AuthContext"
import {Link, useHistory} from "react-router-dom"
import Toolbar from "@material-ui/core/Toolbar";
import "./login.css"
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase"


export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const {login} = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    let innerEmail = "";
    let subExpiration = false;

    async function handleSubmit(e) {
        e.preventDefault();
        let loginData = {
            email: emailRef.current.value,
            password: passwordRef.current.value
        }

        try {
            setError("")
            setLoading(true)
            //check subscription information first to make sure they allowed on here
            const usersSubCollectionRef = collection(db, "Subscription_Information");
            const getUserInfo = async () => {
                let retvalue = false

                const data = await getDocs(usersSubCollectionRef);
                data.docs.map((_data) => {
                    if (_data.data().email === loginData.email) {
                        innerEmail = _data.data().email;
                        if(new Date() > _data.data().Subscription_End.toDate())
                            subExpiration = true;
                        return
                    }
                })

                return retvalue;
            }

            await getUserInfo();

                if (innerEmail ===""){
                    //Subscription information doesnt exist for user.... !!get out!
                    setError("Subscription information doesnt exist for user... Please Signup");
                    return
                }

            if (subExpiration) {
            setError("Subscription Expired.. Please renew");
             history.push({
             type: "renewal",
             renew: {email: loginData.email },
             pathname: "/productCheckout"
             })
                return ;
            }

            await login(emailRef.current.value, passwordRef.current.value)
            //login successful at this point then set some s
            history.push("/")
        } catch (e) {
            console.error("errrror mesaage", e);
            setError("Failed to log in")
        }

        setLoading(false)
    }

    return (
        <div className={"loginClassbody"} >
            <Card >
                <Card.Body >
                    <Toolbar className="login">
                        <h2 className="text-center mb-4 login">Login</h2></Toolbar>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                             <i  className="fa fa-envelope envelopeLogin"></i>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                             <i className="fa fa-lock envelopeLogin"></i>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100 loginButton" type="submit">
                            Login
                        </Button>
                    </Form>
                    <div className="w-100 text-center mt-3">
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Need an account? <Link to="/signup">Sign Up</Link>
            </div>
        </div>
    )
}
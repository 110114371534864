import "./widgetLg.css";
import Moment from "moment";

export default function WidgetLg({transactions : transactions}) {
    const Button = ({ type }) => {
        return <button className={"widgetLgButton " + type}>{type}</button>;
    };
    return (
        <div className="widgetLg">
            <div><h3 className="widgetLgTitle">Latest PnL transactions <span className="widgetLgTitle span">by Operator</span></h3> </div>
            <table className="widgetLgTable">
                <tr className="widgetLgTr">
                    <th className="widgetLgTh">Operator</th>
                    <th className="widgetLgTh">Date</th>
                    <th className="widgetLgTh">Profit</th>
                    <th className="widgetLgTh">Status</th>
                </tr>
                {
                    transactions && transactions.slice(0, 5).map((value, key)=>{
                        return <>
                            <tr className="widgetLgTr">
                                <td className="widgetLgUser">
                                    <img
                                        src="https://www.forexreviews.info/wp-content/uploads/2014/06/audusdgreenandred.jpg"
                                        alt="forex"
                                        className="widgetLgImg"
                                    />
                                    <span className="widgetLgName">{value.orderComment}</span>
                                </td>
                                <td className="widgetLgDate">{ Moment(value.orderCloseTime.toDate()).format('YYYY-MM-DD HH:mm')}</td>
                                <td className="widgetLgAmount">{Number(String(value.orderProfit).trim())}</td>
                                <td className="widgetLgStatus">
                                    <Button type={ Math.sign(Number(String(value.orderProfit).trim())) == -1 ? "Loss" : "Win"} />
                                </td>
                            </tr>
                        </>
                    })

                }


            </table>
        </div>
    );
}
import {DataGrid, GridToolbar} from "@material-ui/data-grid";
import {ViewDay} from "@material-ui/icons";
import {userRows} from "../../DummyData";
import Link from "@material-ui/core/Link";
import React, {useEffect, useState} from "react";
import "./userList.css"
import {useSelector} from "react-redux";
import Moment from 'moment';
import Toolbar from "@mui/material/Toolbar";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import ListItemText from "@material-ui/core/ListItemText";
import {isEmpty, listEmpty} from "../../components/utils/Utils";
import Divider from "@material-ui/core/Divider";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import BeatLoader from "react-spinners/ClipLoader";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../../firebase";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function Transactions() {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState(userRows);
    const [transactionData, setTransactionData] = useState([]);
    const [selectTransaction, setSelectedTransaction] = React.useState({});
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(true);
    const _user = useSelector((state) => state.userInfo.value);
    const color = "#00ff0e";
    let _transactionData = [];
    const _userInfo = useSelector((state) => state.userInfo.value);
    const _transactions = useSelector((state) => state.transactionInfo.value);
    const botId = _userInfo && _userInfo._userInfo && _userInfo._userInfo != 0 ? _userInfo._userInfo[0].Signal_Owner_Id : "";
    _transactionData = _transactions && _transactions._transList && _transactions._transList.filter(value => botId.toUpperCase() === value.userId.trim().toUpperCase()) //make sure only signed in users transactions are retrieved
    //setTransactions(transactionData);

    useEffect(async () => {
        if(_transactionData == null || _transactionData == undefined){
            let _transList = [];
            const tradeHistoryCollectionRef = collection(db, "Trade_history_report");
            
            //const q = query(tradeHistoryCollectionRef, where("userId", "==", _user && _user._userInfo[0] ? " " + _user._userInfo[0].User_Id.toString() : 0), where("orderCloseTime", ">=", new Date(new Date().getFullYear(), 0, 1)), orderBy("orderCloseTime", "desc"));
            const q = query(
                tradeHistoryCollectionRef,
                where("userId", "==", _user && _user._userInfo[0] ? _user._userInfo[0].User_Id.toString() : 0),
                where("orderCloseTime", ">=", new Date(new Date().getFullYear(), 0, 1)),
                orderBy("orderCloseTime", "desc")
              );
            const data = await getDocs(q);
            let counter = 1;
            data.docs.map((doc) => {
                _transList.push(({...doc.data(), id: counter++}))
            })
            setTransactionData(_transList)
        }

    }, []);

    const handleInsideRoleClick = (paramValue) => {
        handleOpen();
        setSelectedTransaction(paramValue)
    }

    const columns = [
        {field: "id", headerName: "ID", width: 100},
        {field: "orderSymbol", headerName: "Symbol", width: 130},
        {field: "orderComment", headerName: "Operator", width: 200},
        {
            field: "orderProfit", headerName: "Profit", width: 200,
            renderCell: (params) => {
                return (
                    <div className="userListUser"
                         style={{color: Math.sign(parseFloat(params.value)) === -1 ? "red" : "green"}}>
                        {parseFloat(params.value)}
                    </div>
                );
            },

        },
        {
            field: "", headerName: "PnL", width: 120,
            renderCell: (params) => {
                return (
                    <div className="userListUser" style={{color: Math.sign(parseFloat(params.row.orderProfit)) === -1 ? "red" : "green"}}>
                        {Math.sign(parseFloat(params.row.orderProfit)) === -1 ? "LOSS" : "WIN"}
                    </div>
                );
            },

        },
        {
            field: "orderCloseTime", headerName: "Order Close Time", width: 200,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {Moment(params.value.toDate()).format('YYYY-MM-DD HH:mm')}
                    </div>
                );
            },

        },
        {field: "orderSize", headerName: "Lot", width: 110},
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={"/user/" + params.row.id}>
                            <button onClick={() => handleInsideRoleClick(params.row)} className="userListEdit">View
                            </button>
                        </Link>
                        <ViewDay style={{cursor: "not-allowed"}}
                                 className="userListDelete"
                        />
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div style={{display: "none"}}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography style={{textAlign: "center", fontStyle: "bold", fontFamily: "sans-serif"}}
                                    id="modal-modal-title" variant="h6"
                                    component="h2">
                            Trade Details
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            <List
                                sx={{
                                    width: '100%',
                                    maxWidth: 360,
                                    bgcolor: 'background.paper',
                                }}
                            >
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ImageIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Dates"
                                                  secondary={`
                                                  OrderOpen @: ${selectTransaction && !isEmpty(selectTransaction) && Moment(selectTransaction.orderOpenTime.toDate()).format('YYYY-MM-DD HH:mm')}
                                                  OrderClose @: ${selectTransaction && !isEmpty(selectTransaction) && Moment(selectTransaction.orderCloseTime.toDate()).format('YYYY-MM-DD HH:mm')}
                                                  `}/>
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Currency Type" secondary={`
                                                  OrderSymbol @: ${selectTransaction && !isEmpty(selectTransaction) && selectTransaction.orderSymbol.trim()}\n \b\r\ \b\r
                                                  \n \b\r\ \b\r \n \b\r\ \b\r \        \  
                                                  \n \b\r\ \b\r 
                                                   \n \b\r\ \b\r 
                                                    \n \b\r\ \b\r 
                                                     \n \b\r\ \b\r 
                                                  OrderType @: ${selectTransaction && !isEmpty(selectTransaction) && selectTransaction.orderType.trim()}
                                                  `}/>
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BeachAccessIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Position" secondary={`
                                                  OrderOpenPrice @: ${selectTransaction && !isEmpty(selectTransaction) && selectTransaction.orderOpenPrice} 
                                                  OrderStopLoss @: ${selectTransaction && !isEmpty(selectTransaction) && selectTransaction.orderStopLoss} 
                                                  OrderTakeProfit @: ${selectTransaction && !isEmpty(selectTransaction) && selectTransaction.orderTakeProfit}
                                                  `}/>
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Size Profit" secondary={`
                                                  OrderProfit @: ${selectTransaction && !isEmpty(selectTransaction) && selectTransaction.orderProfit} \n \b\r\ \b\r
                                                  \n \b\r\ \b\r \n \b\r\ \b\r \        \  
                                                  \n \b\r\ \b\r 
                                                   \n \b\r\ \b\r 
                                                    \n \b\r\ \b\r 
                                                     \n \b\r\ \b\r 
                                                   \n \b\r\ \b\r  OrderSize @: ${selectTransaction && !isEmpty(selectTransaction) && selectTransaction.orderSize}
                                                  `}/>
                                </ListItem>
                            </List>
                        </Typography>
                    </Box>
                </Modal>
            </div>

            {
                transactionData && !listEmpty(transactionData) && loading ? setLoading(false) : ""
            }
            {
                loading ?
                    <div className="sweet-loading transactions">
                        <BeatLoader
                            color={color}
                            loading={loading}
                            size={60}/>
                    </div>
                    :
                    <div className="userList">
                    < Toolbar > Your Trade Journal</Toolbar>
                        <DataGrid autoHeight={true}
                        rows={transactionData ? transactionData : []}
                        disableSelectionOnClick
                        columns={columns}
                        pageSize={8}
                        checkboxSelection
                        rowsPerPageOptions={[8]}
                        style={{fontFamily: "math", fontSize: "12px"}}
                        components={{
                                Toolbar: GridToolbar,
                            }}
                        />
                    </div>
            }
        </>

    );
}
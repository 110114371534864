import React from "react"
import {useAuth} from "../../contexts/AuthContext"
import {Redirect, Route} from "react-router-dom"


export default function PrivateRoute({component: Component, ...rest}) {
    const {currentUser} = useAuth();
    return (
        <>
            <Route
                {...rest}
                render={props => {
                    return currentUser ? <Component {...props} /> : props.location.pathname == "/welcome" ?
                        <Redirect to="/welcome"/> : props.location.pathname == "/termsCondition" ?
                            <Redirect to="/termsCondition"/> : props.location.pathname == "/signup" ?
                                <Redirect to="/signup"/> : props.location.pathname == "/productCheckout" ?
                                    <Redirect to="/productCheckout"/> : props.location.pathname == "/forgot-password" ?
                                        <Redirect to="/forgot-password"/> :
                                        props.location.pathname == "/reset-password" ? <Redirect to={props.location.pathname+ props.location.search}></Redirect>
                                            : props.location.pathname == "/login" ?
                                            <Redirect to="/login"/> : <Redirect to="/welcome"/>
                }}
            />
        </>
    )
}


import {createSlice} from "@reduxjs/toolkit"
const initialState = {};
export const userInfoSlice = createSlice({
    name: "userInformation",
    initialState: initialState,
    reducers: {
        userInfo: (state, action) => {
            state.value = action.payload; ;
        }
    }
});

export const {userInfo} = userInfoSlice.actions;

export default userInfoSlice.reducer;




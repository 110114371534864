import "./operatormonthlyprofiabilitychart.css";
import React from "react";
import {generateRandomColor} from "../../components/utils/Utils";

let parent = [
    {
        name: 'Apples',
        value: 40,
        color: '#eb4d4b'
    },
    {
        name: 'Blueberries',
        value: 7,
        color: '#22a6b3'
    },
    {
        name: 'Guavas',
        value: 23,
        color: '#6ab04c'
    },
    {
        name: 'Grapes',
        value: 10,
        color: '#e056fd'
    },
    {
        name: 'other',
        value: 20,
        color: '#e056fd'
    }
]

const checkObjectForCurrencyValidation = (listOfObjects) => {

    listOfObjects.foreach((obj) => {

    })
}


export default function MultiColorProgressBar({transactions: transactions}) {
    let retval = [];
    let indexTracker = {};
    let nextIndex = 0;
    transactions && transactions.map((transaction) => {
        const _symbol = transaction.orderSymbol.toUpperCase().trim();
        if (indexTracker[_symbol] != undefined) {
            //get the location of the index
            let current_index = indexTracker[_symbol];
            //update the current index object
            retval[current_index] =
                {
                    "name": _symbol,
                    "value": (retval[current_index]["value"] + 1),
                    "color": retval[current_index]["color"]
                }
        } else if (!indexTracker[_symbol]) {
            retval[nextIndex] =
                {
                    "name": _symbol,
                    "value": 1,
                    "color": generateRandomColor()
                }
            indexTracker = {
                ...indexTracker,
                [_symbol]: nextIndex
            }
            nextIndex++;

        }

    })


    let values = retval && retval.length && retval.map(function (item, i) {
        if (item.value > 0) {
            return (
                <div className="value" style={{'color': item.color, 'width': item.value + '%'}} key={i}>
                    <span>{item.value}%</span>
                </div>
            )
        }
    }, this);

    let calibrations = retval && retval.length && retval.map(function (item, i) {
        if (item.value > 0) {
            return (
                <div className="graduation" style={{'color': item.color, 'width': item.value + '%'}} key={i}>
                    <span>|</span>
                </div>
            )
        }
    }, this);

    let bars = retval && retval.length && retval.map(function (item, i) {
        if (item.value > 0) {
            return (
                <div className="bar" style={{'backgroundColor': item.color, 'width': item.value + '%'}} key={i}>

                </div>
            )
        }
    }, this);

    let legends = retval && retval.length && retval.map(function (item, i) {
        if (item.value > 0) {
            return (
                <div className="legend" key={i}>
                    <span className="dot" style={{'color': item.color}}>●</span>
                    <span className="label">{item.name}</span>
                </div>
            )
        }
    }, this);

    return (
         <div className="multicolor-bar operator_monthly_containned" width={"80%"}   height={"30%"}>
             <div className="values" style={{fontSize: "12px"}}>
                 {values == '' ? '' : values}
             </div>
             <div className="scale">
                 {calibrations == '' ? '' : calibrations}
             </div>
             <div className="bars">
                 {bars == '' ? '' : bars}
             </div>
             <div className="legends">
                 {legends == '' ? '' : legends}
             </div>
         </div>
       /* <>
            <div className="multicolor-bar operator_monthly_containned" width={"80%"} height={"30%"}>
                <ProgressBar>
                    {
                        retval && retval.map((obj)=>{
                            console.log(obj)
                            return ( <ProgressBar  color="red" now={obj.value} animated striped  label={`${obj.name}`}/>)
                        })
                    }
                </ProgressBar>
            </div>
        </>*/
    );
}


import React, { useEffect, useRef, useState } from "react"
import { Alert, Button, Card, Form } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { auth, db } from "../../firebase"
import SaveIcon from '@mui/icons-material/Save';
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, doc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore"
import { useDispatch } from "react-redux";
import { addDoc } from "@firebase/firestore";
import { userInfo } from "../../reducers/UserInformation-reducer";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { AccountCircle } from "@mui/icons-material";
import { createAPIKeyFromUuid } from "../../components/security/Security";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import StepButton from "@material-ui/core/StepButton";
import Step from "@material-ui/core/Step";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from 'react-swipeable-views';
import Zoom from "@material-ui/core/Zoom";
import TabPanel from "@material-ui/lab/TabPanel";
import green from "@material-ui/core/colors/green";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import TextField from "@material-ui/core/TextField";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { getObjectKeys, isEmpty, specialBinarySearchNumberOrderedListOperatorsList } from "../../components/utils/Utils";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Modal from "@material-ui/core/Modal";
import Toolbar from "@mui/material/Toolbar";
import CheckIcon from '@mui/icons-material/Cancel';
import Close from '@mui/icons-material/Cancel';
import ToggleButton from '@mui/material/ToggleButton';
import Stack from '@mui/material/Stack';
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BeatLoader from "react-spinners/ClipLoader";

let operatorsListNumberAndName = [];
const steps = ['Add operators you want to automated their trades', 'Select operators and remove them from automatically taking trades', 'Configure operators settings'];
const label = { inputProps: { 'aria-label': 'pending?' } };

export default function UpdateProfile() {
    //redux store, using slice
    const dispatch = useDispatch();
    const color = "#00ff0e";
    const emailRef = useRef();
    const passwordRef = useRef();
    var changePass = useRef()
    const userId = useRef();
    const addEntryRef = useRef();
    const addSLRef = useRef();
    const addSymbolRef = useRef();
    const addTypeRef = useRef();
    const addTp1Ref = useRef();
    const addTp2Ref = useRef();
    const addTp3Ref = useRef();
    const addTp4Ref = useRef();
    const defaultSymbol = useRef();
    const operatorsNoRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const tradeStrategyRegexRef = useRef();
    const tradeStrategyOffsetRef = useRef();
    const defaultTPLevelRef = useRef();
    const closeTextOffsetRef = useRef();
    const botId = useRef();
    const passwordConfirmRef = useRef()
    const { currentUser, updatePassword } = useAuth()
    const [sameTpsChecked, SetSameTpsChecked] = useState(false)
    const [enableTradeReplication, SetEnableTradeReplication] = useState(false)
    const [disableTradeSignal, SetDisableTradeSignal] = useState(false)
    const [tradeStrategy, SetTradeStrategy] = useState("")
    const [defaultTPCheck, SetDefaultTPCheck] = useState(false)
    const [defaultSLCheck, SetDefaultSLCheck] = useState(false)
    const [tradeStrategyCheckGrid, SetTradeStrategyCheckGrid] = useState(false)

    const [tradeStrategyCheckScalp, SetTradeStrategyCheckScalp] = useState(false)
    const [pendingOrdersChecked, SetPendingOrdersChecked] = useState(false)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [rollerloading, setRollerloading] = useState(true)
    const history = useHistory()
    const [users, setUsers] = useState([]);
    const [passChecked, setPassChecked] = useState(true);
    const [user] = useAuthState(auth);
    const usersCollectionRef = collection(db, "User_Information");
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [showModal, setShowModal] = React.useState(false);
    const [configOpsButton, setConfigOpsButton] = React.useState("");
    const [operatorConfigError, setOperatorConfigError] = React.useState("");
    const [onClickedConfigure, setOnClickedConfigure] = React.useState(false);
    const [displayNotification, setDisplayNotification] = React.useState(false);

    const [open, setOpen] = React.useState(true);
    const [idOpen, setIdOpen] = React.useState();

    const handleClick = (id) => {
        setIdOpen(id)
        setOpen(!open);
        setCurrentCheckedOpsId(id);
    };


    const handleStep = (step) => () => {
        setActiveStep(step);
    };


    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [currentCheckedName, setCurrentCheckedName] = React.useState(0);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const [errorMessage, setErrorMessage] = useState();
    const [checkedOperatorConf, setCheckedOperatorConf] = React.useState();
    const [errorConfigurationAdd, setErrorConfigurationAdd] = React.useState();
    const [currentCheckedOpsId, setCurrentCheckedOpsId] = React.useState();
    const [innerConfig, setInnerConfig] = React.useState();
    const [defaultModalValues, setDefaultModalValues] = useState();
    const [operatorsInMemNameList, setOperatorsInMemNameList] = useState([]);

    const addNot = () => {
        Store.addNotification({
            title: "Congratulations!",
            message: "Please Complete your Registration By filling in \n your Bot user ID and Telegram ID",
            type: "success",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 120000,
                onScreen: true
            }
        });
        setDisplayNotification(true)
    }

    const addPasswordChangeNotification = () => {
        Store.addNotification({
            title: "Successful!",
            message: "Password changed Successfully",
            type: "success",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 30000,
                onScreen: true
            }
        });
        setDisplayNotification(true)
    }

    const onClickFab = async (index) => {
        let containedOperator = false;
        if (index == 0) {
            if (!operatorsNoRef || !operatorsNoRef.current || operatorsNoRef.current.value === "") {
                setErrorMessage("operator field must be filled..");
                console.error("operator field must be filled..");
                return;
            }
            /* _operatorsMem && _operatorsMem._operatorsList.forEach((value) => {
                 value.ids.forEach((_value)=>{
                     opsListMem.push(_value)
                 })
             })*/
            users && !isEmpty(users[0].operators) && users[0].operators.forEach((_value) => {
                if (_value.toString() == operatorsNoRef.current.value) {
                    containedOperator = true;
                    setErrorMessage("operator already exist on your list");
                    console.error("operator already exist on your list");
                    return;
                }

            })
            if (containedOperator) {
                setErrorMessage("operator already exist on your list");
                console.error("operator already exist on your list");
                return;
            } else if (!containedOperator && users) {
                const _tmpOpsValue = Number(operatorsNoRef.current.value);
                //update users operators list
                const userUpdateRef = doc(db, "User_Information", users[0].id)
                await updateDoc(userUpdateRef, {
                    ...users[0],
                    operators: [
                        ...users[0].operators,
                        Number(operatorsNoRef.current.value),
                    ],
                    operator_config: {
                        ...users[0].operator_config,
                        [Number(operatorsNoRef.current.value)]: {}
                    }


                }).then(() => {
                    users[0].operators.push(Number(operatorsNoRef.current.value))
                    users[0].operator_config = {
                        ...users[0].operator_config,
                        [Number(operatorsNoRef.current.value)]: {}
                    }
                    const indexValue = specialBinarySearchNumberOrderedListOperatorsList(Number(operatorsNoRef.current.value), operatorsListNumberAndName);
                    setRight([...right, indexValue != undefined && indexValue != -1 ? operatorsListNumberAndName[indexValue] : Number(operatorsNoRef.current.value) + " | noName"])
                    //generateList();
                    setErrorMessage("Successfully  added")
                    //update the api inmem DB
                    //console.log("got heree...", `${process.env.REACT_APP_BACKEND_URL + "/updateAutoforwarderInMemDB/" + users[0].User_Id + "/" + users[0].botId + "/" + _tmpOpsValue}`)

                    const doAxios = async () => {

                        await axios({
                            method: "POST",
                            url: `${process.env.REACT_APP_BACKEND_URL + "/resetLokiUsers/" + users[0].User_Id}`,
                            headers: {
                                "x-api-key": `${users[0].apiKey}`
                            }
                        })

                        //update loki user in mem API

                        await axios({
                            method: "POST",
                            url: `${process.env.REACT_APP_BACKEND_URL + "/setLokiOperators/" + users[0].User_Id + "/noName||" + _tmpOpsValue}`,
                            headers: {
                                "x-api-key": `${users[0].apiKey}`
                            }
                        })

                        await axios({
                            method: "POST",
                            url: `${process.env.REACT_APP_BACKEND_URL + "/updateAutoforwarderInMemDB/" + users[0].User_Id + "/" + users[0].botId + "/" + _tmpOpsValue}`,
                            headers: {
                                "x-api-key": `${users[0].apiKey}`
                            }
                        })
                    }
                    doAxios();
                    operatorsNoRef.current = "";
                }).catch((e) => console.error(e));

            }
        }


        if (index == 1 && left) {
            if (left.length <= 0) {
                return;
                console.error("You have to select from the right..", left.length)
            }

            let removeOps = "";

            left.forEach((_currentOperator) => {
                _currentOperator = Number(_currentOperator.split("|")[0].trim());
                removeOps = _currentOperator;
                const index = users[0].operators.indexOf(_currentOperator);
                if (index > -1) {
                    users[0].operators.splice(index, 1); // 2nd parameter means remove one item only
                    delete users[0].operator_config[_currentOperator]; // 2nd parameter means remove one item only
                }
            })

            const userUpdateRef = doc(db, "User_Information", users[0].id)
            await updateDoc(userUpdateRef, {
                ...users[0],
                operators: [
                    ...users[0].operators,
                ],
                operator_config: {
                    ...users[0].operator_config,
                }
            }).then(() => {
                setLeft([])
                setErrorMessage("Successfully  added")

                const doAxios = async () => {

                    await axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_BACKEND_URL + "/resetLokiUsers/" + users[0].User_Id}`,
                        headers: {
                            "x-api-key": `${users[0].apiKey}`
                        }
                    })

                    //update loki user in mem API

                    await axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_BACKEND_URL + "/setLokiOperators/" + users[0].User_Id + "/noName||" + removeOps}`,
                        headers: {
                            "x-api-key": `${users[0].apiKey}`
                        }
                    })

                    await axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_BACKEND_URL + "/updateAutoforwarderInMemDB/" + users[0].User_Id + "/" + users[0].botId + "/" + removeOps}`,
                        headers: {
                            "x-api-key": `${users[0].apiKey}`
                        }
                    })
                }
                if (users[0])
                    doAxios();

            }).catch((e) => {
                console.error(e);
            })
        }


        return;
    }


    const generateList = () => {
        let retval = [];
        const userOperatorsIds = users && users[0] ? getObjectKeys(users[0].operator_config) : [];
        const userOperatorsConfigMap = userOperatorsIds && users && users[0] ? users[0].operator_config : [];

        users && users[0] && userOperatorsIds && userOperatorsIds.map((e) => {
            const indexNumber = specialBinarySearchNumberOrderedListOperatorsList(e, operatorsListNumberAndName);
            e = indexNumber != -1 ? operatorsListNumberAndName[indexNumber].slice(0, 55) : e + " | noName";
            e != undefined && retval.push(
                <div>
                    <List id={(e.split("|")[0].trim())}
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', margin: "auto" }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >

                        <ListItemButton onClick={() => handleClick((e.split("|")[0].trim()))}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={e} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    '& > *': {
                                        m: 1,
                                    },
                                }}
                            >
                                <ButtonGroup
                                    orientation=""
                                    varient="outlined"
                                    aria-label="vertical outlined button group"
                                >
                                    <Button id="add"
                                        onClick={() => showModalWithProps("add", (e.split("|")[0].trim()), userOperatorsIds)}
                                        key="add">Add</Button>,
                                    <Button
                                        disabled={e && userOperatorsConfigMap && userOperatorsConfigMap[(e.split("|")[0].trim())] && getObjectKeys(userOperatorsConfigMap[(e.split("|")[0].trim())]).length <= 0}
                                        id="edit" key="edit"
                                        onClick={() => showModalWithProps("edit", (e.split("|")[0].trim()), userOperatorsIds)}>Edit</Button>,
                                    <Button id="delete" key="delete"
                                        onClick={() => showModalWithProps("delete", (e.split("|")[0].trim()), userOperatorsIds)}>Delete</Button>
                                </ButtonGroup>
                            </Box>
                            {open && idOpen == (e.split("|")[0].trim()) ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse id={(e.split("|")[0].trim())}
                            in={open && idOpen == (e.split("|")[0].trim())} timeout="auto" unmountOnExit>
                            {
                                userOperatorsConfigMap && userOperatorsConfigMap[(e.split("|")[0].trim())] && getObjectKeys(userOperatorsConfigMap[(e.split("|")[0].trim())]).map((_config) =>
                                    <List id={(e.split("|")[0].trim())} component="div"
                                        disablePadding>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checkedOperatorConf === (e.split("|")[0].trim()) + _config}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': (e.split("|")[0].trim()) }}
                                                    onClick={() => onClickCheckedOperatorsInner((e.split("|")[0].trim()), _config)}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={_config} />
                                        </ListItemButton>
                                    </List>)
                            }
                        </Collapse>


                    </List>

                </div>
            )
        })

        return retval;
    }


    const onClickCheckedOperatorsInner = (id, opsId) => {
        setCheckedOperatorConf("");
        setCheckedOperatorConf(id + opsId);
        setCurrentCheckedName(opsId);
        setCurrentCheckedOpsId(id);
    }

    useEffect(() => {
        const getUsers = async () => {
            const q = query(usersCollectionRef, where("email", "==", user.email));
            const data = await getDocs(q);
            const _tempUsers = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setUsers(_tempUsers);
            //setRight(_tempUsers[0].operators);
            const retval = () => {
                let _retval = [];
                operatorsListNumberAndName && _tempUsers && _tempUsers[0] && _tempUsers[0].operators.map((_opsId) => {
                    const indexValue = specialBinarySearchNumberOrderedListOperatorsList(_opsId, operatorsListNumberAndName);
                    _retval.push(indexValue != undefined && indexValue != -1 ? operatorsListNumberAndName[indexValue] : _opsId + " | noName")
                })

                return _retval
            }
            setRight(retval);
        };
        const operatorsMemInformationCollectionRef = collection(db, "OperatorsInfo_Mem");
        const setOperatorsMemList = async () => {
            const q = query(operatorsMemInformationCollectionRef, orderBy("operatorsId", "desc"));
            await getDocs(q).then((data) => {
                data.docs.map((doc, key) => {
                    operatorsListNumberAndName.push(doc.data().operatorsId + " | " + doc.data().name.split(0, 25))
                });
            })

        }
        setOperatorsMemList();
        getUsers().then(() => {
            setRollerloading(false)
            if (isEmpty(users))
                addNot()
        });
        //setOperatorsInMemNameList(operatorsListNumberAndName)

    }, []);


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`action-tabpanel-${index}`}
                aria-labelledby={`action-tab-${index}`}
                className={"dna_typo_style"}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `action-tab-${index}`,
            'aria-controls': `action-tabpanel-${index}`,
        };
    }

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChangeMultiple = (event, newValue) => {
        SetSameTpsChecked(!sameTpsChecked);
    }

    const handleDisableTrade = (event, newValue) => {
        SetDisableTradeSignal(event.target.checked);
    }

    const handleTradeReplication = (event, newValue) => {
        SetEnableTradeReplication(!enableTradeReplication);
    }
    const handleDefaultTP = (event, newValue) => {
        SetDefaultTPCheck(event.target.checked)
    }

    const handleDefaultSL = (event, newValue) => {
        SetDefaultSLCheck(event.target.checked)
    }
    const handleTradeStrategy = (event, newValue) => {
        const { value } = event.target;
        if (value !== '') {
            SetTradeStrategy(event.target.name)
            if (event.target.name == "grid") {
                SetTradeStrategyCheckGrid(event.target.checked);
                SetTradeStrategyCheckScalp(false);
            }

            if (event.target.name == "scalp") {
                SetTradeStrategyCheckScalp(event.target.checked);
                SetTradeStrategyCheckGrid(false);
            }

        } else {
            SetTradeStrategy("none")
        }
    }

    const handlePendingOrderChecked = (event, newValue) => {
        SetPendingOrdersChecked(!pendingOrdersChecked);
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };


    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
    };

    const fabGreenStyle = {
        color: 'common.white',
        bgcolor: green[500],
        '&:hover': {
            bgcolor: green[600],
        },
    };

    function handleSubmit(e) {
        e.preventDefault()
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match")
        }

        //Updating the state
        //dispatch(authUser({name: "Pedro", age: 20, email: "cockybastard@gmail.com"}))
        const promises = []
        setLoading(true)
        setError("")
        if (emailRef.current.value !== currentUser.email) {
            //promises.push(ail(emailRef.current.value))
        }
        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value))
        }

        Promise.all(promises)
            .then(async () => {
                //Add to database or update
                let data = {
                    First_Name: firstNameRef ? firstNameRef.current.value : "",
                    Last_Name: lastNameRef ? lastNameRef.current.value : "",
                    Signal_Owner_Id: userId.current.value,
                    User_Id: userId.current.value,
                    botId: botId.current.value,
                    Signal_Replication: true,
                    Subscription_ID: "",
                    email: currentUser.email,
                    operator_config: {},
                    operators: [],
                    role: "user"
                    //apiKey: await createAPIKeyFromUuid(emailRef.current.value)

                };

                const doAxios = async (apiKey, userId) => {
                    debugger;
                    await axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_BACKEND_URL + "/resetLokiUsers/" + userId}`,
                        headers: {
                            "x-api-key": `${apiKey}`
                        }
                    })
                }

                if (users.length == 0) {
                    const _keyApi = await createAPIKeyFromUuid(emailRef.current.value);
                    await addDoc(collection(db, "User_Information"), {
                        ...data,
                        apiKey: _keyApi
                    });
                    dispatch(userInfo({ _userInfo: [] }));
                    dispatch(userInfo({ _userInfo: [data] }));
                    setUsers({ _userInfo: data })
                    doAxios(_keyApi, data.User_Id);
                } else if (users) {
                    //update and move one
                    const userUpdateRef = doc(db, "User_Information", users[0].id)
                    await updateDoc(userUpdateRef, {
                        ...users[0],
                        First_Name: data.First_Name,
                        Last_Name: data.Last_Name,
                        Signal_Owner_Id: data.Signal_Owner_Id,
                        User_Id: data.User_Id,
                        botId: data.botId,
                        Signal_Replication: data.Signal_Replication,
                    }).then(() => addPasswordChangeNotification());
                    doAxios(users[0].apiKey, data.User_Id);
                }
                history.push("/")

            })
            .catch((e) => {
                setError("Failed to update account")
                console.error(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const customList = (items) => (
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const showModalWithProps = (buttonName, id, opsIds, userOperatorsIds) => {
        const doAxios = async () => {

            await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BACKEND_URL + "/resetLokiUsers/" + users[0].User_Id}`,
                headers: {
                    "x-api-key": `${users[0].apiKey}`
                }
            })

            //update loki user in mem API

            await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BACKEND_URL + "/updateAutoforwarderInMemDB/" + users[0].User_Id + "/" + users[0].botId + "/" + id}`,
                headers: {
                    "x-api-key": `${users[0].apiKey}`
                }
            })


        }

        if (buttonName == "add") {
            setInnerConfig(users[0].operator_config[id]);
            setConfigOpsButton(buttonName);
            setShowModal(!showModal)
            setCurrentCheckedOpsId(id);
        }

        if (buttonName == "edit") {
            if (!checkedOperatorConf) {
                setOperatorConfigError("You have to select a configuration before you can edit")
                return
            } else if (checkedOperatorConf && users && users[0]) {
                setOperatorConfigError("")
                setInnerConfig(users[0].operator_config[id]);
                setConfigOpsButton(buttonName);
                setShowModal(!showModal)
                setCurrentCheckedOpsId(id);
                let _data = {
                    entry: users[0]?.operator_config?.[id]?.[currentCheckedName]?.entry || '',
                    symbol: users[0]?.operator_config?.[id]?.[currentCheckedName]?.symbol || '',
                    sl: users[0]?.operator_config?.[id]?.[currentCheckedName]?.stop_loss || '',
                    type: users[0]?.operator_config?.[id]?.[currentCheckedName]?.type || '',
                    tp1: users[0].operator_config[id][currentCheckedName].take_profit1,
                    tp2: users[0].operator_config[id][currentCheckedName].take_profit2,
                    tp3: users[0].operator_config[id][currentCheckedName].take_profit3,
                    tp4: users[0].operator_config[id][currentCheckedName].take_profit4,
                    closeText: users[0].operator_config[id][currentCheckedName].closeText,
                    sameMultipleTps: users[0].operator_config[id][currentCheckedName].sameMultipleTps,
                    enableTradeReplication: users[0]?.operator_config?.[id]?.[currentCheckedName]?.enableTradeReplication || false,
                    defaultSL: users[0].operator_config[id][currentCheckedName].defaultSL,
                    tradeStrategy: users[0].operator_config[id][currentCheckedName].tradeStrategy,
                    tradeStrategyOffset: users[0].operator_config[id][currentCheckedName].tradeStrategyOffset,
                    tradeStrategyRegex: users[0].operator_config[id][currentCheckedName].tradeStrategyRegex,
                    pendingOrders: users[0].operator_config[id][currentCheckedName].pendingOrders ? users[0].operator_config[id][currentCheckedName].pendingOrders : false,
                    traderDefaultSymbol: users[0].operator_config[id][currentCheckedName].traderDefaultSymbol,
                    defaultTP: users[0]?.operator_config?.[id]?.[currentCheckedName]?.defaultTP || false,
                    defaultSL: users[0]?.operator_config?.[id]?.[currentCheckedName]?.defaultSL || false,
                    defaultTPLevel: users[0].operator_config[id][currentCheckedName].defaultTPLevel,
                    disableTradeSignal: users[0]?.operator_config?.[id]?.[currentCheckedName]?.disableTradeSignal || false
                };
                SetSameTpsChecked(_data.sameMultipleTps)
                SetEnableTradeReplication(_data.enableTradeReplication)
                SetDisableTradeSignal(_data.disableTradeSignal)
                SetTradeStrategy(_data.tradeStrategy)
                SetDefaultTPCheck(_data.defaultTP)
                SetDefaultSLCheck(_data.defaultSL)
                if (_data.tradeStrategy && _data.tradeStrategy == "grid") {
                    SetTradeStrategyCheckGrid(true)
                } else if (_data.tradeStrategy && _data.tradeStrategy == "scalp") {
                    SetTradeStrategyCheckScalp(true)
                } else {
                    SetTradeStrategyCheckScalp(false)
                    SetTradeStrategyCheckGrid(false)
                }

                SetPendingOrdersChecked(_data.pendingOrders)
                setDefaultModalValues(_data);
            }


        }

        if (buttonName == "delete") {
            if (!checkedOperatorConf) {
                setOperatorConfigError("You have to select a configuration before you can delete")
                return
            } else {

                delete users[0].operator_config[currentCheckedOpsId][currentCheckedName];
                //save to the databaseƒƒ

                const doDBOps = async () => {
                    const _userUpdateRef = doc(db, "User_Information", users[0].id)
                    await updateDoc(_userUpdateRef, {
                        ...users[0],
                        operator_config: {
                            ...users[0].operator_config,
                        }
                    }).then(() => {
                    }).catch((e) => console.error(e));
                }
                setOperatorConfigError("Operation completed successfully");
                doDBOps();
                setErrorConfigurationAdd("Operation completed successfully");
                setOperatorConfigError("Operation completed successfully");
                setTimeout(30000, closeOperatorConfig())
                doAxios();
            }
        }


        return
    }

    const fabs = [
        {
            color: 'primary',
            sx: fabStyle,
            icon: <AddIcon />,
            label: 'Add',
        },
        {
            color: 'secondary',
            sx: fabStyle,
            icon: <EditIcon />,
            label: 'Edit',
        },
        {
            color: 'inherit',
            sx: { ...fabStyle, ...fabGreenStyle },
            icon: <UpIcon />,
            label: 'Expand',
        },
    ];


    const validateModalFields = () => {
        if (!addEntryRef || !addEntryRef.current || addEntryRef.current.value === "") {
            setErrorConfigurationAdd("Entry value is important..")
            return;
        }
    }

    const closeOperatorConfig = () => {
        setErrorConfigurationAdd("");
        setShowModal(false);
        setErrorConfigurationAdd("");
    }

    const handleModalSave = () => {


        const doAxios = async (operatorId) => {

            await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BACKEND_URL + "/resetLokiUsers/" + users[0].User_Id}`,
                headers: {
                    "x-api-key": `${users[0].apiKey}`
                }
            })

            //update loki user in mem API

            await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BACKEND_URL + "/updateAutoforwarderInMemDB/" + users[0].User_Id + "/" + users[0].botId + "/" + operatorId}`,
                headers: {
                    "x-api-key": `${users[0].apiKey}`
                }
            })
        }

        if (configOpsButton == "add") {
            if (!addEntryRef || !addEntryRef.current || addEntryRef.current.value === "") {
                setErrorConfigurationAdd("Entry value is important..")
                return;
            }
            if (!addSymbolRef || !addSymbolRef.current || addSymbolRef.current.value === "") {
                setErrorConfigurationAdd("Symbol value is important..")
                return;
            }
            if (!addTypeRef || !addTypeRef.current || addTypeRef.current.value === "") {
                setErrorConfigurationAdd("Type value is important..")
                return;
            }
            if (!addSLRef || !addSLRef.current || addSLRef.current.value === "") {
                setErrorConfigurationAdd("StopLoss value is important..")
                return;
            }
            if (!addTp1Ref || !addTp1Ref.current || addTp1Ref.current.value === "") {
                setErrorConfigurationAdd("TP1 value is important..")
                return;
            }
            if (sameTpsChecked && !isNaN(parseFloat(addTp1Ref.current.value))) {
                setErrorConfigurationAdd("when same multiples TPs checked, Take profit 1 must be string (words not number e.g TP)")
                return;
            }

            //save to the database
            const _data =
            {
                entry: addEntryRef.current.value,
                stop_loss: addSLRef.current.value,
                type: addTypeRef.current.value,
                symbol: addSymbolRef.current.value,
                take_profit1: addTp1Ref.current.value,
                take_profit2: addTp2Ref.current.value,
                take_profit3: addTp3Ref.current.value,
                take_profit4: addTp4Ref.current.value,
                closeText: closeTextOffsetRef.current.value,
                sameMultipleTps: sameTpsChecked,
                enableTradeReplication: enableTradeReplication,
                disableTradeSignal: disableTradeSignal,
                tradeStrategy: tradeStrategy ? tradeStrategy : "default",
                tradeStrategyOffset: tradeStrategyOffsetRef.current.value,
                tradeStrategyRegex: tradeStrategyRegexRef.current.value,
                defaultTP: defaultTPCheck,
                defaultSL: defaultSLCheck,
                defaultTPLevel: defaultTPLevelRef.current.value,
                pendingOrders: pendingOrdersChecked,
                traderDefaultSymbol: defaultSymbol.current.value

            }

            const getNextConfigValue = (innerConfig) => {
                let _retVal = 0;
                if (!isEmpty(innerConfig)) {
                    const length = getObjectKeys(innerConfig).length
                    return Number(getObjectKeys(innerConfig).sort()[length - 1].split("config")[1])
                }

                return _retVal
            }

            const doDBOps = async () => {
                const _userUpdateRef = doc(db, "User_Information", users[0].id)
                getNextConfigValue(innerConfig);
                await updateDoc(_userUpdateRef, {
                    ...users[0],
                    operator_config: {
                        ...users[0].operator_config,
                        [currentCheckedOpsId]: {
                            ...users[0].operator_config[currentCheckedOpsId],
                            ["config" + Number(getNextConfigValue(innerConfig) + 1)]: _data
                        }
                    }
                }).then(() => {
                    users[0].operator_config[currentCheckedOpsId] =
                    {
                        ...users[0].operator_config[currentCheckedOpsId],
                        ["config" + Number(getNextConfigValue(innerConfig) + 1)]: _data
                    }
                    doAxios(currentCheckedOpsId)
                }).catch((e) => console.error(e));
            }
            doDBOps();
            setErrorConfigurationAdd("Operation completed successfully");
            setOperatorConfigError("Operation completed successfully");
            setTimeout(30000, closeOperatorConfig())
        }


        if (configOpsButton == "edit") {
            //"currentCheckedOpsId", currentCheckedOpsId,
            if (!addEntryRef || !addEntryRef.current || addEntryRef.current.value === "") {
                setErrorConfigurationAdd("Entry value is important..")
                return;
            }
            if (!addSymbolRef || !addSymbolRef.current || addSymbolRef.current.value === "") {
                setErrorConfigurationAdd("Symbol value is important..")
                return;
            }
            if (!addTypeRef || !addTypeRef.current || addTypeRef.current.value === "") {
                setErrorConfigurationAdd("Type value is important..")
                return;
            }
            if (!addSLRef || !addSLRef.current || addSLRef.current.value === "") {
                setErrorConfigurationAdd("StopLoss value is important..")
                return;
            }
            if (!addTp1Ref || !addTp1Ref.current || addTp1Ref.current.value === "") {
                setErrorConfigurationAdd("TP1 value is important..")
                return;
            }
            if (sameTpsChecked && !isNaN(parseFloat(addTp1Ref.current.value))) {
                setErrorConfigurationAdd("when same multiples TPs checked, Take profit 1 must be string (words not number e.g TP)")
                return;
            }
            //save to the database
            const _data =
            {
                entry: addEntryRef.current.value,
                stop_loss: addSLRef.current.value,
                type: addTypeRef.current.value,
                symbol: addSymbolRef.current.value,
                take_profit1: addTp1Ref.current.value,
                take_profit2: addTp2Ref.current.value,
                take_profit3: addTp3Ref.current.value,
                take_profit4: addTp4Ref.current.value,
                closeText: closeTextOffsetRef.current.value,
                sameMultipleTps: sameTpsChecked,
                enableTradeReplication: enableTradeReplication,
                disableTradeSignal: disableTradeSignal,
                tradeStrategy: tradeStrategy ? tradeStrategy : "default",
                tradeStrategyOffset: tradeStrategyOffsetRef.current.value,
                tradeStrategyRegex: tradeStrategyRegexRef.current.value,
                defaultTP: defaultTPCheck,
                defaultSL: defaultSLCheck,
                defaultTPLevel: defaultTPLevelRef.current.value,
                pendingOrders: pendingOrdersChecked,
                traderDefaultSymbol: defaultSymbol.current.value,
            }
            const doDBOps = async () => {
                const _userUpdateRef = doc(db, "User_Information", users[0].id)
                await updateDoc(_userUpdateRef, {
                    ...users[0],
                    operator_config: {
                        ...users[0].operator_config,
                        [currentCheckedOpsId]: {
                            ...users[0].operator_config[currentCheckedOpsId],
                            [currentCheckedName]: _data
                        }
                    }
                }).then(() => {
                    users[0].operator_config = {
                        ...users[0].operator_config,
                        [currentCheckedOpsId]: {
                            ...users[0].operator_config[currentCheckedOpsId],
                            [currentCheckedName]: _data
                        }
                    }
                    doAxios(currentCheckedOpsId)
                }).catch((e) => console.error(e));
            }
            doDBOps();
            setErrorConfigurationAdd("Operation completed successfully");
            setOperatorConfigError("Operation completed successfully");
            setTimeout(30000, closeOperatorConfig())
        }


    }

    return (
        <>
            {
                displayNotification && isEmpty(users) || !users[0] || !users[0].User_Id || !users[0].botId ?
                    <div className="app-container">
                        <ReactNotifications />
                    </div>
                    : ""
            }
            {
                rollerloading ?
                    <div className="sweet-loading transactions">
                        <BeatLoader
                            color={color}
                            loading={rollerloading}
                            size={60} />
                    </div>
                    :
                    <>
                        <div className="profile-dashboard">
                            <div className="main-profile">
                                <Card>
                                    <Card.Body>
                                        <h1 className="text-center mb-4 ">Update Profile</h1>
                                        {error && <Alert variant="danger">{error}</Alert>}
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group>
                                                <InputLabel htmlFor="input-with-icon-adornment">
                                                    First Name
                                                </InputLabel>
                                                <Input
                                                    id="input-with-icon-adornment1"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <AccountCircle />
                                                        </InputAdornment>
                                                    }
                                                    inputRef={firstNameRef}

                                                />

                                            </Form.Group>

                                            <br />

                                            <Form.Group>
                                                <InputLabel htmlFor="input-with-icon-adornment"
                                                    className="profile-title-label">
                                                    Last Name
                                                </InputLabel>
                                                <Input
                                                    id="input-with-icon-adornment"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <AccountCircle />
                                                        </InputAdornment>
                                                    }
                                                    inputRef={lastNameRef}

                                                />

                                            </Form.Group>

                                            <br />

                                            <Form.Group id="email">
                                                <Form.Label className="profile-title-label">Bot User ID</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    ref={userId}
                                                    required
                                                    defaultValue={users.length != 0 && users[0] ? users[0].User_Id : ""}
                                                    disabled={users && users.length != 0}
                                                />
                                            </Form.Group>
                                            <br />
                                            <Form.Group id="email">
                                                <Form.Label className="profile-title-label">Telegram Bot
                                                    API</Form.Label>
                                                <Form.Control
                                                    type="string"
                                                    ref={botId}
                                                    required
                                                    defaultValue={users.length != 0 && users[0] ? users[0].botId : ""}
                                                    disabled={users && users.length != 0}
                                                />
                                            </Form.Group>
                                            <br />
                                            <Form.Group id="email">
                                                <Form.Label className="profile-title-label">API Key</Form.Label>
                                                <Form.Control
                                                    type="string"
                                                    defaultValue={users.length != 0 && users[0] ? users[0].apiKey : ""}
                                                    disabled
                                                />
                                            </Form.Group>
                                            <br />
                                            <Form.Group id="email">
                                                <Form.Label className="profile-title-label">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    ref={emailRef}
                                                    required
                                                    defaultValue={currentUser.email}
                                                    disabled={users && users.length != 0}
                                                />
                                            </Form.Group>
                                            <br />
                                            <Form.Group id="password-confirm">
                                                <Form.Check
                                                    type={'checkbox'}
                                                    id={`changepassword`}
                                                    ref={changePass}
                                                    onChange={(e) => {
                                                        setPassChecked(!passChecked);
                                                    }}
                                                    label={`Change password?`}
                                                />
                                            </Form.Group>
                                            <br />
                                            <Form.Group id="password" hidden={passChecked}>
                                                <Form.Label className="profile-title-label">Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    ref={passwordRef}
                                                    placeholder="Leave blank to keep the same"
                                                />
                                            </Form.Group>
                                            <br />
                                            <Form.Group id="password-confirm" hidden={passChecked}>
                                                <Form.Label className="profile-title-label">Password
                                                    Confirmation</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    ref={passwordConfirmRef}
                                                    placeholder="Leave blank to keep the same"
                                                />
                                            </Form.Group>


                                            <Button disabled={loading} className="w-100" type="submit">
                                                Update
                                            </Button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                                <div className="w-100 text-center mt-2">
                                    <Link to="/">Cancel</Link>
                                </div>
                            </div>
                            <br />
                            <Divider />
                            <br />
                            <div className={"operators_settings"} style={{
                                pointerEvents: !users || !users[0] || !users[0].User_Id || !users[0].botId ? "none" : "",
                                backgroundColor: !users || !user[0] || !users[0].User_Id || !users[0].botId ? "none" : "lightgrey",

                            }}>
                                <h2>Adding operators</h2>
                                <Box sx={{ width: '80%', margin: "auto" }}>
                                    <Stepper nonLinear activeStep={activeStep}>
                                        {steps.map((label, index) => (
                                            <Step key={label} completed={completed[index]}>
                                                <StepButton color="inherit" onClick={handleStep(index)}>
                                                    {label}
                                                </StepButton>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <div>

                                    </div>
                                </Box>


                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        width: "80%",
                                        position: 'relative',
                                        minHeight: "200px",
                                        margin: "auto",
                                        height: "auto",
                                    }}
                                >
                                    <AppBar position="static" color="default">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="fullWidth"
                                            aria-label="action tabs example"
                                        >
                                            <Tab label="Add Operator" {...a11yProps(0)} />
                                            <Tab label="List/Remove Operator " {...a11yProps(1)} />
                                            <Tab label="Configure Operator" {...a11yProps(2)} onClick={() => setOnClickedConfigure(true)} />
                                        </Tabs>
                                    </AppBar>
                                    <SwipeableViews

                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={value}
                                        onChangeIndex={handleChangeIndex}
                                    >
                                        <TabPanel value={value} index={0} dir={theme.direction}>
                                            <div className={"section1_add_operators"}>
                                                <div className={"header_sector_text"}>ADD OPERATOR</div>
                                                <br />
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <div className={"operators_input_sector1"}>
                                                        <TextField required inputRef={operatorsNoRef}
                                                            id="outlined-search"
                                                            label="Enter Operators Number"
                                                            type="number" />
                                                    </div>
                                                    <div
                                                        style={{ color: errorMessage && errorMessage.toString().includes("Successfully") ? "green" : "red" }}
                                                        hidden={!errorMessage} className={"errorMessage_text"}>
                                                        <span>{errorMessage}</span>
                                                    </div>
                                                </Box>


                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={1} dir={theme.direction}>
                                            <div className={"operator_adding_sector"}>
                                                <div className={"delete_operators_text"}>Remove
                                                    Operators <span>from right to left</span></div>
                                                <Grid container spacing={2} justifyContent="center" alignItems="center">
                                                    <Grid item>{customList(left)}</Grid>
                                                    <Grid item>
                                                        <Grid container direction="column" alignItems="center">
                                                            <Button
                                                                sx={{ my: 0.5 }}
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleAllRight}
                                                                disabled={left.length === 0}
                                                                aria-label="move all right"
                                                            >
                                                                ≫
                                                            </Button>
                                                            <Button
                                                                sx={{ my: 0.5 }}
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleCheckedRight}
                                                                disabled={leftChecked.length === 0}
                                                                aria-label="move selected right"
                                                            >
                                                                &gt;
                                                            </Button>
                                                            <Button
                                                                sx={{ my: 0.5 }}
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleCheckedLeft}
                                                                disabled={rightChecked.length === 0}
                                                                aria-label="move selected left"
                                                            >
                                                                &lt;
                                                            </Button>
                                                            <Button
                                                                sx={{ my: 0.5 }}
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={handleAllLeft}
                                                                disabled={right.length === 0}
                                                                aria-label="move all left"
                                                            >
                                                                ≪
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    {!isEmpty(right) && <Grid item>{customList(right)}</Grid>}
                                                </Grid>

                                                <div
                                                    className={"operators_error_text"}
                                                    style={{ color: operatorConfigError.includes("successfully") ? "green" : "red" }}>{operatorConfigError && operatorConfigError}</div>


                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={2} dir={theme.direction} >
                                            <div className={"configure_operator"}>
                                                {generateList()}
                                                <div
                                                    className={"operators_error_text"}
                                                    style={{ color: operatorConfigError.includes("successfully") ? "green" : "red" }}>{operatorConfigError && operatorConfigError}</div>
                                            </div>

                                        </TabPanel>
                                    </SwipeableViews>
                                    {fabs.map((fab, index) => (
                                        <Zoom
                                            key={fab.color}
                                            in={value === index}
                                            timeout={transitionDuration}
                                            style={{
                                                transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
                                            }}

                                            unmountOnExit
                                        >
                                            <Fab onClick={() => {
                                                onClickFab(index)
                                            }}
                                                sx={fab.sx} aria-label={fab.label}
                                                color={fab.color}>
                                                {fab.icon}
                                            </Fab>
                                        </Zoom>
                                    ))}


                                </Box>
                                <br />

                            </div>
                            <Divider />
                            <br />
                        </div>
                        <Modal
                            open={showModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} className={"updateprofile-modal"}>
                                <Typography id="modal-modal-title" variant="h6" component="Toobar">
                                    <Toolbar>Operator Configuration <span> <ToggleButton onClick={() => {
                                        setShowModal(false)
                                    }}
                                        value="button"
                                    >
                                        <CheckIcon />
                                    </ToggleButton>
                                    </span></Toolbar>
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2, width: "60px" }}>
                                    <div>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    error={errorConfigurationAdd && errorConfigurationAdd.toString().includes("Entry")}
                                                    id="filled-error"
                                                    label="Entry"
                                                    variant="filled"
                                                    helperText={errorConfigurationAdd && errorConfigurationAdd.toString().includes("Entry") ? errorConfigurationAdd : "Entry position"}
                                                    inputRef={addEntryRef}
                                                    defaultValue={configOpsButton === "edit" && defaultModalValues ? defaultModalValues.entry : ""}
                                                />
                                                <TextField
                                                    error={errorConfigurationAdd && errorConfigurationAdd.toString().includes("Symbol")}
                                                    id="filled-error"
                                                    label="Symbol"
                                                    variant="filled"
                                                    inputRef={addSymbolRef}
                                                    helperText={errorConfigurationAdd && errorConfigurationAdd.toString().includes("Symbol") ? errorConfigurationAdd : "Symbol position"}
                                                    defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.symbol : ""}

                                                />
                                                <div>
                                                    <FormControlLabel className={"pendingOrderForm"}
                                                        value="start"
                                                        control={
                                                            <Checkbox
                                                                checked={pendingOrdersChecked}
                                                                onChange={handlePendingOrderChecked}
                                                            />}
                                                        label="Pending Order?"
                                                        labelPlacement="top"
                                                        defaultValue={sameTpsChecked}

                                                    />
                                                </div>
                                                <TextField
                                                    error={errorConfigurationAdd && errorConfigurationAdd.toString().includes("Type")}
                                                    id="filled-error"
                                                    label="Type"
                                                    variant="filled"
                                                    inputRef={addTypeRef}
                                                    helperText={errorConfigurationAdd && errorConfigurationAdd.toString().includes("Type") ? errorConfigurationAdd : "Type position"}
                                                    defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.type : ""}

                                                />

                                                <TextField
                                                    error={errorConfigurationAdd && errorConfigurationAdd.toString().includes("StopLoss")}
                                                    id="filled-error-helper-text"
                                                    label="Stop Loss"
                                                    variant="filled"
                                                    inputRef={addSLRef}
                                                    helperText={errorConfigurationAdd && errorConfigurationAdd.toString().includes("StopLoss") ? errorConfigurationAdd : "StopLoss position"}
                                                    defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.sl : ""}

                                                />
                                            </div>

                                            <div>

                                                <FormControlLabel className={"sameTpsFormcontrol"}
                                                    value="start"
                                                    control={
                                                        <Checkbox
                                                            checked={sameTpsChecked}
                                                            onChange={handleChangeMultiple}
                                                        />}
                                                    label="Same Multiple Take profits ?"
                                                    labelPlacement="top"
                                                    defaultValue={sameTpsChecked}

                                                />

                                                <TextField
                                                    error={errorConfigurationAdd && errorConfigurationAdd.toString().includes("TP1")}
                                                    id="filled-error"
                                                    label="Take profit 1"
                                                    variant="filled"
                                                    inputRef={addTp1Ref}
                                                    helperText={errorConfigurationAdd && errorConfigurationAdd.toString().includes("TP1") ? errorConfigurationAdd : "TP1 position"}
                                                    defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.tp1 : ""}

                                                />
                                                <TextField
                                                    id="filled-error-helper-text"
                                                    label="Take profit 2"
                                                    helperText="TP2 position"
                                                    variant="filled"
                                                    inputRef={addTp2Ref}
                                                    defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.tp2 : ""}

                                                />
                                                <TextField
                                                    id="filled-error-helper-text"
                                                    label="Take profit 3"
                                                    helperText="TP3 position"
                                                    variant="filled"
                                                    inputRef={addTp3Ref}
                                                    defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.tp3 : ""}

                                                />
                                                <TextField
                                                    id="filled-error-helper-text"
                                                    label="Take profit 4"
                                                    helperText="TP4 position"
                                                    variant="filled"
                                                    inputRef={addTp4Ref}
                                                    defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.tp4 : ""}
                                                />

                                                <Divider />
                                                <div>
                                                    <FormControlLabel className={"tradeReplication"}
                                                        value="start"
                                                        control={
                                                            <Checkbox
                                                                checked={enableTradeReplication}
                                                                onChange={handleTradeReplication}
                                                            />}
                                                        label="Trade Replication Enable ?"
                                                        labelPlacement="top"
                                                        defaultValue={enableTradeReplication}

                                                    />
                                                </div>

                                                <Divider />
                                                <div>
                                                    <Grid container>
                                                        <Grid item s={5}>
                                                            <InputLabel className={'tradeReplication'}
                                                                htmlFor="my-input">
                                                                Trader Strategy
                                                            </InputLabel>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={5}>
                                                            <FormControlLabel className={'tradeStrategy'}
                                                                value="start"
                                                                name={"grid"}
                                                                control={
                                                                    <Checkbox
                                                                        checked={tradeStrategyCheckGrid}
                                                                        onChange={handleTradeStrategy}
                                                                    />}
                                                                label="Grid"
                                                                labelPlacement="top"
                                                                defaultValue={tradeStrategyCheckGrid}

                                                            >
                                                            </FormControlLabel>
                                                        </Grid>

                                                        <Grid item xs={5}>
                                                            <FormControlLabel className={'tradeStrategy'}
                                                                value="start"
                                                                name={"scalp"}
                                                                control={
                                                                    <Checkbox
                                                                        checked={tradeStrategyCheckScalp}
                                                                        onChange={handleTradeStrategy}
                                                                    />}
                                                                label="Scalping"
                                                                labelPlacement="top"
                                                                defaultValue={tradeStrategyCheckScalp}

                                                            >
                                                            </FormControlLabel>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider />
                                                    {
                                                        <div hidden={!tradeStrategyCheckGrid}>
                                                            <Grid container spacing={7}>
                                                                <Grid item xs={5}>
                                                                    <Box
                                                                        component="form"
                                                                        sx={{
                                                                            '& > :not(style)': { m: 1, width: '25ch' },
                                                                        }}
                                                                        noValidate
                                                                        autoComplete="off"
                                                                    >
                                                                        <TextField inputRef={tradeStrategyOffsetRef}
                                                                            defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.tradeStrategyOffset : ""}
                                                                            label="pips offset" variant="filled"
                                                                            color="success" focused />
                                                                        <TextField inputRef={tradeStrategyRegexRef}
                                                                            defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.tradeStrategyRegex : "[\s\S]*"}
                                                                            placeholder={"[\s\S]*"}
                                                                            label="Split by line number or string (regex)"
                                                                            variant="standard"
                                                                            color="warning"
                                                                            focused
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    }

                                                    <Divider />

                                                    <div>
                                                        <TextField
                                                            id="filled-error-helper-text"
                                                            label="[Optional] Specific Close Text"
                                                            helperText="close text - can be demarcated by commas e.g close,closing"
                                                            variant="filled"
                                                            inputRef={closeTextOffsetRef}
                                                            defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.closeText : ""}
                                                        />
                                                    </div>
                                                    <Divider />

                                                    <Divider />
                                                    <div>
                                                        <FormControlLabel className={"disableTradeSignal"}
                                                            value="start"
                                                            control={
                                                                <Checkbox
                                                                    checked={disableTradeSignal}
                                                                    onChange={handleDisableTrade}
                                                                />}
                                                            label="Disable Trade Signal ?"
                                                            labelPlacement="top"
                                                            defaultValue={disableTradeSignal}

                                                        />
                                                    </div>
                                                    <Divider />
                                                    <div>
                                                        <TextField
                                                            id="filled-error-helper-text"
                                                            label="default symbol"
                                                            helperText="Trader default symbol"
                                                            variant="filled"
                                                            inputRef={defaultSymbol}
                                                            defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.traderDefaultSymbol : ""}
                                                        />
                                                    </div>
                                                    <Divider />
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={5}>
                                                            <FormControlLabel className={'tradeStrategy'}
                                                                value="start"
                                                                name={"defaultTP"}
                                                                control={
                                                                    <Checkbox
                                                                        checked={defaultTPCheck}
                                                                        onChange={handleDefaultTP}
                                                                    />}
                                                                label="Default TP"
                                                                labelPlacement="top"
                                                                defaultValue={defaultTPCheck}

                                                            >
                                                            </FormControlLabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Divider />
                                                    {
                                                        <div hidden={!defaultTPCheck}>
                                                            <Grid container spacing={7}>
                                                                <Grid item xs={5}>
                                                                    <Box
                                                                        component="form"
                                                                        sx={{
                                                                            '& > :not(style)': { m: 1, width: '25ch' },
                                                                        }}
                                                                        noValidate
                                                                        autoComplete="off"
                                                                    >
                                                                        <TextField inputRef={defaultTPLevelRef}
                                                                            defaultValue={configOpsButton == "edit" && defaultModalValues ? defaultModalValues.defaultTPLevel : 1}
                                                                            label="Number of TP levels"
                                                                            variant="filled"
                                                                            color="success" focused />
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    }

                                                    <Grid container spacing={5}>
                                                        <Grid item xs={5}>
                                                            <FormControlLabel className={'tradeStrategy'}
                                                                value="start"
                                                                name={"defaultSL"}
                                                                control={
                                                                    <Checkbox
                                                                        checked={defaultSLCheck}
                                                                        onChange={handleDefaultSL}
                                                                    />}
                                                                label="Default SL"
                                                                labelPlacement="top"
                                                                defaultValue={defaultSLCheck}

                                                            >
                                                            </FormControlLabel>
                                                        </Grid>
                                                    </Grid>

                                                </div>

                                                <div className={"successfully_message"}> {errorConfigurationAdd}</div>
                                            </div>

                                            <div>
                                                <Stack direction="row" spacing={1}>
                                                    <IconButton onClick={() => handleModalSave()} color="secondary"
                                                        aria-label="add an alarm">
                                                        <SaveIcon />
                                                        <span className={"modal_close_button_text"}>Save</span>
                                                    </IconButton>
                                                    <IconButton onClick={() => closeOperatorConfig()} color="primary"
                                                        aria-label="add to shopping cart">
                                                        <Close />
                                                        <span className={"modal_close_button_text"}>Close</span>
                                                    </IconButton>
                                                </Stack>
                                            </div>
                                        </Box>
                                    </div>
                                </Typography>
                            </Box>
                        </Modal>
                    </>
            }
        </>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

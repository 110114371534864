import {createSlice} from "@reduxjs/toolkit"

const initialState = {};

export const userTransactionsInfoSlice = createSlice({
    name: "userTransactionInformation",
    initialState: initialState,
    reducers: {
        userTransactionInfo: (state, action) => {
            state.value = action.payload; ;
        }
    }
});

export const {userTransactionInfo} = userTransactionsInfoSlice.actions;


export default userTransactionsInfoSlice.reducer;
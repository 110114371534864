import Chart from "../../components/charts/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/ClipLoader";
import { getMonthString, isEmpty, listEmpty } from "../../components/utils/Utils";
import { collection, getDocs, query, where, orderBy, updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { userInfo } from "../../reducers/UserInformation-reducer";
import { transactionInfoMild } from "../../reducers/Transactions-Mild";
import { tradesMonthlyRevenueInfo } from "../../reducers/RevenuePermonthPerUser-reducer";
import { addDoc } from "@firebase/firestore";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


export default function Home() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    let generatePrepData = [];
    let color = "#00ff0e";

    const transactions = useSelector((state) => state.transactionInfoMild.value);
    const [_transactions, setTransactions] = useState([]);
    const _user = useSelector((state) => state.userInfo.value);
    const [_tradesMonthlyRevenueInfo, setTradesMonthlyRevenueInfo] = useState([]);
    //let _tradesMonthlyRevenueInfo = useSelector((state) => state.tradesMonthlyRevenueInfo.value);

    const methodIhateButItsNecessary = (tempData, _transactions) => {
        let revenuePerMonth = [
            {
                name: "Jan",
                Revenue: 0,
            },
            {
                name: "Feb",
                Revenue: 0,
            },
            {
                name: "Mar",
                Revenue: 0,
            },
            {
                name: "Apr",
                Revenue: 0,
            },
            {
                name: "May",
                Revenue: 0,
            },
            {
                name: "Jun",
                Revenue: 0,
            },
            {
                name: "Jul",
                Revenue: 0,
            },
            {
                name: "Aug",
                Revenue: 0,
            },
            {
                name: "Sep",
                Revenue: 0,
            },
            {
                name: "Oct",
                Revenue: 0,
            },
            {
                name: "Nov",
                Revenue: 0,
            },
            {
                name: "Dec",
                Revenue: 0,
            },
        ];

        let retVal = 0;
        //ran once
        // eslint-disable-next-line no-unused-expressions
        _transactions && _transactions.map((value, key) => {
            if (value.orderCloseTime.toDate().getFullYear() === new Date().getFullYear()) {
                const addedValue = Number(revenuePerMonth[getMonthFromDate(value.orderCloseTime.toDate())].Revenue + parseFloat(value.orderProfit))
                revenuePerMonth[getMonthFromDate(value.orderCloseTime.toDate())].Revenue = Math.round(addedValue + Number.EPSILON) * 100 / 100;
                //Math.round( + Number.EPSILON) * 100) / 100
            }
        })
        if (tempData && tempData[0] && revenuePerMonth[new Date().getMonth()] && revenuePerMonth[new Date().getMonth()].Revenue != tempData[0].equity_per_month[getMonthString(new Date().getMonth()).toLowerCase()]) {
            //we will be updating or creating new monthly equity per user
            if (tempData[0].equity_per_month[getMonthString(new Date().getMonth()).toLowerCase()] || tempData[0].equity_per_month[getMonthString(new Date().getMonth()).toLowerCase()] == 0 && tempData && tempData[0]) {
                //update value of equity and trade count
                const monthlyEquityPerUserRef = doc(db, "Monthly_equity_per_user", tempData[0].id)
                updateDoc(monthlyEquityPerUserRef, {
                    ...tempData[0],
                    equity_per_month: {
                        ...tempData[0].equity_per_month,
                        [getMonthString(new Date().getMonth()).toLowerCase()]: revenuePerMonth[new Date().getMonth()].Revenue
                    },

                    total_trades_per_month: {
                        ...tempData[0].total_trades_per_month,
                        [getMonthString(new Date().getMonth()).toLowerCase()]: _transactions.length
                    }
                }).then((_result) => {
                    dispatch(tradesMonthlyRevenueInfo({ _tempData: tempData }));
                }).catch(e => console.error(e))

            }


        } else if (_user && _user._userInfo && _user._userInfo[0] && isEmpty(tempData) || _user && _user._userInfo && _user._userInfo[0] && isEmpty(tempData[0])) {
            // create fresh insert for use
            let revenueByMonth = {
                "jan": 0,
                "feb": 0,
                "mar": 0,
                "apr": 0,
                "may": 0,
                "jun": 0,
                "jul": 0,
                "aug": 0,
                "sep": 0,
                "oct": 0,
                "nov": 0,
                "dec": 0
            }
            let _data =
            {
                email: _user._userInfo[0].email,
                equity_per_month: {
                    ...revenueByMonth,
                    [getMonthString(new Date().getMonth()).toLowerCase()]: revenuePerMonth[new Date().getMonth()].Revenue
                },
                total_trades_per_month: {
                    ...revenueByMonth,
                    [getMonthString(new Date().getMonth()).toLowerCase()]: _transactions.length
                },
                user_id: _user._userInfo[0].User_Id
            }
            addDoc(collection(db, "Monthly_equity_per_user"), _data);
            dispatch(tradesMonthlyRevenueInfo({ _tempData: [_data] }));
        }

    }



    useEffect(() => {
        let _transList = [];
        let _tempData = [];
        const runQuery1 = async () => {
            const monthlyEquityPerUserCollectionRef = collection(db, "Monthly_equity_per_user");
            const q = query(monthlyEquityPerUserCollectionRef, where("email", "==", _user && _user._userInfo && _user._userInfo[0] ? _user._userInfo[0].email : ""));
            const data = await getDocs(q);
            const _tempData = await data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            setTradesMonthlyRevenueInfo({ _tempData })
            _user && _user._userInfo && _user._userInfo[0] && runQuery2(_tempData);
        }
        _user && _user._userInfo && _user._userInfo[0] && runQuery1();

        const runQuery2 = async (_tempData) => {
            if (transactions == null || isEmpty(transactions)) {
                const tradeHistoryCollectionRef = collection(db, "Trade_history_report");
                //const q = query(tradeHistoryCollectionRef, where("userId", "==", _user && _user._userInfo[0] ? " " + _user._userInfo[0].User_Id.toString() : 0), where("orderCloseTime", ">=", new Date(new Date().getFullYear(), new Date().getMonth(), 1)), orderBy("orderCloseTime", "desc"));
                const q = query(
                    tradeHistoryCollectionRef,
                    where("userId", "==", _user && _user._userInfo[0] ? _user._userInfo[0].User_Id.toString() : 0),
                    where("orderCloseTime", ">=", new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
                    orderBy("orderCloseTime", "desc")
                );
                const data = await getDocs(q);
                data.docs.map((doc) => {
                    _transList.push(({ ...doc.data(), id: doc.id }))
                })
                updateTransactionDispatcher(_transList)
                generatePrepData = prepareRevenueData2(_transList, { _tempData })
                setTransactions(_transList);
                methodIhateButItsNecessary(_tempData, _transList);
            } else {
                setTransactions(transactions && transactions._transList);
                generatePrepData = prepareRevenueData2(transactions._transList, { _tempData })
            }
            // dispatch(tradesMonthlyRevenueInfo({_tempData}));
        }

    }, [_user])

    const updateTransactionDispatcher = (_data) => {
        dispatch(transactionInfoMild({ _transList: _data }));
    }

    return (
        <>
            {
                _transactions && !listEmpty(_transactions) && loading ? setLoading(false) : ""
            }
            {
                loading ?
                    <div className="sweet-loading home">
                        <BeatLoader
                            color={color}
                            loading={loading}
                            size={60} />
                    </div>
                    :
                    <div className="home">
                        <FeaturedInfo revenueData={!isEmpty(generatePrepData) ? generatePrepData : prepareRevenueData2(_transactions, _tradesMonthlyRevenueInfo)}
                            transactions={_transactions} tradesMonthlyRevenueInfo={_tradesMonthlyRevenueInfo} />
                        <Chart data={!isEmpty(generatePrepData) && generatePrepData} title="Equity Curve"
                            grid
                            dataKey="Revenue" />
                        <div className="homeWidgets">
                            <WidgetSm transactions={_transactions ? _transactions : prepareRevenueData2(_transactions, _tradesMonthlyRevenueInfo)} />
                            <WidgetLg transactions={_transactions ? _transactions : prepareRevenueData2(_transactions, _tradesMonthlyRevenueInfo)} />
                        </div>
                    </div>
            }


        </>
    );
}

const getMonthFromDate = (date) => {
    return date.getMonth();
}

const prepareRevenueData2 = (_transactions, _tradesMonthlyRevenueInfo) => {
    let revenuePerMonth = [
        {
            name: "Jan",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["jan"] : 0,
        },
        {
            name: "Feb",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["feb"] : 0,
        },
        {
            name: "Mar",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["mar"] : 0,
        },
        {
            name: "Apr",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["apr"] : 0,
        },
        {
            name: "May",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["may"] : 0,
        },
        {
            name: "Jun",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["jun"] : 0,
        },
        {
            name: "Jul",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["jul"] : 0,
        },
        {
            name: "Aug",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["aug"] : 0,
        },
        {
            name: "Sep",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["sep"] : 0,
        },
        {
            name: "Oct",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["oct"] : 0,
        },
        {
            name: "Nov",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["nov"] : 0,
        },
        {
            name: "Dec",
            Revenue: _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].equity_per_month ? _tradesMonthlyRevenueInfo._tempData[0].equity_per_month["dec"] : 0,
        },
    ];

    return revenuePerMonth

}

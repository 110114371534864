import "./featuredInfoGlobal.css";
import {ArrowUpward} from "@material-ui/icons";
import {countHighestOccurance, findHighestValueOfObjectElement, higestOccurance, isNegativeNumber} from "../utils/Utils"
import {useEffect, useState} from "react";
import {ArrowDownward} from "@mui/icons-material";


let trades = 0;
let tradesCompareToLast = 0;
let higestOccuranceSymbol = "";
let higestOccuranceSymbolCount = 0;
let userDashLocal =
    {
        higestOccuranceSymbolCount: 0,

    }


export default function AnalyticsFeaturedInfo({lineChartRetVal: lineChartRetVal, transactions: transactions, prepData: prepData, dataByOperators: dataByOperators, higestGrossing: higestGrossing, setOpenCallback: setOpenCallback}) {
    const [highestOperatorState, setHighestOperator] = useState(higestGrossing);
    const [tradeCountByOperator, setTradeCountByOperator] = useState(0);
    const [highestGrossingCount, setHighestGrossingCount] = useState(0);

    useEffect(() => {
        setOpenCallback(true)
        setHighestOperator(higestGrossing)
        // {highestOperatorState && highestOperatorState.length ? displayNegativeSign(highestOperatorState[highestOperatorState.length - 1].value) + highestOperatorState[highestOperatorState.length - 1].value + "" : higestGrossing ? +higestGrossing.value + "" : 0}
        setHighestGrossingCount(highestOperatorState && highestOperatorState.length ? displayNegativeSign(highestOperatorState[highestOperatorState.length - 1].value) + highestOperatorState[highestOperatorState.length - 1].value + "" : higestGrossing ? +higestGrossing.value + "" : 0)
        setOpenCallback(false);
    }, [dataByOperators])


    return (
        <div className="featured">
            <div className="featuredItem analytics one">
                <span className="featuredTitle">Highest Grossing</span>
                <div className="featuredMoneyContainer">
                    <span
                        className="featuredMoney">{displayNegativeSign(higestGrossing && higestGrossing.value)}{highestGrossingCount}</span>
                    <span className="featuredMoneyRate">
                        { higestGrossing && isNegativeNumber(higestGrossing.value) ? <ArrowDownward className="featuredIcon negative"/> : highestOperatorState && highestOperatorState.length && isNegativeNumber(highestOperatorState[highestOperatorState.length - 1].value) ?
                            <ArrowDownward className="featuredIcon negative"/> :
                                <ArrowUpward
                                className="featuredIcon "/> }
          </span>
                </div>
                <span
                    className="featuredSub">{highestOperatorState && highestOperatorState.length ? highestOperatorState[highestOperatorState.length - 1].name + "\n" + "🎖🎖🎖🎖🎖" : higestGrossing ? higestGrossing.name + "\n" + "🎖🎖🎖🎖🎖" : "0"}</span>
            </div>
            <div className="featuredItem analytics two">
                <span className="featuredTitle">Total Trades</span>
                <div className="featuredMoneyContainer">
                    <span
                        className="featuredMoney">{highestOperatorState && higestGrossing && dataByOperators && dataByOperators._dataByOperators[highestOperatorState.length ? highestOperatorState[highestOperatorState.length - 1].name : higestGrossing.name] ? dataByOperators._dataByOperators[highestOperatorState.length ? highestOperatorState[highestOperatorState.length - 1].name : higestGrossing.name].count : 0}</span>
                    <span className="featuredMoneyRate"> <ArrowUpward className={"featuredIcon"}/>
          </span>
                </div>
                <span className="featuredSub">This month</span>
            </div>
            <div className="featuredItem analytics three">
                <span className="featuredTitle">Most Traded Symbol</span>
                <div className="featuredMoneyContainer">
                    <span
                        className="featuredMoney">{highestOperatorState && higestGrossing && findHighestValueOfObjectElement(dataByOperators && dataByOperators._dataByOperators[highestOperatorState.length ? highestOperatorState[highestOperatorState.length - 1].name : higestGrossing.name] && dataByOperators._dataByOperators[highestOperatorState.length ? highestOperatorState[highestOperatorState.length - 1].name : higestGrossing.name].mostTradedSymbol)}</span>
                    <span className="featuredMoneyRate">
            +{highestOperatorState && higestGrossing && dataByOperators._dataByOperators[highestOperatorState.length ? highestOperatorState[highestOperatorState.length - 1].name : higestGrossing.name] && dataByOperators._dataByOperators[[highestOperatorState.length ? highestOperatorState[highestOperatorState.length - 1].name : higestGrossing.name]].mostTradedSymbol[findHighestValueOfObjectElement(dataByOperators && dataByOperators._dataByOperators[highestOperatorState.length ? highestOperatorState[highestOperatorState.length - 1].name : higestGrossing.name] && dataByOperators._dataByOperators[highestOperatorState.length ? highestOperatorState[highestOperatorState.length - 1].name : higestGrossing.name].mostTradedSymbol)]}<ArrowUpward
                        className="featuredIcon"/>
          </span>
                </div>
                <span className="featuredSub">Traded this month</span>
            </div>
        </div>
    );
}


const totalTradesByOperator = (transactions, operator) => {
    let count = 0;
    const operatorName = operator ? operator[operator.length - 1].name : "";
    transactions && transactions.filter((value) => value.orderCloseTime.toDate().getFullYear() === new Date().getFullYear() && value.orderCloseTime.toDate().getMonth() === new Date().getMonth()).map((value, key) => {

        count = value.orderComment.trim().includes(operatorName) ? count + 1 : count;
        return count;
    })

    return count;
}


const setTotalTrades = (transactions) => {
    let tradeCounter = 0;
    let tradesCompareToLastCounter = 0;
    let prevMonth = new Date().getMonth() === 0 ? 0 : new Date().getMonth() - 1;
    transactions && transactions.map((value, key) => {
        if (value.orderOpenTime.toDate().getMonth() === new Date().getMonth() && value.orderOpenTime.toDate().getFullYear() === new Date().getFullYear())
            tradeCounter = tradeCounter + 1;

        if (value.orderOpenTime.toDate().getMonth() === prevMonth && value.orderOpenTime.toDate().getFullYear() === new Date().getFullYear())
            tradesCompareToLastCounter = tradesCompareToLastCounter + 1;

    })
    trades = tradeCounter;
    tradesCompareToLast = tradesCompareToLastCounter;

}

const setHigestOccuranceSymbol = (transactions) => {
    higestOccuranceSymbol = higestOccurance(transactions && transactions.filter(value => value.orderOpenTime.toDate().getMonth === new Date().getMonth).map((value, key) => value.orderSymbol))
    higestOccuranceSymbolCount = countHighestOccurance(transactions && transactions.filter(value => value.orderOpenTime.toDate().getMonth === new Date().getMonth).map((value, key) => value.orderSymbol), higestOccuranceSymbol)
    userDashLocal = higestOccuranceSymbolCount;

}

const displayNegativeSign = (input) => {
    return isNegativeNumber(input) ? "" : "+";
}
import {createSlice} from "@reduxjs/toolkit"
import {userSlice} from "./Users-reducer";

const initialState = {};

export const userDashSlice = createSlice({
    name: "userDash",
    initialState: initialState,
    reducers: {
        userDash: (state, action) => {
            state.value = action.payload;
        },
    }
});


export const { userDash} = userDashSlice.actions;

export default userDashSlice.reducer;




import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import Moment from "moment";
import {ViewCompact} from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import {isEmpty} from "../../components/utils/Utils";
import "./operatormonthlyprofiabilitychart.css"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function DataTable({transactions: transactions}) {

    let rows = [];
    let counter = 0;
    const [open, setOpen] = React.useState(false);
    const [selectTransaction, setSelectedTransaction] = React.useState({});
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    transactions && transactions.map((transaction) => {
        rows.push({
            id: counter + 1,
            operator: transaction.orderComment,
            symbol: transaction.orderSymbol.trim(),
            profit: Number(String(transaction.orderProfit).trim()).toFixed(2),
            lot: Number(String(transaction.orderSize).trim()),
            date: Moment(transaction.orderCloseTime.toDate()).format('YYYY-MM-DD HH:mm')
        })
        counter++;
    })


    const handleInsideRoleClick = (paramId) => {
        handleOpen();
        setSelectedTransaction(transactions[paramId - 1])
    }

    const columns = [
        {field: 'id', headerName: 'ID', width: 70, cellClassName: 'super-app-theme--cell'},
        {field: 'operator', headerName: 'Operator', width: 160, cellClassName: 'super-app-theme--cell'},
        {field: 'symbol', headerName: 'Symbol', width: 90, cellClassName: 'super-app-theme--cell'},
        {field: 'profit', headerName: 'Profit', width: 90, cellClassName: 'super-app-theme--cell'},
        {
            field: 'lot',
            headerName: 'Lot',
            type: 'number',
            width: 70,
            cellClassName: 'super-app-theme--cell'
        },
        {
            field: 'date',
            headerName: 'Date',
            description: 'This column has a value getter and is sortable.',
            sortable: true,
            width: 250,
            cellClassName: 'super-app-theme--cell',
            valueGetter: (params) =>
                `${params.row.date || ''} ${params.row.profit || ''}`,
        },
        {
            field: "action",
            headerName: "Action",
            width: 300,
            cellClassName: 'super-app-theme--cell',
            renderCell: (params) => {
                return (
                    <>
                        <Link to={"/user/" + params.row.id}>
                            <button onClick={() => handleInsideRoleClick(params.row.id)} className="userListEdit">View
                            </button>
                        </Link>
                        <ViewCompact style={{cursor: "not-allowed"}}
                                     className="userListDelete"
                        />
                    </>
                );
            },
        },
    ];


    return (
        <>
{/*
            {!isEmpty(selectTransaction) && console.log("Selected operator transcation....", selectTransaction)}
*/}
            <div style={{display: "none"}}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography style={{textAlign: "center", fontStyle: "bold", fontFamily: "sans-serif"}}
                                    id="modal-modal-title" variant="h6"
                                    component="h2">
                            Trade Details
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            <List
                                sx={{
                                    width: '100%',
                                    maxWidth: 360,
                                    bgcolor: 'background.paper',
                                }}
                            >
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ImageIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Dates"
                                                  secondary={`
                                                  OrderOpen @: ${!isEmpty(selectTransaction) && Moment(selectTransaction.orderOpenTime.toDate()).format('YYYY-MM-DD HH:mm')}
                                                  OrderClose @: ${!isEmpty(selectTransaction) && Moment(selectTransaction.orderCloseTime.toDate()).format('YYYY-MM-DD HH:mm')}
                                                  `}/>
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Currency Type" secondary={`
                                                  OrderSymbol @: ${!isEmpty(selectTransaction) && selectTransaction.orderSymbol.trim()} \b\r\
                                                  OrderType @: ${!isEmpty(selectTransaction) && selectTransaction.orderType.trim()}
                                                  `}/>
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BeachAccessIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Position" secondary={`
                                                  OrderOpenPrice @: ${!isEmpty(selectTransaction) && selectTransaction.orderOpenPrice} 
                                                  OrderStopLoss @: ${!isEmpty(selectTransaction) && selectTransaction.orderStopLoss} 
                                                  OrderTakeProfit @: ${!isEmpty(selectTransaction) && selectTransaction.orderTakeProfit}
                                                  `}/>
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Size Profit" secondary={`
                                                  OrderProfit @: ${!isEmpty(selectTransaction) && selectTransaction.orderProfit} \n \b\r\ \b\r
                                                  \n \b\r\ \b\r \n \b\r\ \b\r \        \  
                                                  \n \b\r\ \b\r 
                                                   \n \b\r\ \b\r 
                                                    \n \b\r\ \b\r 
                                                     \n \b\r\ \b\r 
                                                    \n \b\r\ \b\r OrderSize @: ${!isEmpty(selectTransaction) && selectTransaction.orderSize}
                                                  `}/>
                                </ListItem>
                            </List>
                        </Typography>
                    </Box>
                </Modal>
            </div>
            <div className={"operator_monthly_containned"}>
                <div style={{height: 400, width: '100%', textAlign: "center"}}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}

                    />

                </div>
            </div>
        </>
    );
}
import * as React from 'react';
import {useState} from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';

export default function ViewsDatePicker({text: text, month: month, function: callback}) {

    const [value, setValue] = useState(new Date());
    const onchangeDate = (event) => {
        callback(event)
        setValue(event);

    }

    return (

        <Toolbar>
            <div>{text}</div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box m={2}>
                    {() => {
                        callback = value
                    }}
                    <DatePicker
                        inputFormat="yyyy-MM"
                        views={['year', 'month']}
                        label="Select Year and Month"
                        minDate={new Date('2021-03-01')}
                        maxDate={new Date('2030-06-01')} //i'll slap my self if i have to come back here in the future
                        value={value}
                        onChange={onchangeDate}
                        renderInput={(params) => <TextField {...params} helperText={null}/>}
                    />
                </Box>
            </LocalizationProvider>
        </Toolbar>

    );
}
import "./widgetSm.css";
import {Visibility} from "@material-ui/icons";

export default function WidgetSm({transactions:  transactions}) {
    return (
        <div className="widgetSm">
            <span className="widgetSmTitle">Latest Transactions</span>
            <ul className="widgetSmList">
                {
                    transactions && transactions.slice(0, 5).map((value, key)=>{

                        return <li className="widgetSmListItem" id={key}>
                            <img
                                src="https://images.pexels.com/photos/3992656/pexels-photo-3992656.png?auto=compress&cs=tinysrgb&dpr=2&w=500"
                                alt=""
                                className="widgetSmImg"
                            />
                            <div className="widgetSmUser">
                                <span className="widgetSmUsername">{value.orderSymbol.trim()}</span>
                                <span className="widgetSmUserTitle">{value.orderType.trim()}</span>
                            </div>
                            <button className="widgetSmButton">
                                <Visibility className="widgetSmIcon" />
                                View
                            </button>
                        </li>
                    })

                }



            </ul>
        </div>
    );
}
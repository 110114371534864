import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import "bootstrap/dist/css/bootstrap.min.css"
import reportWebVitals from './reportWebVitals';
import {configureStore} from "@reduxjs/toolkit"
import {Provider} from "react-redux";
import userReducer from "./reducers/Users-reducer"
import userInformation from "./reducers/UserInformation-reducer"
import userTransactionInfo from "./reducers/Users-TransList-Reducer"
import transactionInformation from "./reducers/TransactionLis-reducer"
import transactionInformationMild from "./reducers/Transactions-Mild"
import tradePerMonthRevenue from "./reducers/RevenuePermonthPerUser-reducer"
import userDash from "./reducers/UserDash-Reducer"
import operatorsInfo from "./reducers/Operators-Reducers"
import operatorsMemInfo from "./reducers/OperatorsMem-Reducer"

const store = configureStore({
    reducer: {
        user: userReducer,
        userInfo: userInformation,
        transactionInfo: transactionInformation,
        transactionInfoMild: transactionInformationMild,
        tradesMonthlyRevenueInfo:  tradePerMonthRevenue,
        userTransaction: userTransactionInfo,
        userDash: userDash,
        operatorsInfo: operatorsInfo,
        operatorsMemInfo: operatorsMemInfo,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

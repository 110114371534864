import "./featuredInfoGlobal.css";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import {countHighestOccurance, getMonthString, higestOccurance, isNegativeNumber} from "../utils/Utils"
import {userDash} from "../../reducers/UserDash-Reducer";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {collection, getDocs, query, where} from "firebase/firestore";
import {auth, db} from "../../firebase";
import {tradesMonthlyRevenueInfo} from "../../reducers/RevenuePermonthPerUser-reducer";
import {useAuthState} from "react-firebase-hooks/auth";


let tradesCompareToLast = 0;
let higestOccuranceSymbol = "";
let trades = 0;
let higestOccuranceSymbolCount= 0
let userDashLocal =
    {
        higestOccuranceSymbolCount: 0,

    }

let revenueValue = 0;


export default function FeaturedInfo({revenueData: revenueData, transactions: transactions, tradesMonthlyRevenueInfo: xtradesMonthlyRevenueInfo}) {
    const dispatch = useDispatch();
    const [user] = useAuthState(auth);
    let _tradesMonthlyRevenueInfo = useSelector((state) => state.tradesMonthlyRevenueInfo.value);
    let revenueCompareRules = "0%"
    const [revenueCompareRulesPercent, setRevenueCompareRulesPercent] = useState();
    const runFuction = () => {
        revenueValue = Math.round((revenueData[new Date().getMonth()].Revenue + Number.EPSILON) * 100) / 100;
    }

    useEffect(() => {
        dispatch(userDash({userDashLocal}))
        let _tempData = [];
        const runQuery = async () => {
            if(_tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData != undefined ){
                trades= _tradesMonthlyRevenueInfo._tempData &&  _tradesMonthlyRevenueInfo._tempData[0] && _tradesMonthlyRevenueInfo._tempData[0].total_trades_per_month ? _tradesMonthlyRevenueInfo._tempData[0].total_trades_per_month[ getMonthString(new Date().getMonth()).toLowerCase()] : 0 ;
                tradesCompareToLast = _tradesMonthlyRevenueInfo._tempData && _tradesMonthlyRevenueInfo._tempData[0] &&  _tradesMonthlyRevenueInfo._tempData[0].total_trades_per_month[ getMonthString(new Date().getMonth() === 0 ? 0 : new Date().getMonth()-1).toLowerCase()] == 0  ? 0 : _tradesMonthlyRevenueInfo._tempData[0].total_trades_per_month ? _tradesMonthlyRevenueInfo._tempData[0].total_trades_per_month[ getMonthString(new Date().getMonth() === 0 ? 0 : new Date().getMonth()-1).toLowerCase()] : 0 ;
            }
            else {
                setTotalTrades(transactions)
            }
            setHigestOccuranceSymbol(transactions)
            //dispatch(tradesMonthlyRevenueInfo({_tempData}));

        }
        runQuery();
        runFuction()
        revenueCompareRules = revenueData && _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData  && _tradesMonthlyRevenueInfo._tempData[0].total_trades_per_month[ getMonthString(new Date().getMonth() === 0 ? 0 : new Date().getMonth()-1).toLowerCase()] == 0  ? 0+"%" : revenueData && _tradesMonthlyRevenueInfo && _tradesMonthlyRevenueInfo._tempData && !isNaN(((Math.round((revenueData[new Date().getMonth()].Revenue + Number.EPSILON) * 100) / 100) * 100) / revenueData[new Date().getMonth() == 0 ? 0 : new Date().getMonth() - 1].Revenue) ? Number(((Math.round((revenueData[new Date().getMonth()].Revenue + Number.EPSILON) * 100) / 100) * 100) / revenueData[new Date().getMonth() === 0 ? 0 : new Date().getMonth() - 1].Revenue).toFixed(2) + "%" : "0%"
        setRevenueCompareRulesPercent(revenueCompareRules);
    }, [transactions, trades, _tradesMonthlyRevenueInfo])

    return (
        <div className="featured">
            <div className="featuredItem homepage one">
                <span className="featuredTitle">Revenue</span>
                <div className="featuredMoneyContainer">
                    <span
                        className="featuredMoney">${revenueData ? Math.round((revenueData[new Date().getMonth()].Revenue + Number.EPSILON) * 100) / 100 : 0.0}</span>
                    <span
                        className="featuredMoneyRate"> {revenueCompareRulesPercent}
                        {isNegativeNumber(revenueValue) ? <ArrowDownward className="featuredIcon negative"/> :
                            <ArrowUpward className="featuredIcon"/>}
          </span>
                </div>
                <span className="featuredSub">Compared to last month</span>
            </div>
            <div className="featuredItem homepage two">
                <span className="featuredTitle">Trades</span>
                <div className="featuredMoneyContainer">
                    <span className="featuredMoney">{trades}</span>
                    <span className="featuredMoneyRate">{tradesCompareToLast}
                        {trades > tradesCompareToLast ? <ArrowDownward className={"featuredIcon negative"}/> :
                            <ArrowUpward className={"featuredIcon"}/>}
          </span>
                </div>
                <span className="featuredSub">Last month</span>
            </div>
            <div className="featuredItem homepage three">
                <span className="featuredTitle">Most Traded</span>
                <div className="featuredMoneyContainer">
                    <span className="featuredMoney">{higestOccuranceSymbol}</span>
                    <span className="featuredMoneyRate">
            +{ higestOccuranceSymbolCount}<ArrowUpward className="featuredIcon"/>
          </span>
                </div>
                <span className="featuredSub">Compared to last month</span>
            </div>
        </div>
    );
}

const setTotalTrades=(transactions)=>
{
    let tradeCounter = 0;
    let tradesCompareToLastCounter = 0;
    let prevMonth = new Date().getMonth() === 0 ? 0 : new Date().getMonth()-1;
    transactions && transactions.map(( value, key )=>{
        if(value.orderCloseTime.toDate().getMonth() === new Date().getMonth() && value.orderCloseTime.toDate().getFullYear() === new Date().getFullYear())
            tradeCounter = tradeCounter + 1;

        if(value.orderCloseTime.toDate().getMonth() === prevMonth  && value.orderCloseTime.toDate().getFullYear() === new Date().getFullYear())
            tradesCompareToLastCounter = tradesCompareToLastCounter + 1;

    })
    trades = tradeCounter;
    tradesCompareToLast = tradesCompareToLastCounter;

}

const setHigestOccuranceSymbol=(transactions)=>
{
    higestOccuranceSymbol = higestOccurance( transactions && transactions.filter(value => value.orderOpenTime.toDate().getMonth === new Date().getMonth ).map((value, key)=> value.orderSymbol))
    higestOccuranceSymbolCount = countHighestOccurance(transactions && transactions.filter(value  => value.orderOpenTime.toDate().getMonth === new Date().getMonth ).map((value, key)=> value.orderSymbol),higestOccuranceSymbol)
    userDashLocal = higestOccuranceSymbolCount;

}
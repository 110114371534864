import "./chart.css";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import React, {useEffect, useState} from "react";
import {getMonthString, getObjectKeys, getObjectValues, isEmpty, isNegativeNumber} from "../utils/Utils"
import {piezData} from "../../DummyData"
//import {ArcElement, Chart as ChartJS, Legend as legendz, Tooltip as Tooltipz, ChartOptions } from 'chart.js';
import {Pie} from 'react-chartjs-2';
import Moment from "moment";
import CalendarHeatmap from 'react-calendar-heatmap';
import './react-calender-heatmap.css';
import {scaleOrdinal} from "d3-scale";
import PropTypes from "prop-types";
import {schemeCategory10} from 'd3-scale-chromatic';

let chartTreeData = [];
let dailyDataOutside = [];


const options = {
    plugins: {
        legend:
            {
                display: true,
                position: "right",
                labels: {
                    font: {
                        size: 10

                    }
                }
            },

    },
    layout: {padding: {bottom: 100}},
};

const colors = scaleOrdinal(schemeCategory10).range();

const data = [
    {
        name: 'Page A',
        uv: 4000,
        female: 2400,
        male: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        female: 1398,
        male: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        female: 9800,
        male: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        female: 3908,
        male: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        female: 4800,
        male: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        female: 3800,
        male: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        female: 4300,
        male: 2100,
    },
];

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
    const {fill, x, y, width, height} = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
};

TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};


export default function AnalysisChart({title, _operators, _transactions, prepData, lineChartRetVal, selectedDate: selectedDate, yearlyTransactions: yearlyTransactions, setOpenCallback: setOpenCallback}) {
    const [_yearlyTransactions, set_YearlyTransactions] = useState({});
    const dateRange = (month, selectedDate) => {
        const listDate = [];
        const getTotalDaysInMonth = new Date(selectedDate.getFullYear(), month + 1, 0).getDate();
        const startDate = Moment(new Date(selectedDate.getFullYear(), month, 1)).format("YYYY-MM-DD");
        const endDate = Moment(new Date(selectedDate.getFullYear(), month, getTotalDaysInMonth)).format("YYYY-MM-DD");
        const dateMove = new Date(startDate);
        let strDate = startDate;
        let count = 0;

        while (strDate < endDate) {
            strDate = dateMove.toISOString().slice(0, 10);
            count = count + 1;
            listDate.push({date: strDate, count: count});
            dateMove.setDate(dateMove.getDate() + 1);
        }
        return listDate
    }



    const prepareDailyDataByMonth = (_transactions, month, _operators, selectedDate) => {
        setOpenCallback(true);
        let retval = [];
        const currentYear = selectedDate.getFullYear();
        let transactions = _transactions._transList ? _transactions._transList : _transactions ? _transactions : null;
        transactions && transactions.filter((value) => value.orderCloseTime.toDate().getMonth() === month && value.orderCloseTime.toDate().getFullYear() === currentYear).map((value) => {
            const currentDayData = Moment(value.orderCloseTime.toDate()).format("D");
            const _tempOperator = _operators && _operators._operatorsList && _operators._operatorsList.find((_ops) => value.orderComment.trim().includes( _ops.name));
            if (retval[currentDayData]) {
                let operators = [];
                operators = new Array(retval[currentDayData]["operators"]);
                retval[currentDayData]["profits"] = Number(Number(retval[currentDayData]["profits"]) + Number(value.orderProfit)).toFixed(2);
                retval[currentDayData]["day"] = currentDayData;
                retval[currentDayData]["count"] = retval[currentDayData]["count"] + 1;
                operators.push(value.orderComment.trim());
                retval[currentDayData]["operators"] = [...retval[currentDayData]["operators"], _tempOperator && _tempOperator.name]
            } else {
                const __ops = _operators && _operators._operatorsList && _operators._operatorsList.find((_ops) => {
                    return value.orderComment.trim().includes(_ops.name) ? _ops.name : ""
                })
                let dailyData = {
                    day: currentDayData,
                    profits: Number(value.orderProfit),
                    count: 1,
                    operators: new Array(__ops)
                };
                retval[currentDayData] = dailyData;
            }
        })
        setOpenCallback(false);
        return retval

    }

    const preparePiezDataset = (rawData, selectedDate) => {
        let innerLabel = []
        let innerDataset = []
        rawData && rawData.map((value, key) => {
            if (value.name != "id" || value.name != "name") {
                innerLabel.push(value.name)
                innerDataset.push(value.value)
            }
        })
        const retVal = {
            labels: innerLabel,
            datasets: [
                {
                    label: 'Operators By month [' + getMonthString(selectedDate.getMonth()) + ']',
                    data: innerDataset,
                    borderAlign: 'center',
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 0,
                },

            ],
        }

        return retVal;
    }


    const preparechartTreeData = (chartData, selectedDate) => {
        const currentMonth = selectedDate.getMonth();
        let retval = [];
        let treeChatData
        const getValues = chartData && chartData[currentMonth] && getObjectValues(chartData[currentMonth]);
        chartData && chartData[currentMonth] && getObjectKeys(chartData[currentMonth]).map((value, key) => {
            if (value != "id" || value != "name") {
                retval.push(treeChatData = {
                    ...treeChatData,
                    name: value,
                    size: isNegativeNumber(Number(getValues[key])) ? Math.abs(Number(getValues[key])) * 10 : Number(getValues[key]),
                    value: Number(getValues[key])
                })
            }

        })
        return retval;
    }

    const [dailyData, setDailyData] = useState([]);

    useEffect( () => {
       /* let _transList = [];
        if(yearlyTransactions == undefined || isEmpty(yearlyTransactions)){
            //get data from DB to update chart
            console.log("getting in here..coz its emptyzzzzz", yearlyTransactions )
            const runner = async ()=>{
                const tradeHistoryCollectionRef = collection(db, "Trade_history_report");
                const q = query(tradeHistoryCollectionRef, where("userId", "==", _user && _user._userInfo[0] ? " " + _user._userInfo[0].User_Id.toString() : 0), where("orderCloseTime", ">=", new Date(new Date().getFullYear(), 0, 1)), orderBy("orderCloseTime", "desc"));
                const data = await getDocs(q);
                data.docs.map((doc) => {
                    _transList.push(({...doc.data(), id: doc.id}))
                })
                console.log("_transList is...", _transList)
                set_YearlyTransactions({_transList : _transList});
            }
             runner()
        }
        else{
            _transList= yearlyTransactions
            set_YearlyTransactions(yearlyTransactions)
            console.log("loadng prior one", _transList)
        }
    console.log("finally ....", _transList)*/
        //chartTreeData = prepData && preparechartTreeData(prepData, selectedDate);
        setDailyData(_transactions && prepareDailyDataByMonth(_transactions, selectedDate.getMonth(), _operators, selectedDate))
        dailyDataOutside = _transactions && prepareDailyDataByMonth(_transactions, selectedDate.getMonth(), _operators, selectedDate);
    }, [yearlyTransactions])


    const doFunc = (func) => {
        func()
    }


    return (
        <div>
            <div className="chart">
                <h3 className="chartTitle">{title} <span>monthly</span></h3>
                <div className="chart-Container">
                    {doFunc(() => {
                        dailyDataOutside = _transactions && prepareDailyDataByMonth(_transactions, selectedDate.getMonth(), _operators, selectedDate)
                    })}
                    {/*<ResponsiveContainer width={"33%"} aspect={2 / 2}>
                        <AreaChart width={400} height={250} data={_operators && _transactions && prepData}
                                   margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Tooltip/>
                            {
                                _operators && _operators._operatorsList && _operators._operatorsList.map(((value, index) => {
                                    return <Area type="monotone" dataKey={value.name} stroke={generateRandomColor()}
                                                 fillOpacity={0}/>
                                }))
                            }

                        </AreaChart>
                    </ResponsiveContainer>*/}
                    <ResponsiveContainer width={"44%"} aspect={3 / 2}>
                        <LineChart width={730} height={250} data={lineChartRetVal}
                                   margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <Tooltip itemStyle={{color: "red"}}/>
                            <Line type="monotone" dataKey="value" stroke="#8884d8"/>
                        </LineChart>
                    </ResponsiveContainer>

                    <div className={"pie-Container"}>
                        <ResponsiveContainer width={"100%"}>
                            <div className={"inner-container"}>
                                <span
                                    style={{fontSize: 10}}>{'Operators By month [' + getMonthString(selectedDate.getMonth()) + ']'}</span>
                                {!isEmpty(preparePiezDataset(lineChartRetVal, selectedDate)) ?
                                    <Pie data={preparePiezDataset(lineChartRetVal, selectedDate)} options={options}/> :
                                    <Pie data={piezData}/>}
                            </div>
                        </ResponsiveContainer>
                    </div>

                </div>
            </div>

            <div className="chart">
                <h3 className="chartTitle">{"Heat Map by Month"}
                    <span style={{color: "green"}}> [{getMonthString(selectedDate.getMonth())}]</span>
                </h3>
                <div className="heatmapcalender-Container">
                    <ResponsiveContainer>
                        <CalendarHeatmap
                            startDate={Moment(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)).format("YYYY-MM-DD")}
                            endDate={Moment(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)).format("YYYY-MM-DD")}
                            values={dateRange(selectedDate.getMonth(), selectedDate)}
                            tooltipDataAttrs={value => {
                                return {
                                    'data-tip': `${value && value.date && value.date.toString().slice(0, 10)} has count: ${
                                        value.count
                                    }`,
                                };
                            }}
                            onClick={value => alert(`Operators for : ${value && value.count && dailyDataOutside[value.count] ? value.date + "\n" + dailyDataOutside[value.count].operators : "no trades"}`)}
                            classForValue={value => {
                                if (!value) {
                                    return 'color-empty';
                                }

                                if (isNegativeNumber(dailyDataOutside && dailyDataOutside[value.count] && dailyDataOutside[value.count].profits))
                                    return `color-github-loss`;

                                if (dailyDataOutside && dailyDataOutside[value.count] && dailyDataOutside[value.count].profits && (!isNegativeNumber(dailyDataOutside && dailyDataOutside[value.count] && dailyDataOutside[value.count].profits)))
                                    return `color-github-win`;

                                return `color-github-others`
                                //return `color-github-${value.count}`;
                            }}
                            titleForValue={(value) => dailyDataOutside && value && value.count && dailyDataOutside[value.count] ? value.date + "\n" + "Trade count: " + dailyDataOutside[value.count].count + "\n" + "click for trade list" : ""}
                            showWeekdayLabels={true}
                            horizontal={false}
                            showOutOfRangeDays={true}
                            showMonthLabels={false}
                            gutterSize = {0}
                        transformDayElement={(element, value, index) =>
                                (
                                    <g>
                                        {element}
                                        <text
                                            x={element["props"].x+4}
                                            y={element["props"].y + 6}
                                            style={{
                                                fontSize: "3px",
                                                fill: "#fff"
                                            }}
                                        >
                                            {value ? value.count : ""}
                                        </text>
                                    </g>
                                )}
                        />
                    </ResponsiveContainer>
                </div>
            </div>

        </div>

    );
}



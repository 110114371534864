import AnalyticsFeaturedInfoGlobal from "../../components/featuredInfo/AnalyticsFeaturedInfoGlobal";
import Chart from "../../components/charts/AnalysisChart";
import {userData} from "../../DummyData";
import AnalyticsWidgetGlobalLg from "../../components/widgetLg/AnalyticsWidgetGlobalLg";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    getLastDayOfMonth,
    getObjectKeys,
    getObjectValues,
    isNegativeNumber,
    listEmpty
} from "../../components/utils/Utils";
import AnalyticsWidgetSm from "../../components/widgetSm/AnalyticsWidgetSm";
import ViewsDatePicker from "../../components/utils/DatePickerLocal";
import BeatLoader from "react-spinners/ClipLoader";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../../firebase";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import RingLoader from "react-spinners/RingLoader";

let lineChatData = {};
export default function AnalyticsGlobal() {
    const [loading, setLoading] = useState(true);
    const color = "#00ff0e";
    const _operators = useSelector((state) => state.operatorsInfo.value);
   // const _transactions = useSelector((state) => state.transactionInfo.value);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [_transactions, setTransactions] = useState([]);
    //const _dataByOperators = dataByOperators(_transactions && _transactions._transList, _operators && _operators._operatorsList, selectedDate);
    const [_dataByOperators, setDataByOperators] = useState([]);
    const [prepData, setPrepData] = useState([]);
    const [lineChartRetVal, setLineChartRetVal] = useState([])
    const [open, setOpen] = React.useState(false);
    const setOpenCallback = (value)=>setOpen(value);



    const prepareDate = (_operators, _transactions, selectedDate) => {
        setOpenCallback(true);
        let chartOnecData = [
            {
                id: 1,
                name: "Jan"
            },
            {
                id: 2,
                name: "Feb"
            },
            {
                id: 3,
                name: "Mar"

            }
            ,
            {
                id: 4,
                name: "Apr"

            }
            ,
            {
                id: 5,
                name: "May"

            }
            ,
            {
                id: 6,
                name: "Jun"

            }
            ,
            {
                id: 7,
                name: "Jul"

            }
            ,
            {
                id: 8,
                name: "Aug"

            }
            ,
            {
                id: 9,
                name: "Sep"

            }
            ,
            {
                id: 10,
                name: "Oct"

            },
            {
                id: 11,
                name: "Nov"

            }
            ,
            {
                id: 12,
                name: "Dec"

            }
        ]

        _transactions && _transactions.filter((value) => value.orderCloseTime.toDate().getFullYear() === selectedDate.getFullYear()).map((value, key) => {
            const transactionMonth = value.orderCloseTime.toDate().getMonth();
            const operator = value.orderComment.trim();
            const operatorAcutalName = _operators && _operators.find((value) => operator.toUpperCase().includes(value.name.toUpperCase()));
            //get keys of the object
            //if object key has the operator add the value... else add the operator and add the value
            if (operatorAcutalName) {
                if (getObjectKeys(chartOnecData[transactionMonth]).find((x) => operatorAcutalName.name.toUpperCase() === x.toUpperCase())) {
                    //chartOnecData[transactionMonth].[operator]
                    chartOnecData[transactionMonth] = {
                        ...chartOnecData[transactionMonth],
                        [operatorAcutalName.name]: Math.round(Number(chartOnecData[transactionMonth][operatorAcutalName.name] + Number(value.orderProfit)) + Number.EPSILON) * 100 / 100,
                    }

                } else
                    chartOnecData[transactionMonth] =
                        {
                            ...chartOnecData[transactionMonth],
                            [operatorAcutalName.name]: Number(value.orderProfit),
                        }

            }
        })
        setOpenCallback(true);
        return chartOnecData;

    }

    //by current month and current year
    const dataByOperators = (transactions, operators, selectedDate) => {
        setOpenCallback(true);
        let retval = {};
        transactions && transactions.filter((value) => value.orderCloseTime.toDate().getFullYear() === selectedDate.getFullYear()
            && value.orderCloseTime.toDate().getMonth() === selectedDate.getMonth()).map((value, key) => {
            //Reason for the math.round is to cut 20% of the comment value from behind, because of comments not exactly matching operator id values
            let _tempName = operators && operators.find((_value) => value.orderComment.trim().toUpperCase().includes(
                // _value.name.toUpperCase().replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').slice(0, (/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu.test(_value.name) ? value.orderComment.length - Math.round(value.orderComment.length * 0.33) : value.orderComment.length
                _value.name.toUpperCase().replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').slice(0, (/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu.test(_value.name) ? _value.name.toUpperCase().replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').length - Math.round(_value.name.toUpperCase().replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').length * 0.45) : _value.name.toUpperCase().replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').length

                ))))
            const operatorAcutalName = value.orderComment && _tempName ? _tempName.name : undefined;
            if (operatorAcutalName) {
                if (retval[operatorAcutalName]) {
                    retval[operatorAcutalName] =
                        {
                            ...retval[operatorAcutalName],
                            count: retval[operatorAcutalName]["count"] + 1,
                            profits: retval[operatorAcutalName]["profits"] + Number(value.orderProfit),
                            mostTradedSymbol: {
                                ...retval[operatorAcutalName]["mostTradedSymbol"],
                                [value.orderSymbol.trim()]: retval[operatorAcutalName]["mostTradedSymbol"][value.orderSymbol.trim()] ? Number(retval[operatorAcutalName]["mostTradedSymbol"][value.orderSymbol.trim()]) + 1 : 1,
                            },
                            totalBuys: value.orderType === "BUY" ? retval[operatorAcutalName]["totalBuys"] + 1 : retval[operatorAcutalName]["totalBuys"],
                            totalSells: value.orderType === "SELL" ? retval[operatorAcutalName]["totalSells"] + 1 : retval[operatorAcutalName]["totalSells"],
                            totalSellWins: value.orderType.trim() === "SELL" && !isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalSellWins"] + 1 : retval[operatorAcutalName]["totalSellWins"],
                            totalSellLoss: value.orderType.trim() === "SELL" && isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalSellLoss"] + 1 : retval[operatorAcutalName]["totalSellLoss"],
                            totalBuyWins: value.orderType.trim() === "BUY" && !isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalBuyWins"] + 1 : retval[operatorAcutalName]["totalBuyWins"],
                            totalBuyLoss: value.orderType.trim() === "BUY" && isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalBuyLoss"] + 1 : retval[operatorAcutalName]["totalBuyLoss"],
                            totalWins: !isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalWins"] + 1 : retval[operatorAcutalName]["totalWins"],
                            totalLoss: isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalLoss"] + 1 : retval[operatorAcutalName]["totalLoss"],
                        }

                } else {
                    retval =
                        {
                            ...retval,
                            [operatorAcutalName]: {
                                count: 1,
                                profits: Number(value.orderProfit),
                                mostTradedSymbol: {
                                    [value.orderSymbol.trim()]: 1
                                },
                                totalBuys: value.orderType.trim() === "BUY" ? 1 : 0,
                                totalSells: value.orderType.trim() === "SELL" ? 1 : 0,
                                totalSellWins: value.orderType.trim() === "SELL" && !isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalSellLoss: value.orderType.trim() === "SELL" && isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalBuyWins: value.orderType.trim() === "BUY" && !isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalBuyLoss: value.orderType.trim() === "BUY" && isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalWins: !isNegativeNumber(value.orderProfit) ? 1 : 0,
                                totalLoss: isNegativeNumber(value.orderProfit) ? 1 : 0,
                            }
                        }
                }

            }


        })

        setOpenCallback(false);

        return retval;

    }

    useEffect(() => {
        setLoading(true);
        setOpenCallback(true);
        //load transactions for month for all....
        runner(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1), new Date(selectedDate.getFullYear(),  selectedDate.getMonth(), getLastDayOfMonth(selectedDate.getFullYear(), selectedDate.getMonth())));
        setOpenCallback(false);
        //setDataByOperators (dataByOperators(_transactions && _transactions._transList, _operators && _operators._operatorsList, selectedDate));
    }, [selectedDate])

    const runner = async (startDate, endDate) => {
        let _transList = [];
        const tradeHistoryCollectionRef = collection(db, "Trade_history_report");
        const q = query(tradeHistoryCollectionRef, where("orderCloseTime", ">=", startDate), where("orderCloseTime", "<=", endDate), orderBy("orderCloseTime", "desc"));
        const data = await getDocs(q);
        data.docs.map((doc) => {
            _transList.push(({...doc.data(), id: doc.id}))
        })
        //console.log("_transList", _transList)
        const _prepareData= prepareDate(_operators && _operators._operatorsList, _transList, selectedDate)
        //console.log("_prepareData", _prepareData)
        setPrepData(_prepareData)
        setDataByOperators (dataByOperators(_transList, _operators && _operators._operatorsList, selectedDate));
        setLineChartRetVal(prepareLineChartDataByOperatorAndCurrentMonth(_prepareData, selectedDate));
        setTransactions({_transList: _transList})
        setLoading(false);
    }



    const higestGrossing = () => {
        let retVal = lineChartRetVal ? lineChartRetVal[0] : [];
        lineChartRetVal && lineChartRetVal.map((_value) => {
            retVal = Number(_value["value"]) >= Number(retVal["value"]) ? _value : retVal
            return retVal
        })

        return retVal
    }
    const handleCallback = (childData) => {
        setSelectedDate(childData)
    }

    return (
        <div className="home">

           {/* {
                _transactions && !listEmpty(_transactions._transList) && loading ? setLoading(false) : ""
            }*/}
            {
                loading ?
                    <div className="sweet-loading analytics">
                        <BeatLoader
                            color={color}
                            loading={loading}
                            size={60}/>
                    </div>
                    :
                    <>
                        <div style={{display: "none"}}>
                            <Modal
                                open={open}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box >
                                    <Typography style={{textAlign: "center", fontStyle: "bold", fontFamily: "sans-serif"}}
                                                id="modal-modal-title" variant="h6"
                                                component="h2">
                                        <div className="sweet-loading component-loader">
                                            <RingLoader
                                                color={color}
                                                loading={true}
                                                size={60}/>
                                        </div>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                        <ViewsDatePicker text={"Global Operators Analysis"} function={handleCallback}/>
                        <AnalyticsFeaturedInfoGlobal lineChartRetVal={lineChartRetVal} transactions={_transactions}
                                                     prepData={prepData}
                                                     dataByOperators={{_dataByOperators}}
                                                     higestGrossing={higestGrossing()} setOpenCallback={setOpenCallback}/>
                        <Chart data={userData} _operators={_operators} _transactions={_transactions && _transactions}
                               lineChartRetVal={lineChartRetVal} title="Operator Stats "
                               prepData={prepData && prepData} selectedDate={selectedDate}
                               dataKey="Active User" setOpenCallback={setOpenCallback}/>
                        <div className="homeWidgets">
                            <AnalyticsWidgetGlobalLg dataByOperators={_dataByOperators}/>
                            <AnalyticsWidgetSm dataByOperators={_dataByOperators}/>
                        </div>
                    </>
            }
        </div>
    );
}




const prepareLineChartDataByOperatorAndCurrentMonth = (chartOneData, selectedDate) => {
    const currentMonth = selectedDate.getMonth();
    let retval = [];
    const getValues = getObjectValues(chartOneData[currentMonth]);
    chartOneData && getObjectKeys(chartOneData[currentMonth]).map((value, key) => {
        if (value === "name" || value === "id")
            return
        else
            retval.push(lineChatData = {
                ...lineChatData,
                name: value,
                value: getValues[key]
            })
    })

    return retval;
}



import {createSlice} from "@reduxjs/toolkit"
const initialState = {};
export const operatorsSlice = createSlice({
    name: "operatorsInformation",
    initialState: initialState,
    reducers: {
        operatorsInfo: (state, action) => {
            state.value = action.payload; ;
        },
    }
});


export const {operatorsInfo} = operatorsSlice.actions;
export default operatorsSlice.reducer;
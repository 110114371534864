import React, {useEffect, useState} from 'react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {useHistory, useLocation} from "react-router-dom";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {auth, db} from "../../firebase";
import {addDoc} from "@firebase/firestore";
import axios from "axios";


const ProductDisplay = () => (
    <section>
        <div className="product">

        </div>
        <form
            action={`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session/${base64_decode(localStorage.getItem("email"))}`}
            method="POST">

            <section id={"subscription_module"}>
                <h2 className={"sub_header"}>Subscription Plan</h2>
                <div className={"sub_header_mini"}>Please select your preferred plan</div>
                <div className={"parent_sub_plans"}>
                    <div className={"container"}>
                        <div className="lifetime">
                            <div className="Lifetime Pro_title subscription_title">Monthly Sub</div>
                            <div className="Lifetime Pro_price subscription_price">$20.00</div>
                            <div className="Lifetime Pro_desc subscription_desc"><p className="font_7"><span
                                className="color_11"><span>One MT4 trading accounts</span></span></p>
                                <p className="font_7"><span
                                    className="color_11"><span>Full Web analytics</span></span></p>
                                <p className="font_7"><span
                                    className="color_11"><span>Unlimited Signal Operators</span></span>
                                </p>
                                <p className="font_7"><span className="color_11"><span>Unlimited Telegram Signals</span></span>
                                </p>
                                <p className="font_7"><span
                                    className="color_11"><span>All access to bot control</span></span>
                                </p>
                                <p className="font_7"><span className="color_11">Lifetime Support</span></p></div>
                            <button name="lookup_key" value={"price_1LDUQGGP0dRnEGYUVwiUovd6"} className={"buttonTest"}
                                    onClick={() => localStorage.setItem("productId", base64_encode("price_1LDUQGGP0dRnEGYUVwiUovd6"))} type="submit"
                                    id={"price_1LDUQGGP0dRnEGYUVwiUovd6"}>
                                Buy Now!
                            </button>
                        </div>
                    </div>
                    <div className={"container"}>
                        <div className="lifetime">
                            <div className="Lifetime Pro_title subscription_title">Yearly Sub</div>
                            <div className="Lifetime Pro_price subscription_price">$209.99</div>
                            <div className="Lifetime Pro_desc subscription_desc">
                                <p className="font_7"><span
                                    className="color_11"><span>One MT4 trading accounts</span></span></p>
                                <p className="font_7"><span
                                    className="color_11"><span>Full Web analytics</span></span></p>
                                <p className="font_7"><span
                                    className="color_11"><span>Unlimited Signal Operators</span></span>
                                </p>
                                <p className="font_7"><span className="color_11"><span>Unlimited Telegram Signals</span></span>
                                </p>
                                <p className="font_7"><span
                                    className="color_11"><span>All access to bot control</span></span>
                                </p>
                                <p className="font_7"><span className="color_11">Lifetime Support</span></p>

                            </div>
                            <button name="lookup_key" value={"price_1LDUMCGP0dRnEGYUjFLxNdp1"} className={"buttonTest"}
                                    onClick={() => localStorage.setItem("productId", base64_encode("price_1LDUMCGP0dRnEGYUjFLxNdp1"))} type="submit"
                                    id={"price_1LDUMCGP0dRnEGYUjFLxNdp1"}>
                                Buy Now!
                            </button>
                            {/* <a type={"submit"} className="view_more Lifetime Pro_button">Buy Now!</a>*/}
                        </div>
                    </div>
                </div>
            </section>
            {/* Add a hidden field with the lookup_key of your Price */}
            {/*<input type="hidden" name="lookup_key" value="price_1LDUMCGP0dRnEGYUjFLxNdp1"/>
            <button id="checkout-and-portal-button" type="submit">
                Checkout
            </button>*/}
        </form>
    </section>
);

const SuccessDisplay = ({sessionId}) => {
    return (
        <section>
            <div className="product Box-root">
                <Logo/>
                <div className="description Box-root">
                    <h3>Subscription to starter plan successful!</h3>
                </div>
            </div>
           {/* <form action="/create-portal-session" method="POST">
                <input
                    type="hidden"
                    id="session-id"
                    name="session_id"
                    value={sessionId}
                />
                <button id="checkout-and-portal-button" type="submit">
                    Manage your billing information
                </button>
            </form>*/}
        </section>
    );
};

const Message = ({message}) => (
    <section>
        <p>{message}</p>
    </section>
);

export default function ProductCheckout() {
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');
    const location = useLocation();
    const history = useHistory()
    //const {signup} = useAuth();

    const signUp = async (email, password) => {

        const shotMail = async () => {
            await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BACKEND_URL + "/sendEmailApi/welcome"}`,
                data: {
                    "fromEmail": "support@dnainvesting.net",
                    "toEmail": base64_decode(email),
                    "emailSubject": "DnaInvesing Onboarding..",
                    "apiKey": "login to your account--> settings (your API Key will be visible)",
                    "text": "none for now"
                },
                headers: {
                    "x-api-key": `$2a$10$9G7DSgXz4jc5GclJTBJhN.dozQb9By8.HDkbMVIrgs9YN1x0CnNIy`
                }
            })
        }

        await auth.createUserWithEmailAndPassword(base64_decode(email), base64_decode(password)).then(async () => {
            shotMail();
        });
    }

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (location && location.prevPath === "/signup") {
            localStorage.setItem("email", base64_encode(location.state.signupData.email));
            localStorage.setItem("password", base64_encode(location.state.signupData.password));
        }

        if (location && location.type === "renewal") {
            localStorage.setItem("email", base64_encode(location.renew.email));
            localStorage.setItem("renew_email", base64_encode(location.renew.email));
        }

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage(
                "Order canceled -- will continue to shop now, checkout when you're ready."
            );
            localStorage.clear();
            history.push("/welcome")
        }
        if (location && location.prevPath == undefined && location && location.renew == undefined && !query.get('success') &&  !query.get('canceled')) {
            setSuccess(false);
            setMessage(
                "Session expired - Signup or Login to renew"
            );
            localStorage.clear();
            //history.push("/welcome")
        }
    }, [sessionId]);
    if (!success && message === '') {

        return <ProductDisplay/>;
    } else if (success && sessionId !== '') {
        const email = localStorage.getItem("email")
        const password = localStorage.getItem("password")
        const renewal = localStorage.getItem("renew_email")
        const productId = localStorage.getItem("productId")
        let daysToAdd = base64_decode(productId) === "price_1LDUQGGP0dRnEGYUVwiUovd6" ? 30 : base64_decode(productId) === "price_1LDUMCGP0dRnEGYUjFLxNdp1" ? 360 : 0

        var date = new Date();

        if (email && password) {

            //sign user up
            signUp(email, password);
            //add new subscription
            const addSub = async () => {
                await addDoc(collection(db, "Subscription_Information"), {
                    Active_Subscription: true,
                    Replication_Count: 10,
                    Replication_Status: true,
                    Signal_Owner_Id: 0,
                    Subscription_End: new Date(date.setDate(date.getDate() + daysToAdd)),
                    Subscription_ID: 0,
                    Subscription_Start: new Date(),
                    User_Id: 0,
                    email: base64_decode(email)
                });
            }
            addSub();
            localStorage.clear();
            //display success page and redirect to home
           /* setTimeout(() => {
                history.push("/")
            }, 200000)*/

        } else if (renewal) {
            // search Existing user and update their subscription
            const getUserSubInfo = async () => {
                //find the email of the person
                const usersSubInfoCollectionRef = collection(db, "Subscription_Information");
                const data = await getDocs(usersSubInfoCollectionRef);
                data.docs.map(async (_data) => {

                    if (_data.data().email === base64_decode(renewal)) {
                        const userUpdateRef = doc(db, "Subscription_Information", _data.id)
                        await updateDoc(userUpdateRef, {Subscription_End: new Date(date.setDate(date.getDate() + daysToAdd))}).catch((e) => console.error(e));
                    }
                })
            }
            getUserSubInfo();
            localStorage.clear();
        }
        localStorage.clear();
        return <SuccessDisplay sessionId={sessionId}/>;
    } else {
        return <Message message={message}/>;
    }
}


const Logo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="14px"
        height="16px"
        viewBox="0 0 14 16"
        version="1.1"
    >
        <defs/>
        <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="0-Default"
                transform="translate(-121.000000, -40.000000)"
                fill="#E184DF"
            >
                <path
                    d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
                    id="Pilcrow"
                />
            </g>
        </g>
    </svg>
);
import {createSlice} from "@reduxjs/toolkit"

const initialState = {};
export const transactionsInfoSlice = createSlice({
    name: "transactionInformation",
    initialState: initialState,
    reducers: {
        transactionInfo: (state, action) => {
            state.value = action.payload; ;
        }
    }
});

export const {transactionInfo} = transactionsInfoSlice.actions;


export default transactionsInfoSlice.reducer;




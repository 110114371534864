import React, {useRef, useState} from "react"
import {Alert, Button, Card, Form} from "react-bootstrap"
import {useAuth} from "../contexts/AuthContext"
import {Link, useHistory} from "react-router-dom"
import {auth, db} from "../firebase"
import {useAuthState} from "react-firebase-hooks/auth";
import Toolbar from "@mui/material/Toolbar";
import {collection, getDocs} from "firebase/firestore";

export default function Signup() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const {signup} = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [user] = useAuthState(auth);

    async function handleSubmit(e) {
        e.preventDefault()
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match")
        }
        if (emailRef.current === "" || !emailRef.current.value) {
            return setError("Please enter a valid email")
        }

        try {
            setError("")
            setLoading(true)
            let signupData = {
                email: emailRef.current.value,
                password: passwordRef.current.value
            }
            //check if email exist already before proceeding
            const usersInfoCollectionRef = collection(db, "User_Information");
            const getUserInfo = async () => {
                let retvalue = false
                const data = await getDocs(usersInfoCollectionRef);
                data.docs.map((_data) => {
                    if (_data.data().email === signupData.email) {
                        retvalue = true;
                        return
                    }
                })

                return retvalue;
            }

            if (await getUserInfo()) {
                return setError("Email already exist, Please Login instead");
            }

            history.push({
                pathname: "/productCheckout",
                prevPath: "/signup",
                state: {
                    signupData: signupData
                }
            })


            //until successful payment confirmation
             await signup(emailRef.current.value, passwordRef.current.value).then(() => {});
            //history.push("/")
            //history.push("/")
        } catch(e) {
            console.error(e);
            setError("Failed to create an account");
        }

        setLoading(false)
    }

    return (
        <div className={"loginClassbody"}>
            <Card>
                <Card.Body>
                    <Toolbar className="login"> <h2 className="text-center mb-4 login">Sign Up</h2></Toolbar>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <i  className="fa fa-envelope envelopeLogin"/>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <i className="fa fa-lock envelopeLogin"/>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group id="password-confirm">
                            <Form.Label>Password Confirmation</Form.Label>
                            <i className="fa fa-lock envelopeLogin"/>
                            <Form.Control type="password" ref={passwordConfirmRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100 loginButton" type="submit">
                            Sign Up
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Already have an account? <Link to="/login" >Log In</Link>
            </div>
        </div>
    )
}
import {createSlice} from "@reduxjs/toolkit"

const initialState = {};
export const transactionsInfoSliceMild = createSlice({
    name: "transactionInformationMild",
    initialState: initialState,
    reducers: {
        transactionInfoMild: (state, action) => {
            state.value = action.payload; ;
        }
    }
});

export const {transactionInfoMild} = transactionsInfoSliceMild.actions;

export default transactionsInfoSliceMild.reducer;





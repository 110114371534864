import {createSlice} from "@reduxjs/toolkit"

const initialState = {};

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        authUser: (state, action) => {
            state.value = action.payload;
        },
    }
});


export const { authUser , userDash} = userSlice.actions;

export default userSlice.reducer;






import "./operatormonthlyprofiabilitychart.css";
import * as React from "react";
import {Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const data = [
    {
        name: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100
    }
];

export default function DrawDownChartMonthly({transactions: transactions}) {
    let retVal = [];
    let index_position = 0;
    transactions && transactions.sort((a, b) => a.orderCloseTime.toDate() - b.orderCloseTime.toDate()).map((transaction) => {
        retVal[index_position] =
            {
                name: index_position + 1,
                amount: index_position == 0 ? Number(String(transaction.orderProfit).trim()) : retVal[index_position - 1]["amount"] + Number(String(transaction.orderProfit).trim())
            }
        index_position++;
    })
    return (
        <ResponsiveContainer className={"operator_monthly_profibility"} width={"80%"} height="25%">
            <LineChart
                width={800} height={300} data={retVal}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <Line type="monotone" dataKey="amount" stroke="#8884d8" strokeWidth={2}/>
                <XAxis label={<Label offset={-3} position="insideBottom">number of trades</Label>} dataKey="name"/>
                <YAxis label={<Label color={"blue"} angle={-90} position="insideLeft">amount</Label>}></YAxis>
                <Tooltip active={true} cursor={{stroke: 'green', strokeWidth: 2}}/>
            </LineChart>
        </ResponsiveContainer>


    );
}

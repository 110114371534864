import {v4 as uuid} from 'uuid';
import {hash} from 'bcryptjs';

const bcrypt = require('bcryptjs');

const generateAPIKey = uuid;

export const createAPIKeyFromUuid = async (email) => {
    const apiKey = generateAPIKey();
    let hashedApiKeyNhashedEmail;
    await hash(apiKey + email, 10).then((result) => {
        hashedApiKeyNhashedEmail = result
    });
    return hashedApiKeyNhashedEmail;
}


export const compareHashWithRawText = (raw, hashedValue) => {

    return bcrypt.compareSync(raw, hashedValue);
}

export const validateAPIKey = (req, res, next)=>{
    const { apiLimit } = req.query;
    let apiKey = req.headers['X-API-Key'] || req.headers['x-api-key'];

    if (!apiKey) {
        throw new Error('X-API-Key Header doesn\'t exist');
    }

}
import "./widgetLg.css";
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {getObjectKeys} from "../utils/Utils"
import TableSortLabel from "@mui/material/TableSortLabel";

export default function AnalyticsWidgetGlobalLg({transactions: transactions, dataByOperators: dataByOperators}) {
    const Button = ({type}) => {
        return <button className={"widgetLgButton " + type}>{type}</button>;
    };
    return (
        <div className="widgetLg">
            <div><h3 className="widgetLgTitle"> Top grossing transactions <span className="widgetLgTitle span">by Operator</span>
            </h3></div>

            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>Operator</TableCell>
                            <TableCell align="right">Trade&nbsp;(s)</TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    direction={'desc'}
                                    onClick={(e) => {
                                    }}
                                >
                                    {"Profit(s)"}

                                    <Box component="span">
                                    </Box>

                                </TableSortLabel></TableCell>
                            <TableCell align="right">Win&nbsp;(s)</TableCell>
                            <TableCell align="right">Loss&nbsp;(es)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows(dataByOperators).map((row) => (
                            <Row dataByOperators={dataByOperators} key={row.name} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function Row(props) {
    const {row, dataByOperators} = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>value</TableCell>
                                        <TableCell align="right">Total trades</TableCell>
                                        <TableCell align="right"> Value from trades</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            {"Total Wins:"}
                                        </TableCell>
                                        <TableCell>{dataByOperators[row.name].totalWins}</TableCell>
                                        <TableCell align="right"> {dataByOperators[row.name].count}</TableCell>
                                        <TableCell align="right">
                                            {dataByOperators[row.name].count - dataByOperators[row.name].totalWins}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={2}>
                                        <TableCell component="th" scope="row">
                                            {"Total Losses:"}
                                        </TableCell>
                                        <TableCell>{dataByOperators[row.name].totalLoss}</TableCell>
                                        <TableCell align="right"> {dataByOperators[row.name].count}</TableCell>
                                        <TableCell align="right">
                                            {dataByOperators[row.name].count - dataByOperators[row.name].totalLoss}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={3}>
                                        <TableCell component="th" scope="row">
                                            {"Total Buy Wins:"}
                                        </TableCell>
                                        <TableCell>{dataByOperators[row.name].totalBuyWins}</TableCell>
                                        <TableCell align="right"> {dataByOperators[row.name].count}</TableCell>
                                        <TableCell align="right">
                                            {dataByOperators[row.name].count - dataByOperators[row.name].totalBuyWins}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={4}>
                                        <TableCell component="th" scope="row">
                                            {"Total Sell Wins:"}
                                        </TableCell>
                                        <TableCell>{dataByOperators[row.name].totalSellWins}</TableCell>
                                        <TableCell align="right"> {dataByOperators[row.name].count}</TableCell>
                                        <TableCell align="right">
                                            {dataByOperators[row.name].count - dataByOperators[row.name].totalSellWins}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={5}>
                                        <TableCell component="th" scope="row">
                                            {"Total Sell Loss:"}
                                        </TableCell>
                                        <TableCell>{dataByOperators[row.name].totalSellLoss}</TableCell>
                                        <TableCell align="right"> {dataByOperators[row.name].count}</TableCell>
                                        <TableCell align="right">
                                            {dataByOperators[row.name].count - dataByOperators[row.name].totalSellLoss}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={6}>
                                        <TableCell component="th" scope="row">
                                            {"Total Buy Loss:"}
                                        </TableCell>
                                        <TableCell>{dataByOperators[row.name].totalBuyLoss}</TableCell>
                                        <TableCell align="right"> {dataByOperators[row.name].count}</TableCell>
                                        <TableCell align="right">
                                            {dataByOperators[row.name].count - dataByOperators[row.name].totalBuyLoss}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={7}>
                                        <TableCell component="th" scope="row">
                                            {"Total Buy :"}
                                        </TableCell>
                                        <TableCell>{dataByOperators[row.name].totalBuys}</TableCell>
                                        <TableCell align="right"> {dataByOperators[row.name].count}</TableCell>
                                        <TableCell align="right">
                                            {dataByOperators[row.name].count - dataByOperators[row.name].totalBuys}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={8}>
                                        <TableCell component="th" scope="row">
                                            {"Total Sell :"}
                                        </TableCell>
                                        <TableCell>{dataByOperators[row.name].totalSells}</TableCell>
                                        <TableCell align="right"> {dataByOperators[row.name].count}</TableCell>
                                        <TableCell align="right">
                                            {dataByOperators[row.name].count - dataByOperators[row.name].totalSells}
                                        </TableCell>
                                    </TableRow>

                                    {/*  {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {"total wins:" /* {historyRow.date}*!/
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}*/}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

/*const rows = [
    createData('1000pips builder', 159, 6.0, 24, 4.0, 3.99),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
    createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
    createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];*/


function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],
    };
}


const rows = (dataByOperators) => {
    let retVal = [];
    let getOperatorKeys = dataByOperators && getObjectKeys(dataByOperators);
    getOperatorKeys.forEach((operator => {
        retVal.push(createData(operator, dataByOperators[operator]["count"], Number(dataByOperators[operator]["profits"]).toFixed(2), dataByOperators[operator]["totalWins"], dataByOperators[operator]["totalLoss"], 3.99))
    }))
    return retVal.sort((a, b) => b.fat - a.fat);
}
import "./operatormonthlyprofiabilitychart.css"
import React from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Text,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {isNegativeNumber} from "../../components/utils/Utils";

const data = [
    {
        name: "1",
        win: 4000,
        loss: 2400,
        amt: 2400
    },
    {
        name: "2",
        win: -3000,
        loss: 1398,
        amt: 2210
    },
    {
        name: "3",
        win: -2000,
        loss: -9800,
        amt: 2290
    },
    {
        name: "4",
        win: 2780,
        loss: 3908,
        amt: 2000
    },
    {
        name: "5",
        win: -1890,
        loss: 4800,
        amt: 2181
    },
    {
        name: "6",
        win: 2390,
        loss: -3800,
        amt: 2500
    },
    {
        name: "7",
        win: 3490,
        loss: 4300,
        amt: 2100
    }
];

export default function OperatorMonthlyProfitabilityChart({transactions}) {
    let retVal = [];
    transactions && transactions.map((value, key) => {
        if (retVal[value.orderCloseTime.toDate().getDate()] && retVal[value.orderCloseTime.toDate().getDate()] != undefined) {
            let _win = !isNegativeNumber(parseFloat(value.orderProfit)) && !isNaN(parseFloat(value.orderProfit)) ? retVal[value.orderCloseTime.toDate().getDate()].win + parseFloat(value.orderProfit) : retVal[value.orderCloseTime.toDate().getDate()].win;
            let _loss = isNegativeNumber(parseFloat(value.orderProfit)) && !isNaN(parseFloat(value.orderProfit)) ? retVal[value.orderCloseTime.toDate().getDate()].loss + parseFloat(value.orderProfit) : retVal[value.orderCloseTime.toDate().getDate()].loss;
            retVal[value.orderCloseTime.toDate().getDate()] = {
                name: value.orderCloseTime.toDate().getDate(),
                win: _win,
                loss: _loss,
                amt: parseFloat((retVal[value.orderCloseTime.toDate().getDate()].amt) + parseFloat(value.orderProfit))
            }
        } else {
            let _win = !isNegativeNumber(parseFloat(value.orderProfit)) ? parseFloat(value.orderProfit) : 0.0;
            let _loss = isNegativeNumber(parseFloat(value.orderProfit)) ? parseFloat(value.orderProfit) : 0.0;
            retVal[value.orderCloseTime.toDate().getDate()] = {
                name: value.orderCloseTime.toDate().getDate(),
                win: _win,
                loss: _loss,
                amt: parseFloat(_win + _loss)
            }
        }

    });

    return (

        <ResponsiveContainer className={"operator_monthly_profibility"} width={"80%"}  maxHeight={"25%"} height="20%">
            <BarChart
                barSize={25}
                barGap={4}
                barCategoryGap={50}
                width={500}
                height={200}
                data={retVal.filter(x => x !== null)}
                stackOffset="sign"
                className={"barchartProfibility"}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >

                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name">days</XAxis>
                <YAxis/>
                <Tooltip/>
                <Legend iconSize={10} verticalAlign={"bottom"} />
                <ReferenceLine y={0} stroke="#000"/>
                <Bar dataKey="win" fill="#A9FE74" stackId="stack"/>
                <Bar dataKey="loss" fill="#FF8787" stackId="stack"/>
            </BarChart>
        </ResponsiveContainer>
    );
}

import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ViewsDatePicker from "../../components/utils/DatePickerLocal";
import Grid from "@material-ui/core/Grid";
import {styled} from '@mui/material/styles';
import Paper from "@material-ui/core/Paper";
import Button from '@mui/material/Button';
import Divider from "@material-ui/core/Divider";
import SearchBar from "./SearchBar";
import OperatorMonthlyProfitabilityChart from "./OperatorMonthlyProfitabilityChart";
import MultiColorProgressBar from "./MultiColorProgressBar";
import OperatorsTradeHistory from "./OperatorsTradeHistory";
import {getLastDayOfMonth, getObjectKeys, getObjectValues, isNegativeNumber} from "../../components/utils/Utils";
import DrawDownChartMonthly from "./DrawDownChartMonthly";
import ProfitabilityPane from "./ProfitabilityPane";
import BeatLoader from "react-spinners/ClipLoader";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../../firebase";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import RingLoader from "react-spinners/RingLoader";


let lineChatData = {};
const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontFamily: "source-code-pro, serif",
    minHeight: "55px",
    color: theme.palette.text.secondary,
}));
const ItemLeftTextAlign = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));
export default function OperatorAnalytics() {
    const [loading, setLoading] = useState(true);
    const color = "#00ff0e";
    const [underLineMark, setUnderLineMark] = useState("profitability")
    const [operatorNames, setOperatorNames] = useState([])
    const [dataResult, setDataResult] = useState([]);
    const [searchBarValuee, setSearchBarValuee] = useState("Enter Operator Name")
    const [selectedOperatorName, setSelectedOperatorName] = useState("GENERAL PLACEMENT")
    const _user = useSelector((state) => state.userInfo.value);
    const _operators = useSelector((state) => state.operatorsInfo.value);
    const [selectedDate, setSelectedDate] = useState(new Date());
    //let prepData = prepareDate(_operators && _operators._operatorsList, _transactions && _transactions._data, selectedDate)
    const [monthlyTractionsList, setMonthlyTractionsList] = useState([]);
    const [yearlyTransactionList, setYearlyTransactionList] = useState([]);
    const [_transactions, setTransactions] = useState([]);
    const [open, setOpen] = React.useState(false);
    //const handleClose = () => setOpen(false);

    const setOpenCallback = (value)=>setOpen(value);

    const handleCallback = (childData) => {
             // when new year is spotted
        if (selectedDate.getFullYear() != childData.getFullYear() && selectedDate.getMonth() != childData.getMonth() || selectedDate.getFullYear() == childData.getFullYear() && selectedDate.getMonth() != childData.getMonth() || selectedDate.getFullYear() != childData.getFullYear() && selectedDate.getMonth() == childData.getMonth()) {
            runner(new Date(childData.getFullYear(), childData.getMonth(), 1), new Date(childData.getFullYear(), childData.getMonth(), getLastDayOfMonth(childData.getFullYear(), childData.getMonth())))
        } else {
            prepTransactionDataMonthlyData(childData, selectedOperatorName, _transactions)
        }

        setSelectedDate(childData)
    }

    const setOperatorName = () => {
        setOperatorNames([]);
        let retVal = [];
        _operators && _operators._operatorsList && _operators._operatorsList.map((ops) => {
            retVal.push(ops.name)
        })
        setOperatorNames(retVal.sort((a, b,) => a < b));
    }

    const setSelectedOperator = (selectedValue) => {
        prepTransactionDataMonthlyData(selectedDate, selectedValue, _transactions)
        setSelectedOperatorName(selectedValue);
        setSearchBarValuee(selectedValue)
    }


    const prepTransactionDataMonthlyData = (selectedDate, selectedOperatorName, xtransactions) => {
        setOpenCallback(true);
        const data = xtransactions && xtransactions._data && xtransactions._data.filter((value) => value.orderCloseTime.toDate().getFullYear() === selectedDate.getFullYear()
            && value.orderCloseTime.toDate().getMonth() === selectedDate.getMonth() && value.orderComment.includes(selectedOperatorName.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').slice(0, selectedOperatorName.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').length - (0.45 * selectedOperatorName.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').length))))
        setMonthlyTractionsList(data);
        setOpenCallback(false)
    }

    const runner = async (startDate, endDate) => {
        setOpenCallback(true)
        let _transList = [];
        const tradeHistoryCollectionRef = collection(db, "Trade_history_report");
        const q = query(tradeHistoryCollectionRef, where("orderCloseTime", ">=", startDate), where("orderCloseTime", "<=", endDate), orderBy("orderCloseTime", "desc"));
        const data = await getDocs(q);
        data.docs.map((doc) => {
            _transList.push(({...doc.data(), id: doc.id}))
        })
        prepTransactionDataMonthlyData(startDate, selectedOperatorName, {_data: _transList})
        setTransactions({_data: _transList})
        setYearlyTransactionList({_data: _transList})
        setOpenCallback(false)
    }

    useEffect(async () => {
        runner(new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), 11, 31));
        setOperatorName();
        setLoading(false);
    }, [])

    return (
        <div className="home">
            {
                loading ?
                    <div className="sweet-loading-operatorAnalysis">
                        <BeatLoader
                            color={color}
                            loading={loading}
                            size={60}/>
                    </div>
                    :
                    <>
                        <div style={{display: "none"}}>
                            <Modal
                                open={open}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box >
                                    <Typography style={{textAlign: "center", fontStyle: "bold", fontFamily: "sans-serif"}}
                                                id="modal-modal-title" variant="h6"
                                                component="h2">
                                        <div className="sweet-loading component-loader">
                                            <RingLoader
                                                color={color}
                                                loading={true}
                                                size={60}/>
                                        </div>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                        <ViewsDatePicker text={"Select Operator & Month "} function={handleCallback}/>
                        <Divider/>
                        <Grid className={"firstfone"} container spacing={2}>
                            <Grid className={"operstorsector"} item xs={12}>
                                <itemlefttextalign>
                                    <SearchBar placeholder={searchBarValuee} data={operatorNames}
                                               setSelectedOperator={setSelectedOperator}/>
                                </itemlefttextalign>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Item>
                                    <Button onClick={() => setUnderLineMark("profitability")} id={"profitability"}
                                            style={{
                                                textTransform: "lowercase",
                                                borderBottom: ` ${underLineMark === "profitability" ? "0.5px dotted" : ""} `,
                                                fontFamily: "source-code-pro, serif",
                                                fontSize: "15px",
                                                color: "rgb(95 97 98)"
                                            }}
                                            variant="text">profitability</Button>
                                </Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item><Button style={{cursor: "unset"}}
                                              variant="text"> </Button></Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item> <Button
                                    onClick={() => setUnderLineMark("drawdown")} id={"drawdown"} style={{
                                    textTransform: "lowercase",
                                    borderBottom: ` ${underLineMark === "drawdown" ? "0.5px dotted" : ""} `,
                                    fontFamily: "source-code-pro, serif",
                                    fontSize: "15px",
                                    color: "rgb(95 97 98)"
                                }}
                                    variant="text">Drawdown</Button></Item>
                            </Grid>
                        </Grid>
                        <Divider/>

                        {underLineMark != "drawdown" && (<>
                            <div className={"chartTitle_operatorAnalytics"}>Profitability Pane</div>
                            < ProfitabilityPane transactions={monthlyTractionsList}/></>)}

                        {underLineMark != "drawdown" && (<>
                            <div className={"chartTitle_operatorAnalytics"}>Daily Profitability (days by PnL)</div>
                            < OperatorMonthlyProfitabilityChart transactions={monthlyTractionsList}/></>)}

                        <Divider/>
                        {underLineMark == "drawdown" && (<>
                            <div className={"chartTitle_operatorAnalytics"}>Drawdown (by number of trades)</div>
                            <DrawDownChartMonthly transactions={monthlyTractionsList}/></>)}

                        {/* <div className={"chartTitle_operatorAnalytics"}>Monthly PnL</div>
            <CustomShapChartMonthlyProfitability/>*/}

                        {underLineMark != "drawdown" && (<>
                            <div className={"chartTitle_operatorAnalytics"}>Symbols order</div>
                            <MultiColorProgressBar transactions={monthlyTractionsList}/>
                        </>)}

                        <div className={"chartTitle_operatorAnalytics"}>Trade History</div>
                        <OperatorsTradeHistory transactions={monthlyTractionsList}/>
                    </>
            }
        </div>

    );
}


//by current month and current year
const dataByOperators = (transactions, operators, selectedDate) => {

    let retval = {};
    transactions && transactions.filter((value) => value.orderCloseTime.toDate().getFullYear() === selectedDate.getFullYear()
        && value.orderCloseTime.toDate().getMonth() === selectedDate.getMonth()).map((value, key) => {
        //Reason for the math.round is to cut 20% of the comment value from behind, because of comments not exactly matching operator id values
        let _tempName = operators && operators.find((_value) => value.orderComment.trim().toUpperCase().includes(_value.name.toUpperCase().replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').slice(0, (/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu.test(_value.name) ? value.orderComment.length - Math.round(value.orderComment.length * 0.33) : value.orderComment.length))))
        const operatorAcutalName = value.orderComment && _tempName ? _tempName.name : undefined;
        if (operatorAcutalName) {
            if (retval[operatorAcutalName]) {
                retval[operatorAcutalName] =
                    {
                        ...retval[operatorAcutalName],
                        count: retval[operatorAcutalName]["count"] + 1,
                        profits: retval[operatorAcutalName]["profits"] + Number(value.orderProfit),
                        mostTradedSymbol: {
                            ...retval[operatorAcutalName]["mostTradedSymbol"],
                            [value.orderSymbol.trim()]: retval[operatorAcutalName]["mostTradedSymbol"][value.orderSymbol.trim()] ? Number(retval[operatorAcutalName]["mostTradedSymbol"][value.orderSymbol.trim()]) + 1 : 1,
                        },
                        totalBuys: value.orderType === "BUY" ? retval[operatorAcutalName]["totalBuys"] + 1 : retval[operatorAcutalName]["totalBuys"],
                        totalSells: value.orderType === "SELL" ? retval[operatorAcutalName]["totalSells"] + 1 : retval[operatorAcutalName]["totalSells"],
                        totalSellWins: value.orderType.trim() === "SELL" && !isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalSellWins"] + 1 : retval[operatorAcutalName]["totalSellWins"],
                        totalSellLoss: value.orderType.trim() === "SELL" && isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalSellLoss"] + 1 : retval[operatorAcutalName]["totalSellLoss"],
                        totalBuyWins: value.orderType.trim() === "BUY" && !isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalBuyWins"] + 1 : retval[operatorAcutalName]["totalBuyWins"],
                        totalBuyLoss: value.orderType.trim() === "BUY" && isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalBuyLoss"] + 1 : retval[operatorAcutalName]["totalBuyLoss"],
                        totalWins: !isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalWins"] + 1 : retval[operatorAcutalName]["totalWins"],
                        totalLoss: isNegativeNumber(value.orderProfit) ? retval[operatorAcutalName]["totalLoss"] + 1 : retval[operatorAcutalName]["totalLoss"],
                    }

            } else {
                retval =
                    {
                        ...retval,
                        [operatorAcutalName]: {
                            count: 1,
                            profits: Number(value.orderProfit),
                            mostTradedSymbol: {
                                [value.orderSymbol.trim()]: 1
                            },
                            totalBuys: value.orderType.trim() === "BUY" ? 1 : 0,
                            totalSells: value.orderType.trim() === "SELL" ? 1 : 0,
                            totalSellWins: value.orderType.trim() === "SELL" && !isNegativeNumber(value.orderProfit) ? 1 : 0,
                            totalSellLoss: value.orderType.trim() === "SELL" && isNegativeNumber(value.orderProfit) ? 1 : 0,
                            totalBuyWins: value.orderType.trim() === "BUY" && !isNegativeNumber(value.orderProfit) ? 1 : 0,
                            totalBuyLoss: value.orderType.trim() === "BUY" && isNegativeNumber(value.orderProfit) ? 1 : 0,
                            totalWins: !isNegativeNumber(value.orderProfit) ? 1 : 0,
                            totalLoss: isNegativeNumber(value.orderProfit) ? 1 : 0,
                        }
                    }
            }

        }


    })


    return retval;

}

const prepareLineChartDataByOperatorAndCurrentMonth = (chartOneData, selectedDate) => {
    const currentMonth = selectedDate.getMonth();
    let retval = [];
    const getValues = getObjectValues(chartOneData[currentMonth]);
    chartOneData && getObjectKeys(chartOneData[currentMonth]).map((value, key) => {
        if (value === "name" || value === "id")
            return
        else
            retval.push(lineChatData = {
                ...lineChatData,
                name: value,
                value: getValues[key]
            })
    })

    return retval;
}

const prepareDate = (_operators, _transactions, selectedDate) => {

    let chartOnecData = [
        {
            id: 1,
            name: "Jan"
        },
        {
            id: 2,
            name: "Feb"
        },
        {
            id: 3,
            name: "Mar"

        }
        ,
        {
            id: 4,
            name: "Apr"

        }
        ,
        {
            id: 5,
            name: "May"

        }
        ,
        {
            id: 6,
            name: "Jun"

        }
        ,
        {
            id: 7,
            name: "Jul"

        }
        ,
        {
            id: 8,
            name: "Aug"

        }
        ,
        {
            id: 9,
            name: "Sep"

        }
        ,
        {
            id: 10,
            name: "Oct"

        },
        {
            id: 11,
            name: "Nov"

        }
        ,
        {
            id: 12,
            name: "Dec"

        }
    ]

    _transactions && _transactions.filter((value) => value.orderCloseTime.toDate().getFullYear() === selectedDate.getFullYear()).map((value, key) => {
        const transactionMonth = value.orderCloseTime.toDate().getMonth();
        const operator = value.orderComment.trim();
        const operatorAcutalName = _operators && _operators.find((value) => operator.toUpperCase().includes(value.name.toUpperCase()));
        //get keys of the object
        //if object key has the operator add the value... else add the operator and add the value
        if (operatorAcutalName) {
            if (getObjectKeys(chartOnecData[transactionMonth]).find((x) => operatorAcutalName.name.toUpperCase() === x.toUpperCase())) {
                //chartOnecData[transactionMonth].[operator]
                chartOnecData[transactionMonth] = {
                    ...chartOnecData[transactionMonth],
                    [operatorAcutalName.name]: Math.round(Number(chartOnecData[transactionMonth][operatorAcutalName.name] + Number(value.orderProfit)) + Number.EPSILON) * 100 / 100,
                }

            } else
                chartOnecData[transactionMonth] =
                    {
                        ...chartOnecData[transactionMonth],
                        [operatorAcutalName.name]: Number(value.orderProfit),
                    }

        }
    })

    return chartOnecData;

}

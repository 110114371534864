import React, {useRef, useState} from "react"
import {Alert, Button, Card, Form} from "react-bootstrap"
import {useAuth} from "../contexts/AuthContext"
import {Link} from "react-router-dom"
import "./login.css"
import Toolbar from "@mui/material/Toolbar";

export default function ResetPassword() {
    const passwordRef = useRef()
    const {resetPasswordConfirm} = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [hidePword, setHidePword] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setMessage("")
            setError("")
            setLoading(true)
            const queryParams = new URLSearchParams(window.location.search);
            const oobCode = queryParams.get("oobCode");
            await resetPasswordConfirm(oobCode, passwordRef.current.value)
            setHidePword(true);
            setMessage("Password changed successfully")
        } catch (e) {
            console.error(e.message)
            setError(e.message)
        }

        setLoading(false)
    }

    return (
        <div className={"loginClassbody"}>
            <Card>
                <Card.Body>
                    <Toolbar className="login">
                        <h2 className="text-center mb-4 login reset">Password Reset</h2></Toolbar>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {message && <Alert variant="success">{message}</Alert>}
                    <Form onSubmit={handleSubmit} hidden={hidePword}>
                        <Form.Group id="password" >
                            <Form.Label>Password</Form.Label>
                            <i className="fa fa-envelope envelopeLogin"/>
                            <Form.Control type="password" ref={passwordRef} required/>
                        </Form.Group>
                        <Button disabled={loading} className="w-100 loginButton" type="submit">
                            Reset Password
                        </Button>
                    </Form>
                    <div className="w-100 text-center mt-3">
                        <Link to="/login">Login</Link>
                    </div>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Need an account? <Link to="/signup">Sign Up</Link>
            </div>
        </div>
    )
}
import {createSlice} from "@reduxjs/toolkit";
const initialState = {};

export const tradePerMonthRevenue = createSlice({
    name: "tradeTransactionsPermonth",
    initialState: initialState,
    reducers: {
        tradesMonthlyRevenueInfo: (state, action) => {
            state.value = action.payload; ;
        }
    }
});

export const {tradesMonthlyRevenueInfo} = tradePerMonthRevenue.actions;

export default tradePerMonthRevenue.reducer;
